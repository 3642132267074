import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  Button,
  TouchableOpacity,
  Image,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
  Alert,
  ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import DateTimePicker from '@react-native-community/datetimepicker';
import { baseURL, imageURL } from '../apiConfig';
import { MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const ProfileScreen = ({ navigation }) => {
  const { setUser } = useContext(UserContext); // Get the user context
  const [successmsg, setSuccessmsg] = useState('');
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    password: '',
    date_of_birth: new Date(),
    phone: '',
    address: '',
    image: null,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        const uname = sessionStorage.getItem('username');
        const email = sessionStorage.getItem('email');

        setProfile({
          name: uname,
          email: email,
          password: '',
          date_of_birth: new Date(),
          address: '',
          phone: '',
          image: null,
        });

        if (!uid || !uname) {
          console.log('User not logged in');
        } else {
          console.log('User found!');
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const userId = sessionStorage.getItem('userId');
        const userToken = sessionStorage.getItem('token');

        if (!userId || !userToken) {
          console.log('User not logged in');
          return;
        }

        const response = await axios.get(`${baseURL}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        const { user } = response.data;
        setProfile((prevProfile) => ({
          ...prevProfile,
          name: user.name,
          email: user.email,
          date_of_birth: new Date(user.date_of_birth),
          address: user.address,
          phone: user.phone,
          image: user.image ? { uri: `${imageURL}/${user.image.replace(/\\/g, '/')}` } : null,
        }));
      } catch (error) {
        console.error('Error fetching profile:', error.response?.data || error.message);
      }
    };

    fetchProfileData();
  }, []);

  const handleImagePicker = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert('Permission to access media library is required!');
      return;
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!pickerResult.canceled) {
      const selectedImage = pickerResult.assets[0];
      setProfile((prevProfile) => ({
        ...prevProfile,
        image: selectedImage,
      }));
    }
  };

  const handleSave = async () => {
    const userId = sessionStorage.getItem('userId');
    const userToken = sessionStorage.getItem('token');
    if (!userId) {
      Alert.alert('Error', 'User ID not found. Please log in again.');
      return;
    }

    if (
      !profile.name ||
      !profile.email ||
      !profile.password ||
      !profile.date_of_birth.toISOString ||
      !profile.phone ||
      !profile.address
    ) {
      Alert.alert('Please fill in all the fields!');
      return;
    }

    const formData = new FormData();
    formData.append('name', profile.name);
    formData.append('email', profile.email);
    formData.append('date_of_birth', profile.date_of_birth.toISOString());
    formData.append('phone', profile.phone);
    formData.append('address', profile.address);
    if (profile.image && profile.image.uri) {
      if (Platform.OS === 'web') {
        // 👇 Web: Convert URL to Blob first
        const response = await fetch(profile.image.uri);
        const blob = await response.blob();
    
        formData.append('image', blob, 'profile.jpg');
      } else {
        // 👇 Native (Android/iOS)
        formData.append('image', {
          uri: profile.image.uri,
          type: 'image/jpeg',
          name: 'profile.jpg',
        });
      }
    }

    try {
      const response = await axios.put(`${baseURL}/users/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken || ''}`,
        },
      });
      Alert.alert('Success', 'Profile updated successfully');
      // 🔁 Refresh profile from server
      try {
        const response = await axios.get(`${baseURL}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        const { user } = response.data;
        const imageUrl = user.image ? { uri: `${baseURL}/${user.image.replace(/\\/g, '/')}` } : null;

        setProfile((prev) => ({
          ...prev,
          name: user.name ?? '',
          email: user.email ?? '',
          phone: user.phone ?? '',
          address: user.address ?? '',
          date_of_birth: user.date_of_birth ? new Date(user.date_of_birth) : new Date(),
          image: user.image ? imageUrl : null,
        }));

        setUser({
          name: user.name,
          phone: user.phone,
          image: imageUrl?.uri,
        });
        setSuccessmsg('Profile updated successfully');
        setTimeout(() => setSuccessmsg(''), 3000); // clears the message after 3 seconds
      } catch (fetchErr) {
        console.error('Error refreshing profile after save:', fetchErr);
      }


      // Navigate back and refresh
      navigation.navigate('Profile');
    } catch (error) {
      console.error('Error updating profile:', error);
      Alert.alert('Error', 'Failed to update profile');
    }
  };

  return (
    
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.container}>
            
            <View style={styles.profileImageContainer}>
              <Image
                source={profile.image ? { uri: profile.image.uri } : require('../assets/profile-placeholder.png')}
                style={styles.profileImage}
              />
              <TouchableOpacity style={styles.editIcon} onPress={handleImagePicker}>
                <Text style={styles.editIconText}>✎</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.label}>Your Name</Text>
            <TextInput
              style={styles.input}
              value={profile.name || ''}
              onChangeText={(name) => setProfile({ ...profile, name })}
            />
            <Text style={styles.label}>Email</Text>
            <TextInput
              style={styles.input}
              value={profile.email || ''}
              onChangeText={(email) => setProfile({ ...profile, email })}
            />
            <Text style={styles.label}>Password</Text>
            <TextInput
              style={styles.input}
              secureTextEntry
              value={profile.password || ''}
              onChangeText={(password) => setProfile({ ...profile, password })}
            />
            <Text style={styles.label}>Date of Birth</Text>
            <TouchableOpacity onPress={() => setShowDatePicker(true)} style={styles.input}>
              <Text>{profile.date_of_birth.toDateString()}</Text>
            </TouchableOpacity>
            {showDatePicker && (
              <DateTimePicker
                value={profile.date_of_birth}
                mode="date"
                display="default"
                onChange={(event, date) => {
                  setShowDatePicker(false);
                  if (date) {
                    setProfile({ ...profile, date_of_birth: date });
                  }
                }}
              />
            )}
            <Text style={styles.label}>Phone</Text>
            <TextInput
              style={styles.input}
              value={profile.phone || ''}
              keyboardType="numeric"
              onChangeText={(phone) => setProfile({ ...profile, phone })}
            />
            <Text style={styles.label}>Address</Text>
            <TextInput
              style={styles.input}
              value={profile.address || ''}
              onChangeText={(address) => setProfile({ ...profile, address })}
            />
            {successmsg ? (
              <View style={styles.successContainer}>
                <Text style={styles.successText}>{successmsg}</Text>
              </View>
            ) : (
              <TouchableOpacity style={styles.saveButton} onPress={handleSave}>
                <Text style={styles.saveButtonText}>Save</Text>
              </TouchableOpacity>
            )}

          </View>
        </ScrollView>
        
      </KeyboardAvoidingView>
  
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  backButton: {
    marginRight: 10,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  container: { flex: 1, padding: 10 },
  profileImageContainer: { alignItems: 'center', marginBottom: 20, padding:10, },
  profileImage: { width: 100, height: 100, borderRadius: 50 },
  editIcon: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    backgroundColor: '#FFD700',
    borderRadius: 15,
    padding: 5,
  },
  editIconText: { fontSize: 16, fontWeight: 'bold' },
  label: { fontWeight: 'bold', marginBottom: 5 },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  saveButton: {
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  saveButtonText: { color: 'white', fontWeight: 'bold' },
  successContainer: {
    marginTop: 20,
    padding: 15,
    backgroundColor: '#d4edda',
    borderRadius: 5,
    borderColor: '#c3e6cb',
    borderWidth: 1,
    alignItems: 'center',
  },
  successText: {
    color: '#155724',
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default ProfileScreen;
