import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, FlatList, Image, ActivityIndicator, ScrollView, SafeAreaView, useWindowDimensions } from 'react-native';
import axios from 'axios';
import { baseURL, imageURL } from '../apiConfig';
import TestimonialCarousel from './TestimonialCarousel';


const HomeScreen = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [sortBy, setSortBy] = useState('recommended');
  const [tags, setTags] = useState([]);

  useEffect(() => {
    console.log('Width:', width);
    fetchBlogs();
    fetchTags();
  }, []);

  

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${baseURL}/blogs`);
      setBlogs(response.data.blogs);
      setFilteredBlogs(response.data.blogs);
      setLoading(false);
      console.log("Blogs:", response.data.blogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${baseURL}/blogs/tags`);
      console.log("Tags:", response.data.tags);
      setTags(response.data.tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleSearch = () => {
    let filtered = blogs;
    if (searchQuery) {
      filtered = filtered.filter(blog => 
        blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.content.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (selectedTag) {
      filtered = filtered.filter(blog => blog.tags.includes(selectedTag));
    }
    if (sortBy === 'latest') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
    setFilteredBlogs(filtered);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1, backgroundColor: 'white' }} contentContainerStyle={{ flexGrow: 1 }}>
        {/* Hero Section */}
        <View style={[styles.heroContainer, isMobile && styles.heroContainerMobile]}>
          <Text style={[styles.heroText, isMobile && styles.heroTextMobile]}>
            Ready to boost your $avings today?
          </Text>
          <TouchableOpacity style={[styles.heroButton, isMobile && styles.heroButtonMobile]}>
            <Text style={styles.heroButtonText}>Start your free trial now</Text>
          </TouchableOpacity>
        </View>

        {/* Search Bar */}
        <View style={[styles.searchContainer, isMobile && styles.searchContainerMobile]}>
          <TextInput
            placeholder="Enter topic"
            value={searchQuery}
            onChangeText={setSearchQuery}
            style={[styles.searchInput, isMobile && styles.searchInputMobile]}
          />
          <TouchableOpacity onPress={handleSearch} style={styles.searchButton}>
            <Text style={{ color: 'white' }}>Search now</Text>
          </TouchableOpacity>
        </View>

        {/* Responsive Fix: Wrap main content in flex container */}
        <View style={{ paddingHorizontal: isMobile ? 10 : 40 }}>
          {loading ? (
            <ActivityIndicator size="large" color="black" style={{ marginTop: 40 }} />
          ) : (
            <FlatList
              data={filteredBlogs}
              contentContainerStyle={{ gap: 15 }}
              style={{ maxHeight: 500 }}
              keyExtractor={(item) => item._id}
              renderItem={({ item }) => (
                <View style={[styles.blogCard, isMobile && styles.blogCardMobile]}>
                  <Image source={{ uri: `${imageURL}${item.featuredImage}` }} style={[styles.blogImage, isMobile && styles.blogImageMobile]} />
                  <View style={styles.blogContent}>
                    <Text style={styles.blogTitle}>{item.title}</Text>
                    <Text style={styles.blogExcerpt} numberOfLines={2}>{item.excerpt}</Text>
                  </View>
                </View>
              )}
              ListEmptyComponent={<Text style={{ textAlign: 'center', marginTop: 20 }}>No blogs found</Text>}
            />
          )}
        </View>


        <View style={[styles.newsSection, isMobile && styles.newsSectionMobile]}>
          <Text style={styles.newsHeading}>Our latest news</Text>

          {filteredBlogs.length > 0 && (
            <View style={[styles.newsContainer, isMobile && styles.newsContainerMobile]}>
              <View style={[styles.featuredBlog, isMobile && styles.featuredBlogMobile]}>
                <Image source={{ uri: `${imageURL}${filteredBlogs[0].featuredImage}` }} style={styles.featuredImage} />
                <Text style={styles.featuredTitle}>{filteredBlogs[0].title}</Text>
                <Text style={styles.featuredExcerpt}>{filteredBlogs[0].excerpt}</Text>
              </View>

              <View style={styles.blogList}>
                <FlatList
                  data={filteredBlogs.slice(1, 4)}
                  keyExtractor={(item) => item._id}
                  style={{ maxHeight: isMobile ? 400 : 500 }}
                  showsVerticalScrollIndicator={false}
                  ListEmptyComponent={loading ? <ActivityIndicator size="large" color="black" /> : <Text>No blogs found</Text>}
                  renderItem={({ item }) => (
                    <View style={styles.miniBlogCard}>
                      <Image source={{ uri: `${imageURL}${item.featuredImage}` }} style={styles.miniBlogImage} />
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontWeight: 'bold' }}>{item.title}</Text>
                        <Text numberOfLines={2} style={{ color: '#666' }}>{item.excerpt}</Text>
                      </View>
                    </View>
                  )}
                />
              </View>
            </View>
          )}

          <TouchableOpacity style={styles.viewAllButton}>
            <Text style={{ color: 'white', textAlign: 'center' }}>View all</Text>
          </TouchableOpacity>
        </View>


        {/* Portfolio Section */}
        <View style={[styles.portfolioContainer, isMobile && styles.portfolioContainerMobile]}>
          <Text style={styles.portfolioTitle}>Build your portfolio now</Text>
          <Text style={styles.portfolioDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat nulla suspendisse tortor aene.</Text>

          <View style={[styles.portfolioGrid, isMobile && styles.portfolioGridMobile]}>
            {[
              { image: require('../assets/send_receive.png'), title: 'Send & Receive' },
              { image: require('../assets/secure_wallet.png'), title: '100% Secure Wallet' },
              { image: require('../assets/mobile_app_ui.png'), title: 'iOS & Android App', wide: true },
              { image: require('../assets/trading_charts.png'), title: 'Trading Charts' },
              { image: require('../assets/real_time_trading.png'), title: 'Real Time Trading' }
            ].map((item, index) => (
              <View key={index} style={[styles.portfolioCard, item.wide && styles.portfolioCardWide]}>
                <Image source={item.image} style={styles.portfolioImage} />
                <Text style={styles.portfolioCardTitle}>{item.title}</Text>
                <Text style={styles.portfolioCardDesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text>
              </View>
            ))}
          </View>

          <TouchableOpacity style={styles.downloadButton}>
            <Text style={styles.heroButtonText}>Download app</Text>
          </TouchableOpacity>
        </View>

        {/* Ad Section - Responsive */}
        <View style={[styles.adContainer, isMobile && styles.adContainerMobile]}>
          <Image
            source={require('../assets/ad_section.png')}
            style={[styles.adImage, isMobile && styles.adImageMobile]}
          />
        </View>
        {/* Two-Column Section - Responsive */}
        <View style={[styles.twoColumnContainer, isMobile && styles.twoColumnContainerMobile]}>
          <Image
            source={require('../assets/laptop_section.png')}
            style={[styles.twoColumnImage, isMobile && styles.twoColumnImageMobile]}
          />
          <View style={[styles.twoColumnTextContainer, isMobile && styles.twoColumnTextContainerMobile]}>
            <Text style={[styles.twoColumnTitle, isMobile && styles.twoColumnTitleMobile]}>Get started today</Text>
            <Text style={styles.twoColumnSubtitle}>
              Start saving money today with our easy to use platform.
            </Text>
            <View style={styles.featureItemRow}>
              <Image source={require('../assets/lowest_fees.png')} style={styles.featureIcon} />
              <Text>- Lowest fees in market</Text>
            </View>
            <View style={styles.featureItemRow}>
              <Image source={require('../assets/fast_secure.png')} style={styles.featureIcon} />
              <Text>- Fast and secure transactions</Text>
            </View>
            <View style={styles.featureItemRow}>
              <Image source={require('../assets/ai_generated.png')} style={styles.featureIcon} />
              <Text>- AI generated suggestions</Text>
            </View>
          </View>
        </View>

        {/* Pricing Section - Responsive */}
        <View style={styles.pricingContainer}>
          <Text style={styles.pricingTitle}>Pricing</Text>
          <View style={[styles.pricingCardsWrapper, isMobile && styles.pricingCardsWrapperMobile]}>
            {["BASIC", "PRO", "EXPERT"].map((plan, index) => (
              <View key={index} style={styles.card}>
                <View style={styles.badge}>
                  <Text style={styles.badgeText}>{plan}</Text>
                </View>
                <Text style={styles.price}>₹ {index === 0 ? "1000" : index === 1 ? "2000" : "5000"}</Text>
                <Text style={styles.description}>
                  Lorem ipsum dolor sit amet, ametor consectetur adipiscing elit. Et nibh.
                </Text>
                <Text style={styles.featuresTitle}>FEATURES</Text>
                <View style={styles.featuresList}>
                  <Text style={styles.featureItem}>● Everything included in Basic</Text>
                  <Text style={styles.featureItem}>● Trading up to ₹1Cr per month</Text>
                  <Text style={styles.featureItem}>● Windows & macOS App</Text>
                  <Text style={styles.featureItem}>● Premium Support</Text>
                </View>
                <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>Buy now</Text>
                </TouchableOpacity>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <TestimonialCarousel />
        </View>

        {/* Footer */}
        <View style={styles.footerContainer}>
          <View style={[styles.footerTop, isMobile && styles.footerTopMobile]}>
            <View style={styles.footerLeftBox}>
              <Image source={require('../assets/savingy_logo.png')} style={styles.footerLogo} />
              <Text style={styles.footerText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nulla integer.
              </Text>
              <View style={styles.footerStoresRow}>
                <Image source={require('../assets/app_store.png')} style={styles.storeBadge} />
                <Image source={require('../assets/play_store.png')} style={styles.storeBadge} />
              </View>
            </View>

            <View style={styles.footerRightLinks}>
              {['Tracker', 'Insights', 'Threads', 'Contact us'].map((link, i) => (
                <Text key={i} style={styles.footerLink}>{link}</Text>
              ))}
              <View style={styles.footerSocialsRow}>
                <Image source={require('../assets/instagram.png')} style={styles.socialIcon} />
                <Image source={require('../assets/facebook.png')} style={styles.socialIcon} />
                <Image source={require('../assets/linkedin.png')} style={styles.socialIcon} />
              </View>
            </View>
          </View>

          <View style={styles.footerBottom}>
            <Text style={styles.footerBottomText}>All rights reserved</Text>
            <View style={styles.footerBottomLinks}>
              <Text style={styles.footerBottomText}>Privacy policy</Text>
              <Text style={styles.footerBottomText}>Terms and conditions</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  heroContainer: {
    margin: 50,
    padding: 40,
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderRadius: 10,
    shadowColor: '#ccc',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  heroContainerMobile: {
    margin: 20,
    marginHorizontal: 5,
    padding: 10,
  },
  heroText: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: 600,
  },
  heroTextMobile: {
    width: '100%',
    fontSize: 22,
  },
  heroButton: {
    marginTop: 40,
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 10,
  },
  heroButtonMobile: {
    marginTop: 20,
  },
  heroButtonText: {
    color: 'white',
    fontSize: 16,
  },
  searchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 20,
    paddingHorizontal: 20,
  },
  searchContainerMobile: {
    flexDirection: 'column',
    gap: 10,
  },
  searchInput: {
    flex: 1,
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    marginRight: 10,
  },
  searchInputMobile: {
    marginRight: 0,
    marginBottom: 10,
  },
  searchButton: {
    backgroundColor: 'black',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  contentContainer: {
    paddingHorizontal: 40,
    marginTop: 20,
  },
  contentContainerMobile: {
    paddingHorizontal: 20,
  },
  blogCard: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  blogCardMobile: {
    flexDirection: 'column',
  },
  blogImage: {
    width: 120,
    height: 120,
    borderRadius: 10,
    marginRight: 10,
  },
  blogImageMobile: {
    width: '100%',
    height: 180,
    marginRight: 0,
    marginBottom: 10,
  },
  blogContent: {
    flex: 1,
  },
  blogTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5,
  },
  blogExcerpt: {
    color: '#666',
    fontSize: 14,
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    width: 300,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    elevation: 5, // Shadow for Android
  },
  badge: {
    backgroundColor: '#FDCB2E',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 20,
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  badgeText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 14,
  },
  price: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#000',
  },
  description: {
    fontSize: 14,
    color: '#555',
    marginVertical: 10,
    lineHeight: 20,
  },
  featuresTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
    marginTop: 10,
    marginBottom: 5,
  },
  featuresList: {
    marginBottom: 20,
  },
  featureItem: {
    fontSize: 14,
    color: '#333',
    marginVertical: 4,
  },
  button: {
    backgroundColor: '#000',
    paddingVertical: 12,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 'bold',
  },

  section: {
    paddingVertical: 30,
    alignItems: 'center',
  },
  newsSection: {
    padding: 20,
  },
  newsSectionMobile: {
    paddingHorizontal: 15,
  },
  newsHeading: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  newsContainer: {
    flexDirection: 'row',
    marginTop: 20,
  },
  newsContainerMobile: {
    flexDirection: 'column',
  },
  featuredBlog: {
    flex: 1,
    paddingLeft: 50,
    paddingRight: 20,
  },
  featuredBlogMobile: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 20,
  },
  featuredImage: {
    width: '100%',
    height: 250,
    borderRadius: 10,
  },
  featuredTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
  },
  featuredExcerpt: {
    color: '#666',
    marginVertical: 5,
  },
  blogList: {
    flex: 1,
  },
  miniBlogCard: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  miniBlogImage: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 10,
  },
  viewAllButton: {
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 10,
    marginTop: 20,
    alignSelf: 'center',
  },
  portfolioContainer: {
    alignItems: 'center',
    padding: 20,
  },
  portfolioContainerMobile: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  portfolioTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  portfolioDescription: {
    textAlign: 'center',
    color: '#666',
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  portfolioGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 20,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 10,
    padding: 20,
  },
  portfolioGridMobile: {
    gap: 15,
    padding: 10,
  },
  portfolioCard: {
    width: 150,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  portfolioCardWide: {
    width: 250,
  },
  portfolioImage: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  portfolioCardTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 5,
  },
  portfolioCardDesc: {
    textAlign: 'center',
    color: '#666',
    fontSize: 13,
  },
  downloadButton: {
    marginTop: 20,
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 10,
  },
  twoColumnContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 20,
    marginTop: 20,
    marginLeft: 100,
    alignItems: 'center',
  },
  twoColumnContainerMobile: {
    flexDirection: 'column',
    marginLeft: 0,
    padding: 10,
    alignItems: 'center',
  },
  twoColumnImage: {
    width: '50%',
    height: undefined,
    aspectRatio: 16 / 7,
    resizeMode: 'contain',
  },
  twoColumnImageMobile: {
    width: '100%',
    aspectRatio: 16 / 9,
    marginBottom: 20,
  },
  twoColumnTextContainer: {
    flex: 1,
    paddingLeft: 20,
  },
  twoColumnTextContainerMobile: {
    paddingLeft: 0,
    alignItems: 'center',
  },
  twoColumnTitle: {
    fontSize: 44,
    fontWeight: 'bold',
  },
  twoColumnTitleMobile: {
    fontSize: 24,
    textAlign: 'center',
  },
  twoColumnSubtitle: {
    fontSize: 18,
    marginTop: 10,
    textAlign: 'center',
    color: '#444',
    paddingHorizontal: 10,
  },
  featureItemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  featureIcon: {
    width: 50,
    height: 50,
    marginRight: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  adContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
  },
  adContainerMobile: {
    paddingHorizontal: 10,
  },
  adImage: {
    width: '90%',
    height: undefined,
    aspectRatio: 16 / 7,
    resizeMode: 'contain',
  },
  adImageMobile: {
    width: '100%',
  },
  pricingContainer: {
    padding: 20,
  },
  pricingTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pricingCardsWrapper: {
    backgroundColor: '#f5f5f5',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: 20,
    padding: 50,
    gap: 20,
    flexWrap: 'wrap',
  },
  pricingCardsWrapperMobile: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    width: 300,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    elevation: 5,
    marginBottom: 20,
  },
  badge: {
    backgroundColor: '#FDCB2E',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 20,
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  badgeText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 14,
  },
  price: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#000',
  },
  description: {
    fontSize: 14,
    color: '#555',
    marginVertical: 10,
    lineHeight: 20,
  },
  featuresTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
    marginTop: 10,
    marginBottom: 5,
  },
  featuresList: {
    marginBottom: 20,
  },
  featureItem: {
    fontSize: 14,
    color: '#333',
    marginVertical: 4,
  },
  button: {
    backgroundColor: '#000',
    paddingVertical: 12,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 'bold',
  },
  footerContainer: {
    backgroundColor: '#111',
    padding: 20,
    paddingBottom: 40,
  },
  footerTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  footerTopMobile: {
    flexDirection: 'column',
    gap: 30,
  },
  footerLeftBox: {
    backgroundColor: '#222',
    padding: 20,
    borderRadius: 15,
    width: 320,
  },
  footerLogo: {
    width: 120,
    height: 40,
    marginBottom: 10,
    resizeMode: 'contain',
    alignSelf: 'flex-start',
  },
  footerText: {
    color: 'white',
    fontSize: 14,
    marginBottom: 15,
  },
  footerStoresRow: {
    flexDirection: 'row',
    gap: 10,
  },
  storeBadge: {
    width: 120,
    height: 40,
    resizeMode: 'contain',
    marginRight: 10,
  },
  footerRightLinks: {
    gap: 10,
  },
  footerLink: {
    color: 'white',
    fontSize: 16,
    marginBottom: 10,
  },
  footerSocialsRow: {
    flexDirection: 'row',
    marginTop: 50,
    paddingTop: 50,
    gap: 15,
    alignContent: 'flex-start',
  },
  socialIcon: {
    width: 30,
    height: 30,
  },
  footerBottom: {
    marginTop: 30,
    borderTopWidth: 1,
    borderColor: '#444',
    paddingTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerBottomText: {
    color: 'white',
  },
  footerBottomLinks: {
    flexDirection: 'row',
    gap: 20,
  },
});


export default HomeScreen;