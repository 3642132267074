import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
  StyleSheet,
  TextInput,
  Modal,
  SafeAreaView,
  Alert,
  KeyboardAvoidingView,
  Platform,
  LayoutAnimation,
  UIManager,
  RefreshControl,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Picker } from '@react-native-picker/picker';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { baseURL } from '../apiConfig';

// Enable LayoutAnimation on Android
if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const TrackerScreen = ({ navigation }) => {
  // ============== STATE (Budget + Expenses) ==============
  // From your HomeScreen approach
  const [budgetData, setBudgetData] = useState([]);
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);     // overall "income" this month
  const [totalExpenses, setTotalExpenses] = useState(0); // sum of all subcategory amounts
  const [netBalance, setNetBalance] = useState(0);       // budget - expenses
  const [refreshing, setRefreshing] = useState(false);
  const [budgetSum, setBudgetSum] = useState(0);
  
  // For the displayed progress, etc.
  const [progressPercent, setProgressPercent] = useState(0);

  // ============== STATE (Tracker Screen's existing) ==============
  const [bankName, setBankName] = useState('');
  const [bankAmount, setBankAmount] = useState('');
  const [categories, setCategories] = useState([
    { name: 'Bills', subcategories: [], budgeted: 0 },
    { name: 'Needs', subcategories: [], budgeted: 0 },
    { name: 'Wants', subcategories: [], budgeted: 0 },
  ]);
  const [selectedCategory, setSelectedCategory] = useState('Bills');
  const [subcategoryName, setSubcategoryName] = useState('');
  const [subcategoryAmount, setSubcategoryAmount] = useState('');
  const [subcategoryDate, setSubcategoryDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState(null);
  const [savings, setSavings] = useState('');
  const [investments, setInvestments] = useState('');
  const [chartData, setChartData] = useState([]);
  const [userId, setUserId] = useState();
  const [isFormPrefilled, setIsFormPrefilled] = useState(false);

  const [progressMessage, setProgressMessage] = useState('');
  const [progressBarColor, setProgressBarColor] = useState('#FFC107'); 

  // Additional state for "search" & "filter" modals
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Daily');

  // Expenses from the DB
  const [expenses, setExpenses] = useState([]);
  const [totExpenses, setTotExpenses] = useState();
  const [editingExpense, setEditingExpense] = useState(null);

  const [filterTabs, setFilterTabs] = useState([]);
  // Let's keep track of which tab is selected:
  const [activeTab, setActiveTab] = useState(null);

  // NEW: Local states for editing an expense
  const [editAmount, setEditAmount] = useState('');
  const [saveEditAmount, setSaveEditAmount] = useState(0);
  const [editCategory, setEditCategory] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editDate, setEditDate] = useState(new Date());

  const [modalAlert,setModalAlert] = useState(false);
  const [alertMessage,setAlertMessage] = useState('');

  // ====================== EFFECTS ======================

  useEffect(() => {
    if (filterTabs.length > 0 && !activeTab) {
      setActiveTab(filterTabs[0]);
    }
  }, [filterTabs]);

  useEffect(() => {
    if (selectedFilter === 'Daily') {
      // Show days of the week
      setFilterTabs(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
    } else if (selectedFilter === 'Weekly') {
      // Show "Week 1" to "Week 5"
      setFilterTabs(['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5']);
    } else if (selectedFilter === 'Monthly') {
      // Show months of the year
      setFilterTabs(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
    } else {
      // If you add "Yearly" or anything else
      setFilterTabs(['2023', '2024', '2025']); // or however you want
    }
  
    // Reset active tab
    setActiveTab(null);
  }, [selectedFilter]);

  // Check for uid/uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        setUserId(uid);

        const uname = sessionStorage.getItem('username');
        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          navigation.navigate("Account");
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };

    checkAuth();
  }, [navigation]);

  // Once we have userId, fetch data
  useEffect(() => {
    if (userId && selectedFilter && activeTab != null) {
      fetchBudgetData();
      fetchExpenseData();
      fetchExpensesByTimeframe(selectedFilter, activeTab);
    }
  }, [selectedFilter, activeTab, userId]);

  // Prefill form if budget data is available
  useEffect(() => {
    if (budgetData?.length > 0 && !isFormPrefilled) {
      const latestBudget = budgetData[0];
      setBankName(latestBudget.bankName || '');
      setBankAmount(latestBudget.bankAmount ? latestBudget.bankAmount.toString() : '');
      setCategories(latestBudget.categories || categories);
      setSavings(latestBudget.savings ? latestBudget.savings.toString() : '');
      setInvestments(latestBudget.investments ? latestBudget.investments.toString() : '');
      setIsFormPrefilled(true);
    }
  }, [budgetData]);

  // ====================== FETCHING BUDGET & EXPENSES ======================
  const fetchBudgetData = async () => {
    try {
      if (!userId) return;
  
      // If monthly or yearly
      if (selectedFilter === 'Monthly' && activeTab) {
        // Convert something like "Mar" → 2 (because Jan=0, Feb=1, Mar=2, etc.)
        const monthIndex = getMonthIndexFromString(activeTab);
        const year = new Date().getFullYear(); 
        // If you let user pick year differently, parse that too.
  
        // e.g. GET /budget/monthly?year=2025&month=2&userId=...
        const response = await axios.get(
          `${baseURL}/budget/monthly?year=${year}&month=${monthIndex}&userId=${userId}`
        );
        handleBudgetResponse(response);
  
      } else if (selectedFilter === 'Yearly' && activeTab) {
        // e.g. activeTab = "2023" or "2024"
        const year = parseInt(activeTab, 10) || new Date().getFullYear();
  
        // e.g. GET /budget/yearly?year=2025&userId=...
        const response = await axios.get(
          `${baseURL}/budget/yearly?year=${year}&userId=${userId}`
        );
        handleBudgetResponse(response);
  
      } else {
        // Otherwise, e.g. if daily/weekly or no activeTab set:
        // skip or fallback to the current month
        // Or do /budget/current?userId=...
        const response = await axios.get(
          `${baseURL}/budget/current?userId=${userId}`
        );
        handleBudgetResponse(response);
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
      Alert.alert('Error', 'Could not fetch budget data.');
    }
  };
  
  // A small helper to handle the budget response
  // So we don’t repeat logic
  const handleBudgetResponse = (response) => {
    if (!response.data.success) {
      console.log('No budget found or error:', response.data.message);
      return;
    }
    const budgets = response.data.budgets || [];
    setBudgetData(budgets);
  
    if (budgets.length > 0) {
      let savingsSum = 0;
      let investmentsSum = 0;
      let totalBudgetTmp = 0;
      let expensesSum = 0;
  
      budgets.forEach((budget) => {
        savingsSum += budget.savings || 0;
        investmentsSum += budget.investments || 0;
  
        // e.g. for monthly or yearly, the backend might give you
        // "incomeAmount" or "allocatedBudget" 
        totalBudgetTmp += budget.incomeAmount || 0;
  
        if (Array.isArray(budget.categories)) {
          budget.categories.forEach((cat) => {
            if (Array.isArray(cat.subcategories)) {
              cat.subcategories.forEach((sub) => {
                expensesSum += parseFloat(sub.amount) || 0;
              });
            }
          });
        }
      });
  
      setTotalSavings(savingsSum);
      setTotalInvestments(investmentsSum);
      setTotalBudget(totalBudgetTmp);
      setBudgetSum(totalBudgetTmp);
      setTotalExpenses(expensesSum);
  
      const net = totalBudgetTmp - (totExpenses || 0);
      setNetBalance(net);
    }
  };

  useEffect(() => {
    if (budgetSum > 0) {
      const percent = (totExpenses / budgetSum) * 100;
      setProgressPercent(percent);
      if (percent < 100) {
        setProgressMessage(`${percent.toFixed(1)}% of your expenses used, keep going!`);
        setProgressBarColor('#FFC107');
      } else if (percent === 100) {
        setProgressMessage('You have used up your income!');
        setProgressBarColor('#FFC107');
      } else {
        const overspent = (totExpenses - budgetSum).toFixed(2);
        setProgressMessage(`You’ve overspent by ₹${overspent}...`);
        setProgressBarColor('#FF0000');
      }
    } else {
      setProgressPercent(0);
      setProgressMessage('No budget or total budget is zero.');
      setProgressBarColor('#FFC107');
    }
  }, [budgetSum, totExpenses]);

  // Similar approach for expenses if needed
  const fetchExpenseData = async () => {
    try {
      if (!userId) return;
      // 1) Build date range
      const { startDate, endDate } = getDateRange(selectedFilter, activeTab);

      // 2) Construct query
      const query = `?userId=${userId}&start=${startDate.toISOString()}&end=${endDate.toISOString()}`;

      // 3) Call backend route for expenses
      const response = await axios.get(`${baseURL}/expenses/timeframe${query}`);
      //const response = await axios.get(`${baseURL}/expenses/current?userId=${userId}`);
      if (response.data.success) {
        // Store the raw expenses array
      const fetchedExpenses = response.data.expenses;
      setExpenses(fetchedExpenses);
      
      // Calculate total from the "amount" field
      const total = fetchedExpenses.reduce((sum, expense) => sum + expense.amount, 0);
      setTotExpenses(total);
      console.log('Total expenses:', total);
      } else {
        console.log('No expenses found for current month or error:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching expense data:', error);
    }
  };


  // Put this above your component or in a utils file:
const getDateRange = (selectedFilter, activeTab) => {
  const now = new Date();
  let startDate = new Date();
  let endDate = new Date();

  switch (selectedFilter) {
    case 'Daily': {
      // (Optional) if user picks a specific day like "Mon" or "Tue", 
      // you'd compute that date. Otherwise, here’s the "today only" example:
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      break;
    }

    case 'Weekly': {
      // If activeTab === "Week 2", parse out the number
      if (activeTab) {
        const weekNum = parseInt(activeTab.replace('Week ', ''), 10) || 1;
        const startDay = 1 + (weekNum - 1) * 7;
        const endDay = startDay + 7;
        startDate = new Date(now.getFullYear(), now.getMonth(), startDay);
        endDate = new Date(now.getFullYear(), now.getMonth(), endDay);
      }
      break;
    }

    case 'Monthly': {
      // If user picks "Jan", "Feb", etc. parse that string to a 0-based index
      if (activeTab) {
        const monthIndex = getMonthIndexFromString(activeTab); // define a helper
        const year = now.getFullYear(); 
        startDate = new Date(year, monthIndex, 1);
        endDate = new Date(year, monthIndex + 1, 1);
      }
      break;
    }

    case 'Yearly': {
      // If user picks "2023", parse it
      if (activeTab) {
        const year = parseInt(activeTab, 10) || now.getFullYear();
        startDate = new Date(year, 0, 1);
        endDate = new Date(year + 1, 0, 1);
      }
      break;
    }

    default: {
      // If unknown, just default to e.g. the entire current month
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      break;
    }
  }

  return { startDate, endDate };
}

// Example helper for converting "Jan","Feb",... to 0,1,...
const getMonthIndexFromString =(mon) => {
  const months = ['Jan','Feb','Mar','Apr','May','Jun',
                  'Jul','Aug','Sep','Oct','Nov','Dec'];
  return months.indexOf(mon);
}


  // Place this in your TrackerScreen or a helper file
const fetchExpensesByTimeframe = async (timeframe, option) => {
  // timeframe could be: 'Daily', 'Weekly', 'Monthly', 'Yearly', etc.
  // option could be the day of week, or the month number, or the year, etc.
  // e.g. option = 'Mon', or option = 'Week 2', or option = 'May', or option = 2025

  // By default, let's assume we want to fetch for the current date/time
  const now = new Date();
  let startDate = new Date();
  let endDate = new Date();

  switch (timeframe) {
    case 'Daily': {
      // If "Daily" and user picks a certain date or day name (Mon/Tue/Wed):
      // For example, if you want the "most recent Monday" or a specific date, you’d compute here.
      // This simple example: we fetch "today only"
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // midnight today
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1); // midnight tomorrow
      break;
    }
    case 'Weekly': {
      // If "Weekly" and user picks e.g. "Week 2," you can figure out which dates that covers.
      // Example: let's define "Week 2" as day 8 to 14 of the current month.
      // In reality, you might want to pick a Sunday-Saturday or Monday-Sunday approach.

      // A rough example for "Week 2" of the current month:
      const weekNum = parseInt(option.replace('Week ', ''), 10) || 1; 
      // each "week" is 7 days, so:
      const startDay = 1 + (weekNum - 1) * 7; 
      const endDay = startDay + 7; 
      startDate = new Date(now.getFullYear(), now.getMonth(), startDay); 
      endDate = new Date(now.getFullYear(), now.getMonth(), endDay);
      break;
    }
    case 'Monthly': {
      // If "Monthly" and user picks e.g. "Jan", "Feb", "Mar", ...
      // Suppose `option` is a month index (0 = Jan, 1 = Feb, etc.) or a string name
      // For a custom approach, parse accordingly.

      const monthIndex = getMonthIndexFromString(option); // you'll define a helper
      const year = now.getFullYear(); // or let user pick year too
      startDate = new Date(year, monthIndex, 1);
      endDate = new Date(year, monthIndex + 1, 1); // first day of the next month
      break;
    }
    case 'Yearly': {
      // If "Yearly" and user picks a year, e.g. 2023, 2024, ...
      const year = parseInt(option, 10) || now.getFullYear();
      startDate = new Date(year, 0, 1);      // Jan 1 of that year
      endDate = new Date(year + 1, 0, 1);    // Jan 1 of the next year
      break;
    }
    default: {
      Alert.alert('Error', 'Unknown timeframe selected.');
      return;
    }
  }

  try {
    const userId = sessionStorage.getItem('userId');
    if (!userId) {
      Alert.alert('Not Logged In', 'Please log in first.');
      return;
    }

    const query = `start=${startDate.toISOString()}&end=${endDate.toISOString()}&userId=${userId}`;
    const response = await axios.get(`${baseURL}/expenses/timeframe?${query}`);

    if (response.data.success) {
      // Do something with the data
      console.log('Expenses for the selected timeframe:', response.data.expenses);
      setExpenses(response.data.expenses);
    } else {
      Alert.alert('No Expenses', response.data.message || 'No expenses found.');
      setExpenses([]);
    }
  } catch (error) {
    console.error('Error fetching expenses by timeframe:', error);
    Alert.alert('Error', 'Failed to fetch expenses.');
  }
};


  // ====================== CRUD HANDLERS ======================
  const deleteExpense = async (id) => {
    try {
      await axios.delete(`${baseURL}/expenses/${id}`);
      setExpenses(expenses.filter((expense) => expense._id !== id));
      Alert.alert('Success', 'Expense deleted successfully.');
      // Optionally re-fetch budget data if it affects budget
      fetchBudgetData();
    } catch (error) {
      console.error('Error deleting expense:', error);
    }
  };

  const handleEditExpense = (expense) => {
    setEditingExpense(expense);
    setEditAmount(expense.amount.toString());
    setSaveEditAmount(expense.amount);
    setEditCategory(expense.category);
    setEditDescription(expense.description || '');
    setEditDate(new Date(expense.date));
    setEditDescription(expense.description || '');
    setEditDate(new Date(expense.date));
  };

  const handleSaveEdit = async () => {
    if (!editingExpense) return;
    // Use the latest budget data
    const latestBudget = budgetData[0];
    if (!latestBudget) {
      Alert.alert('Error', 'No budget data found.');
      return;
    }
    // Find the selected subcategory in the budgetData
    let selectedSubcategory = null;
    for (const cat of latestBudget.categories) {
      const subcat = cat.subcategories.find((sub) => sub.name === editCategory);
      if (subcat) {
        selectedSubcategory = subcat;
        break;
      }
    }
    // Calculate the total expenses for the selected category
    const totalExpensesForCategory = expenses
      .filter((expense) => expense.category === editCategory)
      .reduce((total, expense) => total + expense.amount, 0);

    // Calculate remaining budget
    const remainingBudget = (selectedSubcategory.amount - totalExpensesForCategory) + parseFloat(saveEditAmount);
    console.log("Selected Subcategory: ", selectedSubcategory);
    console.log("Total Expenses for Category: ", totalExpensesForCategory);
    console.log("Remaining Budget: ", remainingBudget);
    console.log("Edit Amount: ", editAmount);
    // Check if the new expense exceeds the budget
    if(editAmount <= 0){
      console.log('Invalid Amount');
      setModalAlert(true);
      setAlertMessage('Please enter a valid amount.');
      return;
    }
    if (editAmount > remainingBudget) {
      
      console.log('Budget Exceeded');
      setModalAlert(true);
      setAlertMessage(`This expense exceeds the remaining budget for the category by ₹${(editAmount - selectedSubcategory.amount).toFixed(2)}.`);
      Alert.alert(
        'Budget Exceeded',
        `This expense exceeds the remaining budget for the category by ₹${(amount - remainingBudget).toFixed(
          2
        )}.`
      );
      return;
    }
    try {
      await axios.put(`${baseURL}/expenses/${editingExpense._id}`, {
        amount:editAmount,
        category:editCategory,
        description: editDescription,
        date: editDate,
      });

      Alert.alert('Success', 'Expense updated successfully.');
      setExpenses((prev) =>
        prev.map((expense) =>
          expense._id === editingExpense._id
            ? {
                ...expense,
                amount: editAmount,
                category: editCategory,
                description: editDescription,
                date: editDate,
              }
            : expense
        )
      );

      setEditingExpense(null);
      setEditAmount('');
      setEditCategory('');
      setEditDescription('');
      setEditDate(new Date());
      // Re-fetch budget data if needed
      fetchBudgetData();
    } catch (error) {
      console.error('Error updating expense:', error);
      Alert.alert('Error', 'Failed to update expense.');
    }
  };

  const handleCancelEdit = () => {
    setEditingExpense(null);
    setEditAmount('');
    setEditCategory('');
    setEditDescription('');
    setEditDate(new Date());
  };

  // ====================== SUBCATEGORY (EXISTING) ======================
  // ... your addSubcategory, deleteSubcategory, editSubcategory, etc. remain the same
  // Omitted for brevity

  // ====================== RENDER ======================
  const renderExpense = ({ item }) => (
    <View style={styles.expenseCard}>
      <Text style={styles.expenseText}>{item.category} - ₹{item.amount}</Text>
      <Text style={styles.expenseDate}>{new Date(item.date).toLocaleDateString()}</Text>
      <View style={styles.expenseActions}>
        <TouchableOpacity onPress={() => handleEditExpense(item)} style={styles.editButton}>
          <Ionicons name="pencil" size={20} color="#fff" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => deleteExpense(item._id)} style={styles.deleteButton}>
          <Ionicons name="trash" size={20} color="#fff" />
        </TouchableOpacity>
      </View>
    </View>
  );

  // Example date handling
  const onDateChange = (event, selectedDate) => {
    const currentDate = selectedDate || subcategoryDate;
    setShowDatePicker(false);
    setSubcategoryDate(currentDate);
  };

  const onRefresh = () => {
    setRefreshing(true);
    
    Promise.all([fetchBudgetData(), fetchExpenseData(), fetchExpensesByTimeframe(selectedFilter, activeTab), ])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  return (
    <SafeAreaView style={styles.container} >
      <ScrollView style={styles.cardWrapper} refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} /> } >
        {/* ---------------- TOP SECTION (WHITE) ---------------- */}
        <View style={styles.topContainer} >
          {/* Header Row */}
          <View style={styles.headerRow}>
            <TouchableOpacity
              onPress={() => navigation.goBack?.()}
              style={styles.iconButton}
              accessible
              accessibilityLabel="Go back"
            >
              <Ionicons name="arrow-back" size={24} color="#000" />
            </TouchableOpacity>

            <Text style={styles.titleText}>All Transactions</Text>

            {/* Icons on right (Search & Filter) */}
            <View style={styles.headerIcons}>
              <TouchableOpacity
                onPress={() => setSearchVisible(true)}
                style={styles.iconButton}
                accessible
                accessibilityLabel="Search"
              >
                <Ionicons name="search" size={24} color="#000" />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => setFilterModalVisible(true)}
                style={styles.iconButton}
                accessible
                accessibilityLabel="Open filter"
              >
                <Ionicons name="calendar" size={24} color="#000" />
              </TouchableOpacity>
            </View>
          </View>

          <Text style={styles.subheaderText}>Income & expenses</Text>

          {/* Filter Dropdown */}
          <View style={styles.filterContainer}>
            <TouchableOpacity
              style={styles.filterButtonContainer}
              onPress={() => setFilterModalVisible(true)}
              accessible
              accessibilityLabel="Select filter"
            >
              <Text style={styles.filterButtonText}>{selectedFilter}</Text>
              <Ionicons name="chevron-down" size={20} color="#777" />
            </TouchableOpacity>
          </View>

          {/* Horizontal Day Tabs */}
          <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.dayTabsContainer} >
            {filterTabs.map((option, index) => {
              const isActive = activeTab === option;

              return (
                <TouchableOpacity
                  key={option}
                  style={isActive ? styles.activeDayTab : styles.dayTab}
                  onPress={() => {
                    // Set this option as active
                    setActiveTab(option);

                    // Actually fetch data:
                    // If selectedFilter is "Weekly" and option is "Week 2", 
                    // you’d do something like:
                    fetchExpensesByTimeframe(selectedFilter, option);
                    fetchBudgetData();
                  }}
                  accessible
                  accessibilityLabel={`Select ${option} tab`}
                >
                  <Text style={isActive ? styles.activeDayText : styles.dayText}>
                    {option}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </ScrollView>

          {/* ============ NET BALANCE CARD WITH REAL DATA ============ */}
          <View style={styles.netBalanceCard}>
            <Text style={styles.netBalanceLabel}>Net Balance</Text>
            {/* Show netBalance from the fetched data */}
            <Text style={styles.netBalanceAmount}>₹{netBalance}</Text>

            {/* Show a progress bar based on totalExpenses / totalBudget */}
            <View style={styles.progressContainer}>
              <View style={styles.progressLabelContainer}>
                <Text style={styles.progressLabelText}>
                  {progressPercent.toFixed(0)}%
                </Text>
                <Text style={styles.progressLabelRightText}>
                  ₹{totalBudget.toFixed(2)}
                </Text>
              </View>
              <View style={styles.progressBarBackground}>
                <View
                  style={[
                    styles.progressBarFill,
                    { width: `${progressPercent}%`,
                    backgroundColor: progressBarColor,
                    },
                  ]}
                />
              </View>
            </View>

            <Text 
              style={[
                styles.expenseInfoText, 
                progressPercent > 100 && { color: 'red' } // If overspent, text is red
              ]}
            >
              {progressMessage}
            </Text>

            {/* Income / Expenses Row */}
            <View style={styles.incomeExpensesRow}>
              <View style={styles.infoBox}>
                <Text style={styles.infoBoxLabel}>Income</Text>
                <Text style={styles.infoBoxValue}>₹{totalBudget}</Text>
              </View>
              <View style={styles.infoBox}>
                <Text style={styles.infoBoxLabel}>Expenses</Text>
                <Text style={styles.infoBoxValue}>₹{totExpenses}</Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {/* ---------------- BOTTOM SECTION (LIST) ---------------- */}
      
        <View style={styles.expenseListWrapper}>
          <FlatList
            data={expenses}
            renderItem={renderExpense}
            keyExtractor={(item) => item._id}
            contentContainerStyle={styles.expenseList}
          />
        </View>
      

      {/* ================== MODALS ================== */}
      {/* Search Modal */}
      <Modal visible={searchVisible} transparent animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <TextInput
              placeholder="Search transactions"
              placeholderTextColor="#777"
              style={styles.searchInput}
              value={searchText}
              onChangeText={setSearchText}
              accessible
              accessibilityLabel="Search input"
            />
            <TouchableOpacity
              onPress={() => setSearchVisible(false)}
              style={styles.closeButton}
              accessible
              accessibilityLabel="Close search"
            >
              <Ionicons name="close" size={24} color="#fff" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Date Picker Modal */}
      {showDatePicker && (
        <DateTimePicker value={subcategoryDate} mode="date" display="default" onChange={onDateChange} />
      )}

      {/* Filter Modal */}
      <Modal visible={filterModalVisible} transparent animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Picker
              selectedValue={selectedFilter}
              onValueChange={(val) => {
                setSelectedFilter(val);
                setActiveTab(null);
              }}
              style={styles.filterPicker}
              accessibilityLabel="Filter picker"
            >
              <Picker.Item label="Daily" value="Daily" />
              <Picker.Item label="Weekly" value="Weekly" />
              <Picker.Item label="Monthly" value="Monthly" />
              <Picker.Item label="Yearly" value="Yearly" />
            </Picker>
            <TouchableOpacity
              onPress={() => setFilterModalVisible(false)}
              style={styles.closeButton}
              accessible
              accessibilityLabel="Close filter"
            >
              <Ionicons name="close" size={24} color="#fff" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* ================== EDIT EXPENSE MODAL ================== */}
      {editingExpense && (
        <Modal visible={true} transparent animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContent}>
              <Text style={{ color: '#fff', fontSize: 18, marginBottom: 10 }}>Edit Expense</Text>
              {modalAlert && (
              <Text style={{ color:'red', fontSize: 16, marginBottom: 10 }}>{modalAlert ? alertMessage : ''}</Text>
              )}
              <TextInput
                style={styles.editInput}
                placeholder="Enter Amount"
                placeholderTextColor="#777"
                value={editAmount}
                onChangeText={setEditAmount}
                keyboardType="numeric"
              />

              <TextInput
                style={styles.editInput}
                placeholder="Enter Category"
                placeholderTextColor="#777"
                value={editCategory}
                onChangeText={setEditCategory}
              />

              <TextInput
                style={styles.editInput}
                placeholder="Enter Description"
                placeholderTextColor="#777"
                value={editDescription}
                onChangeText={setEditDescription}
              />

              {/* If you want date editing, you could add a date picker here */}
              
              <View style={{ flexDirection: 'row', marginTop: 15 }}>
                <TouchableOpacity
                  style={[styles.saveButton, { marginRight: 10 }]}
                  onPress={handleSaveEdit}
                >
                  <Text style={{ color: '#fff' }}>Update Expense</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.cancelButton]} onPress={handleCancelEdit}>
                  <Text style={{ color: '#fff' }}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      )}
      
    </SafeAreaView>
  );
};

/* =================== STYLES =================== */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  cardWrapper:{
    flex:1,
  },

  /* ---------- TOP SECTION (WHITE) ---------- */
  topContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingTop: 10,
    
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    marginHorizontal: 5,
    padding: 5,
  },
  titleText: {
    flex: 1,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '600',
    color: '#000',
  },
  headerIcons: {
    flexDirection: 'row',
  },
  subheaderText: {
    marginTop: 10,
    fontSize: 20,
    fontWeight: '700',
    color: '#000',
  },
  filterContainer: {
    marginTop: 15,
    backgroundColor: '#F7F7F7',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  filterButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterButtonText: {
    fontSize: 16,
    color: '#777',
  },
  dayTabsContainer: {
    marginTop: 15,
    marginBottom: 20,
    flexGrow: 0,
  },
  dayTab: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    backgroundColor: '#EAEAEA',
    marginRight: 10,
  },
  activeDayTab: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    backgroundColor: '#FFC107',
    marginRight: 10,
  },
  dayText: {
    color: '#777',
    textAlign: 'center',
  },
  activeDayText: {
    color: '#fff',
    textAlign: 'center',
  },

  // ============ Net Balance Card ============
  netBalanceCard: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#EEE',
    shadowColor: '#000',
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2,
  },
  netBalanceLabel: {
    fontSize: 14,
    color: '#777',
    marginBottom: 5,
  },
  netBalanceAmount: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 10,
  },
  progressContainer: {
    marginBottom: 10,
  },
  progressLabelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  progressLabelText: {
    color: '#777',
  },
  progressLabelRightText: {
    color: '#777',
  },
  progressBarBackground: {
    backgroundColor: '#EAEAEA',
    height: 10,
    borderRadius: 5,
  },
  progressBarFill: {
    backgroundColor: '#FFC107',
    height: '100%',
    borderRadius: 5,
  },
  expenseInfoText: {
    fontSize: 14,
    color: '#777',
    marginBottom: 15,
  },
  incomeExpensesRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoBox: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    padding: 10,
    borderRadius: 10,
    marginHorizontal: 5,
  },
  infoBoxLabel: {
    color: '#777',
    marginBottom: 5,
  },
  infoBoxValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },

  // ============ FlatList Styles ============
  expenseListWrapper: {
    height: 200, // Optional: Adjust height as needed
    marginBottom: 0, // Add spacing below
    backgroundColor:'#000',
    borderRadius:8,
  },
  expenseList: {
    padding: 10,
    
    
    
  },
  expenseCard: {
    backgroundColor: '#555',
    padding: 15,
    marginBottom: 10,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expenseText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  expenseDate: {
    color: '#777',
    fontSize: 14,
  },
  expenseActions: {
    flexDirection: 'row',
  },
  editButton: {
    backgroundColor: '#28a745',
    padding: 10,
    borderRadius: 4,
    marginRight: 10,
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    padding: 10,
    borderRadius: 4,
  },

  // ============ Modals ============
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#1A1A1A',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    alignItems: 'center',
  },
  editInput: {
    backgroundColor: '#2A2A2A',
    color: '#fff',
    borderRadius: 8,
    padding: 10,
    marginBottom: 15,
  },
  saveButton: {
    flex: 1,
    backgroundColor: '#28a745',
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
  },
  cancelButton: {
    flex: 1,
    backgroundColor: '#dc3545',
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    padding: 10,
    backgroundColor: '#2A2A2A',
    color: '#fff',
    borderRadius: 8,
    marginBottom: 15,
  },
  closeButton: {
    marginTop: 10,
  },
  filterPicker: {
    width: '100%',
    color: '#fff',
    backgroundColor: '#2A2A2A',
  },
});

export default TrackerScreen;
