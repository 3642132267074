import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, FlatList, TouchableOpacity, Switch, Appearance } from 'react-native';

const RecentOrders = ({ darkMode, toggleDarkMode }) => {
  const orders = [
    { id: '3000', date: 'May 9, 2024', customer: 'Leslie Alexander', event: 'Bear Hug: Live in Concert', amount: '$80.00' },
    { id: '3001', date: 'May 5, 2024', customer: 'Michael Foster', event: 'Six Fingers DJ Set', amount: '$299.00' },
    // Add more orders...
  ];

  return (
    <ScrollView style={[styles.container, darkMode ? styles.darkContainer : styles.lightContainer]}>
      {/* Dark Mode Toggle */}
      <View style={styles.toggleContainer}>
        <Text style={darkMode ? styles.darkText : styles.lightText}>Dark Mode</Text>
        <Switch value={darkMode} onValueChange={toggleDarkMode} />
      </View>

      <Text style={[styles.sectionTitle, darkMode ? styles.darkText : styles.lightText]}>Recent Orders</Text>

      {/* Tabular View */}
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={[styles.tableHeaderText, darkMode ? styles.darkText : styles.lightText]}>Order #</Text>
          <Text style={[styles.tableHeaderText, darkMode ? styles.darkText : styles.lightText]}>Date</Text>
          <Text style={[styles.tableHeaderText, darkMode ? styles.darkText : styles.lightText]}>Customer</Text>
          <Text style={[styles.tableHeaderText, darkMode ? styles.darkText : styles.lightText]}>Event</Text>
          <Text style={[styles.tableHeaderText, darkMode ? styles.darkText : styles.lightText]}>Amount</Text>
        </View>

        {/* Order Rows */}
        {orders.map(order => (
          <View key={order.id} style={styles.tableRow}>
            <Text style={[styles.tableCell, darkMode ? styles.darkText : styles.lightText]}>{order.id}</Text>
            <Text style={[styles.tableCell, darkMode ? styles.darkText : styles.lightText]}>{order.date}</Text>
            <Text style={[styles.tableCell, darkMode ? styles.darkText : styles.lightText]}>{order.customer}</Text>
            <Text style={[styles.tableCell, darkMode ? styles.darkText : styles.lightText]}>{order.event}</Text>
            <Text style={[styles.tableCell, darkMode ? styles.darkText : styles.lightText]}>{order.amount}</Text>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

const LeftPanel = ({ darkMode }) => (
  <View style={[styles.leftPanel, darkMode ? styles.darkContainer : styles.lightContainer]}>
    <TouchableOpacity style={styles.navItem}><Text style={darkMode ? styles.darkText : styles.lightText}>Home</Text></TouchableOpacity>
    <TouchableOpacity style={styles.navItem}><Text style={darkMode ? styles.darkText : styles.lightText}>Events</Text></TouchableOpacity>
    <TouchableOpacity style={styles.navItem}><Text style={darkMode ? styles.darkText : styles.lightText}>Orders</Text></TouchableOpacity>
    <TouchableOpacity style={styles.navItem}><Text style={darkMode ? styles.darkText : styles.lightText}>Settings</Text></TouchableOpacity>
  </View>
);

const App = () => {
  const [darkMode, setDarkMode] = useState(Appearance.getColorScheme() === 'dark');

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <View style={styles.appContainer}>
      {/* Left Panel */}
      <LeftPanel darkMode={darkMode} />

      {/* Main Content (Recent Orders) */}
      <RecentOrders darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </View>
  );
};

export default App;

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    flexDirection: 'row',  // To place the left panel next to the main content
  },
  container: {
    flex: 1,
    padding: 20,
  },
  lightContainer: {
    backgroundColor: '#f9f9f9',
  },
  darkContainer: {
    backgroundColor: '#1e1e1e',
  },
  toggleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  lightText: {
    color: '#000',
  },
  darkText: {
    color: '#fff',
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  table: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#ddd',
    padding: 10,
  },
  tableHeaderText: {
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
  },
  leftPanel: {
    width: 200,  // Width of the left panel
    padding: 20,
    backgroundColor: '#4f46e5', // Primary color for the left panel
  },
  navItem: {
    marginVertical: 10,
  },
});
