import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  TextInput,
  Modal,
  Dimensions,
  Alert,
  KeyboardAvoidingView,
  Platform,
  LayoutAnimation,
  UIManager,
  RefreshControl,
} from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
//import DateTimePicker from '@react-native-community/datetimepicker';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import { Picker } from '@react-native-picker/picker';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { useNavigation } from '@react-navigation/native';
import { baseURL } from '../apiConfig'; // <-- Make sure this is your server's base URL
import { LineChart, PieChart, BarChart } from 'react-native-chart-kit';
import * as Animatable from 'react-native-animatable';

// Enable LayoutAnimation on Android
if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export default function BudgetScreen() {
  // ----------------------- STATE -----------------------
  const [incomeName, setIncomeName] = useState('');
  const [incomeAmount, setIncomeAmount] = useState('');
  const [categories, setCategories] = useState([
    { name: 'Recurring Expenses', subcategories: [], budgeted: 0 },
    { name: 'Needs', subcategories: [], budgeted: 0 },
    { name: 'Wants', subcategories: [], budgeted: 0 },
  ]);

  const navigation = useNavigation();

  const [selectedCategory, setSelectedCategory] = useState('Recurring Expenses');
  const [subcategoryName, setSubcategoryName] = useState('');
  const [subcategoryAmount, setSubcategoryAmount] = useState('');
  const [subcategoryDate, setSubcategoryDate] = useState(new Date());
  
  //const [showDatePicker, setShowDatePicker] = useState(false);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState(null);
  const [savings, setSavings] = useState('');
  const [investments, setInvestments] = useState('');
  const [chartData, setChartData] = useState([]);
  const [userId, setUserId] = useState();
  const [budgetData, setBudgetData] = useState([]);
  const [isFormPrefilled, setIsFormPrefilled] = useState(false);
  const [timeRange, setTimeRange] = useState('1month'); 
  const [refreshing, setRefreshing] = useState(false);
  const [compareData, setCompareData] = useState(null);

  const [newCategoryName, setNewCategoryName] = useState('');
  const [editingCategoryIndex, setEditingCategoryIndex] = useState(null);

  const pieData = [
    {
      name: 'Recurring Expenses',
      
      color: '#FF5722',        // 1st color (Orange-Red)
      legendFontColor: '#fff',
      legendFontSize: 12,
    },
    {
      name: 'Needs',
      
      color: '#4CAF50',        // 2nd color (Green)
      legendFontColor: '#fff',
      legendFontSize: 12,
    },
    {
      name: 'Wants',
      
      color: '#FFC107',        // 3rd color (Yellow-ish)
      legendFontColor: '#fff',
      legendFontSize: 12,
    },
  ];

  // Additional modal state
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Monthly');

  // ----------------------- HOOKS -----------------------
  // Check for userId/uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        setUserId(uid);
        
        const uname = sessionStorage.getItem('username');
        if (!uid || !uname) {
          console.log('Error', 'You must be logged in to access this page.');
          navigation.navigate('Account');
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
        console.log('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };
    checkAuth();
  }, [navigation]);

    // 1) On mount (once userId is known), fetch compare data
    useEffect(() => {
      if (userId) {
        fetchCompareData(userId);
      }
    }, [userId]);
  
    // 2) Function to fetch "compare" data for current & previous month
    const fetchCompareData = async (uid) => {
      try {
        const response = await axios.get(`${baseURL}/budget/compare?userId=${uid}`);
        if (response.data.success) {
          if(response.data.prevMonthBudget != null){
            setCompareData(response.data);
          }else
          {
            console.log('No Previous Data Found!');
          }
        } else {
          console.log('No data to compare:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching compare data:', error);
      }
    };
  
    // 3) Helper to build the bar chart dataset for subcategories of "Recurring Expenses"
    const buildBarDataset = () => {
      if (!compareData) {
        console.error('Missing compareData:', compareData);
        return null;
      }
      const { currentMonthBudget, prevMonthBudget } = compareData || {};
      if (!currentMonthBudget) {
        console.warn('No data for current month. Setting default values.');
        return null;
      }
      if (!prevMonthBudget) {
        console.warn('No data for previous month. Comparison will be partial.');
      }
      if(compareData.currentMonthBudget){
        if (!compareData || !compareData.currentMonthBudget || !compareData.prevMonthBudget) {
          console.error('Missing or invalid compareData:', compareData);
          return null;
        }
        const { currentMonthBudget, prevMonthBudget } = compareData;
  
        if (!currentMonthBudget || !prevMonthBudget) {
          console.error('Missing budget data for one or both months.', {
            currentMonthBudget,
            prevMonthBudget,
          });
          return null;
        }
    
        // We'll combine subcategories from "Recurring Expenses" in both months
        const currentCat = currentMonthBudget.categories.find(c => c.name === 'Recurring Expenses');
        const prevCat = prevMonthBudget.categories.find(c => c.name === 'Recurring Expenses');
  
        if (!currentCat) {
          console.error('Recurring Expenses category not found in currentMonthBudget:', currentMonthBudget);
          return null;
        }
      
        if (!prevCat) {
          console.error('Recurring Expenses category not found in prevMonthBudget:', prevMonthBudget);
          return null;
        }
    
        if (!currentCat || !prevCat) {
          console.error('Recurring Expenses category not found in one or both budgets.', {
            currentCat,
            prevCat,
          });
          return null;
        }
    
        // Build a map: subcategoryName => { current: x, prev: y }
        const subMap = {};
    
        // Fill from current month
        currentCat.subcategories.forEach(sub => {
          subMap[sub.name] = {
            current: sub.amount,
            prev: 0,
          };
        });
        // Fill from previous month
        prevCat.subcategories.forEach(sub => {
          if (!subMap[sub.name]) {
            subMap[sub.name] = { current: 0, prev: sub.amount };
          } else {
            subMap[sub.name].prev = sub.amount;
          }
        });
  
        // Log the subMap for debugging
        console.log('Subcategory map:', subMap);
    
        // Now subMap has all subcategories from both months
        const labels = Object.keys(subMap);
        const currentData = labels.map(label => subMap[label].current);
        const prevData = labels.map(label => subMap[label].prev);
  
        // Ensure there is data to return
        if (labels.length === 0) {
          console.error('No subcategories to compare.');
          return null;
        }
    
        return { labels, currentData, prevData };
      }
    };

  // Function to merge default categories with server categories
  const mergeCategories = (defaultCats, serverCats) => {
    const merged = [...defaultCats.map(cat => ({ ...cat }))];
  
    serverCats.forEach(serverCat => {
      const existingIndex = merged.findIndex(cat => cat.name === serverCat.name);
      if (existingIndex === -1) {
        // New category from server
        merged.push(serverCat);
      } else {
        // Merge subcategories, budgeted amounts, etc.
        merged[existingIndex].subcategories = serverCat.subcategories || [];
        merged[existingIndex].budgeted = serverCat.budgeted || 0;
      }
    });
    return merged;
  };

  // Prefill the form if budget data is available
  useEffect(() => {
    const DEFAULT_CATEGORIES = [
      { name: 'Recurring Expenses', subcategories: [], budgeted: 0 },
      { name: 'Needs', subcategories: [], budgeted: 0 },
      { name: 'Wants', subcategories: [], budgeted: 0 },
    ];

    if (budgetData && budgetData.length > 0 && !isFormPrefilled) {
      const latestBudget = budgetData[0];
      const serverCategories = latestBudget.categories || [];
      const mergedCategories = mergeCategories(DEFAULT_CATEGORIES, serverCategories);

      console.log('Latest Budget Data: ', latestBudget);
      // Debug: see what categories exist
      latestBudget.categories.forEach(category => {
        console.log(`Category: ${category.name}`, 'Subcategories =>', category.subcategories);
      });

      setIncomeName(latestBudget.incomeName || '');
      setIncomeAmount(latestBudget.incomeAmount ? latestBudget.incomeAmount.toString() : '');
      setCategories(mergedCategories);
      setSavings(latestBudget.savings ? latestBudget.savings.toString() : '');
      setInvestments(latestBudget.investments ? latestBudget.investments.toString() : '');

      setIsFormPrefilled(true);
    }
  }, [budgetData]);

  // Fetch budget data once we have userId
  useEffect(() => {
    if (userId) {
      fetchBudgetData();
    }
  }, [userId]);

  // ----------------------- API CALLS -----------------------
  const fetchBudgetData = async () => {
    try {
      console.log('Fetching budget data for user:', userId);
      const response = await axios.get(`${baseURL}/budget/current?userId=${userId}`);
      if (response.data.success && response.data.budgets.length > 0) {
        const budgets = response.data.budgets;
        if (Array.isArray(budgets) && budgets.length > 0) {
          setBudgetData(budgets);
          generateChartData(budgets[0].categories);
        }
      } else {
        Alert.alert('Error', response.data.message);
        setBudgetData([]); // Set an empty array instead of undefined
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
      Alert.alert('Error', 'An error occurred while fetching budget data.');
    }
  };


  // A new function to fetch data for a given time range
  const fetchBudgetDataForRange = async (range) => {
    // (Optional) We define startDate & endDate in the backend or pass a param
    // For demo, let's just store it in timeRange state and handle in the backend if needed
    setTimeRange(range);

    // If you want the front-end to pass special query params:
    let url = `${baseURL}/budget?userId=${userId}`;
    if (range === '6months') url += '&months=6';
    if (range === '1year') url += '&months=12';
    // Or you handle this in the backend route. Example:
    try {
      const response = await axios.get(url);
      // Then we filter locally or rely on the backend returning the correct data
      if (response.data.success) {
        const budgets = response.data.budgets;
        // If we wanted to show multiple budgets, we might store them in a new state
        setBudgetData(budgets);
        // Possibly re-generate chart data for that range
        // ...
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
      Alert.alert('Error', 'An error occurred while fetching budget data.');
    }
  };

  // Example function to create line chart data for each category
  const generateCategoryComparisonData = (categoryName, budgets) => {
    // budgets might contain multiple months if we pulled from the backend
    // Return an array of sums for that category across all budgets in the time range
    // For simplicity, let's assume budgets are sorted from newest to oldest
    const reversedBudgets = [...budgets].reverse(); 
    const labels = reversedBudgets.map((b, i) => {
      // Could label by month or use i, or any date
      const d = new Date(b.createdAt);
      return d.toLocaleString('default', { month: 'short', year: 'numeric' });
    });
    const dataPoints = reversedBudgets.map((b) => {
      const cat = b.categories.find((c) => c.name === categoryName);
      if (!cat) return 0;
      return cat.subcategories.reduce((sum, sub) => sum + sub.amount, 0);
    });
    return { labels, dataPoints };
  };

  const submitBudget = async () => {
    console.log("Clicked on Submit Budget");
    let totalBudgetedAmount=0;
    
    if(budgetData.length > 0){
      const latestBudget = budgetData[0];
      totalBudgetedAmount = categories.reduce(
        (total, category) => total + category.budgeted,
        0
      );
    }else{
      console.warn("No existing budget data. Initializing a new budget.");
      totalBudgetedAmount = categories.reduce(
        (total, category) => total + category.budgeted,0
      );
    }
    
    // Calculate remaining income
    console.log('Total Budgeted Amount:', totalBudgetedAmount);
    console.log('Income Amount:', incomeAmount);
    const remainingIncome = parseFloat(incomeAmount) - totalBudgetedAmount;
    if (remainingIncome < 0) {
      Alert.alert('Error', 'Your budget exceeds your available income.');
      return;
    }
    console.log('Remaining Income:', remainingIncome);
    console.log('Savings:', savings);
    console.log('Investments:', investments);
    if(savings < 0 || investments < 0) {
      Alert.alert('Error', 'Savings and Investments cannot be negative.');
      return;
    }
    if(savings > remainingIncome) {
      Alert.alert('Error', 'Savings exceeds remaining income.');
      return;
    }
    if(investments > remainingIncome) {
      Alert.alert('Error', 'Investments exceeds remaining income.');
      return;
    }
    if((parseFloat(savings) + parseFloat(investments)) > remainingIncome) {
      Alert.alert('Error', 'Savings and Investments combined exceed remaining income.');
      return;
    }

    const budgetDataToSend = {
      userId,
      incomeName,
      incomeAmount: parseFloat(incomeAmount) || 0,
      categories,
      savings: parseFloat(savings) || 0,
      investments: parseFloat(investments) || 0,
    };

    console.log('Submitting budget:', budgetDataToSend);

    try {
      const response = await axios.post(`${baseURL}/budget`, budgetDataToSend);
      if (response.data.success) {
        Alert.alert('Success', 'Budget submitted successfully!');
        // Re-fetch budget data to update the UI
        fetchBudgetData();
      } else {
        Alert.alert('Error', 'Failed to submit budget.');
        console.log(response.data);
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while submitting budget.');
    }
  };

  // ----------------------- SUBCATEGORY HANDLERS -----------------------
  const addSubcategory = () => {
    if (!subcategoryName.trim() || !subcategoryAmount.trim()) {
      Alert.alert('Error', 'Please enter both subcategory name and amount.');
      return;
    }
  
    // Parse the subcategory amount
    const newSubcategoryAmount = parseFloat(subcategoryAmount);
    if (isNaN(newSubcategoryAmount) || newSubcategoryAmount <= 0) {
      Alert.alert('Error', 'Please enter a valid positive amount.');
      return;
    }
  
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.name === selectedCategory) {
          // Calculate total spent in the selected category
          const totalCategorySpent = category.subcategories.reduce((catTotal, sub) => catTotal + sub.amount, 0);
          const remainingCategoryBudget = category.budgeted - totalCategorySpent;
  
          // Check if the new subcategory amount exceeds the remaining category budget
          if (remainingCategoryBudget < newSubcategoryAmount) {
            Alert.alert(
              'Error',
              `Budget for "${selectedCategory}" exceeded. Remaining budget: ₹${remainingCategoryBudget.toFixed(2)}`
            );
            return category;
          }
  
          // If editing
          if (currentlyEditingIndex !== null) {
            const updatedSub = [...category.subcategories];
            updatedSub[currentlyEditingIndex] = {
              name: subcategoryName,
              amount: newSubcategoryAmount,
              date: subcategoryDate.toISOString(),
            };
            return { ...category, subcategories: updatedSub };
          }
  
          // If adding a new subcategory
          return {
            ...category,
            subcategories: [
              ...category.subcategories,
              {
                name: subcategoryName,
                amount: newSubcategoryAmount,
                date: subcategoryDate.toISOString(),
              },
            ],
          };
        }
        return category;
      })
    );
  
    // Reset fields
    setSubcategoryName('');
    setSubcategoryAmount('');
    setSubcategoryDate(new Date());
    setCurrentlyEditingIndex(null);
  
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const deleteSubcategory = index => {
    setCategories(prev =>
      prev.map(cat =>
        cat.name === selectedCategory
          ? {
              ...cat,
              subcategories: cat.subcategories.filter((_, i) => i !== index),
            }
          : cat
      )
    );
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const addCategory = () => {
    console.log('Adding new category:', newCategoryName);
    if (!newCategoryName.trim()) {
      Alert.alert("Error", "Category name cannot be empty.");
      return;
    }
  
    // Ensure category doesn't already exist
    if (categories.some((cat) => cat.name.toLowerCase() === newCategoryName.toLowerCase())) {
      Alert.alert("Error", "Category already exists.");
      return;
    }
  
    // Add new category to state
    const newCategory = {
      name: newCategoryName,
      subcategories: [],
      budgeted: 0,
    };
  
    setCategories((prevCategories) => [...prevCategories, newCategory]);
    setNewCategoryName('');
  };
  
  const editCategory = (index) => {
    const category = categories[index];
    
    Alert.prompt(
      "Edit Category",
      `Modify name for "${category.name}"`,
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Save",
          onPress: (newCategoryName) => {
            if (!newCategoryName.trim()) {
              Alert.alert("Error", "Category name cannot be empty.");
              return;
            }
  
            if (
              categories.some(
                (cat, i) => i !== index && cat.name.toLowerCase() === newCategoryName.toLowerCase()
              )
            ) {
              Alert.alert("Error", "Category name already exists.");
              return;
            }
  
            setCategories((prevCategories) =>
              prevCategories.map((cat, i) =>
                i === index ? { ...cat, name: newCategoryName } : cat
              )
            );
          },
        },
      ],
      "plain-text",
      category.name
    );
  };
  
  const deleteCategory = (index) => {
    const categoryToDelete = categories[index];
  
    if (!categoryToDelete) return;
  
    const hasSubcategories = categoryToDelete.subcategories.length > 0;
    const hasBudgetedAmount = categoryToDelete.budgeted > 0;
  
    Alert.alert(
      "Confirm Deletion",
      `The category "${categoryToDelete.name}" has ${hasSubcategories ? "subcategories" : ""}${hasSubcategories && hasBudgetedAmount ? " and " : ""}${hasBudgetedAmount ? "a budgeted amount" : ""}. Deleting it will remove these. Do you want to proceed?`,
      [
        { text: "Cancel", style: "cancel" },
        {
          text: "Delete",
          style: "destructive",
          onPress: () => {
            setCategories((prev) => prev.filter((_, i) => i !== index));
          },
        },
      ]
    );
  };

  const editSubcategory = index => {
    const category = categories.find(cat => cat.name === selectedCategory);
    const toEdit = category.subcategories[index];

    setSubcategoryName(toEdit.name);
    setSubcategoryAmount(toEdit.amount.toString());
    setSubcategoryDate(new Date(toEdit.date || new Date()));
    setCurrentlyEditingIndex(index);
  };

  // ----------------------- DATE & CHART -----------------------
  const onDateChange = (event, selectedDate) => {
    const currentDate = selectedDate || subcategoryDate;
    setDatePickerVisibility(false);
    setSubcategoryDate(currentDate);
  };

  const generateChartData = categoriesList => {
    if (!categoriesList) return;
    const data = categoriesList.map(category => ({
      name: category.name,
      total: category.subcategories.reduce((sum, sub) => sum + sub.amount, 0),
    }));
    console.log('CHART DATA =>', data);
    setChartData(data);
  };

  // ----------------------- BUDGET GOAL -----------------------
  const setBudgetGoal = (categoryName, amount) => {
    // Parse the budget amount input
    console.log("Here in setBudgetGoal",categoryName,amount);
    const newBudgetedAmount = parseFloat(amount);
    if (isNaN(newBudgetedAmount) || newBudgetedAmount < 0) {
      Alert.alert('Error', 'Please enter a valid positive budget amount.');
      return;
    }

    // Calculate total budgeted amount across all categories except the current one
    const totalOtherBudgets = categories.reduce((total, cat) => {
      return cat.name !== categoryName ? total + (cat.budgeted || 0) : total;
    }, 0);

    // Calculate remaining income
    const remainingIncome = parseFloat(incomeAmount) - totalOtherBudgets;

    // Check if the new budget exceeds the remaining income
    if (remainingIncome < newBudgetedAmount) {
      Alert.alert(
        'Error',
        `Budget exceeds remaining income. Remaining income: ₹${remainingIncome.toFixed(2)}`
      );
      return;
    }
    setCategories(prev =>
      prev.map(cat =>
        cat.name === categoryName ? { ...cat, budgeted: parseFloat(amount) || 0 } : cat
      )
    );
    console.log("Categories after setting budget goal",categories);
  };

  // ----------------------- ANIMATIONS -----------------------
  const fadeIn = {
    from: { opacity: 0 },
    to: { opacity: 1 },
  };

  const onRefresh = () => {
    setRefreshing(true);
    
    Promise.all([fetchBudgetData(), fetchBudgetDataForRange()])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  // ----------------------- RENDER -----------------------
  return (
    <View style={styles.container}>
      {/* ------------------ TOP (White) SECTION ------------------ */}
      <View style={styles.topContainer}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            accessible
            accessibilityLabel="Go back"
          >
            <Ionicons name="arrow-back" size={24} color="#fff" />
          </TouchableOpacity>

          <View style={styles.headerIcons}>
            {/* Search Icon */}
            <TouchableOpacity
              onPress={() => setSearchVisible(true)}
              accessible
              accessibilityLabel="Search transactions"
            >
              <Ionicons name="search" size={24} color="#fff" style={styles.icon} />
            </TouchableOpacity>
            {/* Filter Icon */}
            <TouchableOpacity
              onPress={() => setFilterModalVisible(true)}
              accessible
              accessibilityLabel="Open filter"
            >
              <Ionicons name="options" size={24} color="#fff" />
            </TouchableOpacity>
          </View>
        </View>

        {/* Search Modal */}
        <Modal visible={searchVisible} transparent animationType="slide">
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <TextInput
                placeholder="Search..."
                placeholderTextColor="#777"
                style={styles.searchInput}
                value={searchText}
                onChangeText={setSearchText}
                accessible
                accessibilityLabel="Search input"
              />
              <TouchableOpacity
                onPress={() => setSearchVisible(false)}
                style={styles.closeButton}
                accessible
                accessibilityLabel="Close search"
              >
                <Ionicons name="close" size={24} color="#fff" />
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Filter Modal */}
        <Modal visible={filterModalVisible} transparent animationType="slide">
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalText}>Filter by</Text>
              <Picker
                selectedValue={selectedFilter}
                onValueChange={val => setSelectedFilter(val)}
                style={{ width: '100%', color: '#fff', backgroundColor: '#2A2A2A' }}
              >
                <Picker.Item label="Daily" value="Daily" />
                <Picker.Item label="Weekly" value="Weekly" />
                <Picker.Item label="Monthly" value="Monthly" />
              </Picker>
              <TouchableOpacity
                onPress={() => setFilterModalVisible(false)}
                style={styles.closeButton}
                accessible
                accessibilityLabel="Close filter"
              >
                <Ionicons name="close" size={24} color="#fff" />
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>

      {/* ------------------ BOTTOM (Black) SECTION ------------------ */}
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: '#1A1A1A' }}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        <ScrollView contentContainerStyle={styles.bottomScrollContainer} refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }>
          {/* ---------- Income Details (Step 1) ---------- */}
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Income Details</Text>
            <TextInput
              style={styles.input}
              placeholder="Income Name"
              placeholderTextColor="#888"
              value={incomeName}
              onChangeText={setIncomeName}
            />
            <TextInput
              style={styles.input}
              placeholder="Available Amount (INR)"
              placeholderTextColor="#888"
              keyboardType="numeric"
              value={incomeAmount}
              onChangeText={setIncomeAmount}
            />
          </Animatable.View>

          {/* ---------- Budget Categories (Step 2) ---------- */}
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Budget Categories</Text>
            {categories.map((category, index) => {
              // For a little aesthetics: show a mini progress bar if there's a budgeted amount
              const totalSpent = category.subcategories.reduce((sum, sub) => sum + sub.amount, 0);
              const progress = category.budgeted ? (totalSpent / category.budgeted) * 100 : 0;

              return (
                <View key={category.name} style={styles.categoryItem}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.categoryName}>{category.name}</Text>

                    {/* Mini progress bar for each category */}
                    {category.budgeted > 0 && (
                      <View style={styles.categoryProgressContainer}>
                        <View style={[styles.categoryProgressFill, { width: `${Math.min(progress, 100)}%` }]} />
                      </View>
                    )}
                  </View>

                  <TextInput
                    style={styles.categoryBudgetInput}
                    placeholder="Set Budget (INR)"
                    placeholderTextColor="#888"
                    keyboardType="numeric"
                    value={category.budgeted ? category.budgeted.toString() : ''}
                    onChangeText={amount => setBudgetGoal(category.name, amount)}
                  />
                  <TouchableOpacity onPress={() => editCategory(index)} style={styles.iconButton}>
                    <Ionicons name="pencil-outline" size={18} color="#fff" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => deleteCategory(index)} style={styles.iconButton}>
                    <Ionicons name="trash-outline" size={18} color="#FF5722" />
                  </TouchableOpacity>
                </View>
              );
            })}
            <View style={styles.addCategoryContainer}>
              <TextInput
                style={styles.addCategoryInput}
                placeholder="New Category Name"
                placeholderTextColor="#888"
                value={newCategoryName}
                onChangeText={setNewCategoryName}
              />
              <TouchableOpacity
                style={styles.addCategoryButton}
                onPress={addCategory}
              >
                <Ionicons name={"add"} size={24} color="#000" />
              </TouchableOpacity>
            </View>
          </Animatable.View>

          {/* ---------- Transactions (Step 3) ---------- */}
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Transactions</Text>
            <Picker
              selectedValue={selectedCategory}
              onValueChange={value => setSelectedCategory(value)}
              style={styles.picker}
            >
              {categories.map(category => (
                <Picker.Item key={category.name} label={category.name} value={category.name} />
              ))}
            </Picker>

            <TextInput
              style={styles.input}
              placeholder="Transaction Name"
              placeholderTextColor="#888"
              value={subcategoryName}
              onChangeText={setSubcategoryName}
            />
            <TextInput
              style={styles.input}
              placeholder="Amount (INR)"
              placeholderTextColor="#888"
              keyboardType="numeric"
              value={subcategoryAmount}
              onChangeText={setSubcategoryAmount}
            />

            {/* Date Picker Trigger */}
            <TouchableOpacity style={styles.datePickerButton} onPress={() => setDatePickerVisibility(true)}>
              <Text style={styles.datePickerText}>
                {subcategoryDate ? subcategoryDate.toDateString() : 'Select Date'}
              </Text>
            </TouchableOpacity>
            {Platform.OS !== 'web' ? (
              <DateTimePickerModal
                isVisible={isDatePickerVisible}
                mode="date"
                onConfirm={(date) => {
                  setSubcategoryDate(date);
                  setDatePickerVisibility(false);
                }}
                onCancel={() => setDatePickerVisibility(false)}
                date={subcategoryDate}
              />
            ) : (
              <TextInput
                style={styles.input}
                placeholder="Enter Date (yyyy-mm-dd)"
                placeholderTextColor="#888"
                value={subcategoryDate.toISOString().slice(0, 10)}
                onChangeText={(text) => {
                  const newDate = new Date(text);
                  if (!isNaN(newDate)) setSubcategoryDate(newDate);
                }}
                accessible
                accessibilityLabel='Web Date Input'
              />
            )}

            {/* Add/Update Subcategory Button */}
            <TouchableOpacity style={styles.addButton} onPress={addSubcategory}>
              <Text style={styles.addButtonText}>
                {currentlyEditingIndex !== null ? 'Update Transaction' : 'Add Transaction'}
              </Text>
            </TouchableOpacity>

            {/* Display Subcategories for the selected category */}
            {categories
              .find(cat => cat.name === selectedCategory)
              ?.subcategories.map((sub, index) => (
                <View key={index} style={styles.subcategoryItem}>
                  <View>
                    <Text style={styles.subcategoryText}>{sub.name}</Text>
                    <Text style={styles.subcategoryAmount}>₹{sub.amount}</Text>
                    <Text style={styles.subcategoryDate}>{new Date(sub.date).toDateString()}</Text>
                  </View>
                  <View style={styles.actionButtons}>
                    <TouchableOpacity onPress={() => editSubcategory(index)} style={styles.editButton}>
                      <Ionicons name="pencil-outline" size={20} color="#fff" />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => deleteSubcategory(index)} style={styles.deleteButton}>
                      <Ionicons name="trash-outline" size={20} color="#fff" />
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
          </Animatable.View>

          {/* ---------- Savings & Investments (Step 4) ---------- */}
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Savings & Investments</Text>
            <TextInput
              style={styles.input}
              placeholder="Savings Amount (INR)"
              placeholderTextColor="#888"
              keyboardType="numeric"
              value={savings}
              onChangeText={setSavings}
            />
            <TextInput
              style={styles.input}
              placeholder="Investments Amount (INR)"
              placeholderTextColor="#888"
              keyboardType="numeric"
              value={investments}
              onChangeText={setInvestments}
            />
          </Animatable.View>

          {/* ---------- Submit Button ---------- */}
          <TouchableOpacity style={styles.submitButton} onPress={submitBudget}>
            <Text style={styles.submitButtonText}>Submit / Update Budget</Text>
          </TouchableOpacity>

          {/* ---------- Budget Overview (Step 5) ---------- */}
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Budget Overview</Text>
            {budgetData && budgetData.length > 0 ? (
              (() => {
                // Just showing the FIRST budget as "Overview"
                const budget = budgetData[0];

                return (
                  <View style={styles.budgetCard}>
                    {/* Budget Header */}
                    <View style={styles.budgetHeader}>
                      <Text style={styles.incomeName}>{budget.incomeName}</Text>
                      <Text style={styles.incomeAmount}>₹{budget.incomeAmount}</Text>
                    </View>

                    {/* Savings and Investments */}
                    <View style={styles.budgetDetails}>
                      <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>Savings</Text>
                        <Text style={styles.detailValue}>₹{budget.savings}</Text>
                      </View>
                      <View style={styles.detailItem}>
                        <Text style={styles.detailLabel}>Investments</Text>
                        <Text style={styles.detailValue}>₹{budget.investments}</Text>
                      </View>
                    </View>

                    {/* Categories and Subcategories */}
                    {budget.categories && budget.categories.length > 0 ? (
                      <View style={styles.categoriesContainer}>
                        {budget.categories.map((category, index) => {
                          const totalSpent = category.subcategories.reduce((sum, sub) => sum + sub.amount, 0);
                          const progress = category.budgeted
                            ? (totalSpent / category.budgeted) * 100
                            : 0;

                          return (
                            <View key={index} style={styles.categoryCard}>
                              <View style={styles.categoryHeader}>
                                <Text style={styles.categoryTitle}>{category.name}</Text>
                              </View>
                              {category.subcategories && category.subcategories.length > 0 ? (
                                category.subcategories.map((sub, subIndex) => (
                                  <View key={subIndex} style={styles.overviewSubcategoryItem}>
                                    <Text style={styles.subcategoryName}>{sub.name}</Text>
                                    <Text style={styles.subcategoryAmount}>₹{sub.amount}</Text>
                                    <Text style={styles.subcategoryDate}>
                                      {new Date(sub.date).toDateString()}
                                    </Text>
                                  </View>
                                ))
                              ) : (
                                <Text style={styles.noSubcategoriesText}>
                                  No transactions available
                                </Text>
                              )}

                              {/* Progress Bar */}
                              <View style={styles.budgetDetails}>
                                <Text style={styles.budgetDetail}>Budgeted: ₹{category.budgeted}</Text>
                                <View style={styles.divider} />
                                <Text style={styles.spentDetail}>Allotted: ₹{totalSpent}</Text>
                              </View>
                              <View style={styles.progressBarContainer}>
                                <View
                                  style={[
                                    styles.progressBarFill,
                                    { width: `${Math.min(progress, 100)}%` },
                                  ]}
                                />
                                <Text style={styles.progressText}>{progress.toFixed(1)}%</Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    ) : (
                      <Text>No categories available</Text>
                    )}
                  </View>
                );
              })()
            ) : (
              <Text>Loading budget data...</Text>
            )}

            {/* Chart */}
            <View style={styles.chartContainer}>
              {chartData.length > 0 ? (
                <LineChart
                  data={{
                    labels: chartData.map(item => item.name),
                    datasets: [{ data: chartData.map(item => item.total) }],
                  }}
                  width={Dimensions.get('window').width - 100}
                  height={220}
                  yAxisLabel="₹"
                  chartConfig={{
                    backgroundColor: '#000',
                    backgroundGradientFrom: '#1A1A1A',
                    backgroundGradientTo: '#1A1A1A',
                    decimalPlaces: 0,
                    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                    labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                    formatYLabel: (value) => parseInt(value,10).toString(),
                    style: { borderRadius: 16 },
                    propsForDots: {
                      r: '5',
                      strokeWidth: '2',
                      stroke: '#fff',
                    },
                  }}
                  style={{
                    marginVertical: 16,
                    borderRadius: 16,
                  }}
                  bezier
                />
              ) : (
                <Text style={{ color: '#fff' }}>No chart data available</Text>
              )}
            </View>
          </Animatable.View>

          {/* ======= Add Your 3 Buttons for Time Range ======= */}
          <View style={styles.rangeButtonContainer}>
            <TouchableOpacity
              style={[
                styles.rangeButton,
                timeRange === '1month' && styles.rangeButtonActive,
              ]}
              onPress={() => fetchBudgetDataForRange('1month')}
            >
              <Text style={styles.rangeButtonText}>1 Month</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.rangeButton,
                timeRange === '6months' && styles.rangeButtonActive,
              ]}
              onPress={() => fetchBudgetDataForRange('6months')}
            >
              <Text style={styles.rangeButtonText}>6 Months</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.rangeButton,
                timeRange === '1year' && styles.rangeButtonActive,
              ]}
              onPress={() => fetchBudgetDataForRange('1year')}
            >
              <Text style={styles.rangeButtonText}>1 Year</Text>
            </TouchableOpacity>
          </View>

          {/* === Example: Show line chart for each category separately === */}
          {budgetData.length > 1 ? (
            <View>
              <Text style={styles.sectionTitle}>Comparison by Category</Text>
              {['Recurring Expenses', 'Needs', 'Wants'].map((catName) => {
                const { labels, dataPoints } = generateCategoryComparisonData(
                  catName,
                  budgetData
                );
                return (
                  <View key={catName} style={styles.comparisonChartContainer}>
                    <Text style={styles.comparisonChartTitle}>{catName} Over {timeRange}</Text>
                    <LineChart
                      data={{
                        labels,
                        datasets: [{ data: dataPoints }],
                      }}
                      width={Dimensions.get('window').width - 60}
                      height={200}
                      chartConfig={{
                        backgroundColor: '#1A1A1A',
                        backgroundGradientFrom: '#1A1A1A',
                        backgroundGradientTo: '#1A1A1A',
                        decimalPlaces: 2,
                        color: (opacity = 1) => `rgba(255, 200, 0, ${opacity})`, 
                        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      }}
                      bezier
                      style={{
                        marginVertical: 10,
                        borderRadius: 16,
                      }}
                    />
                  </View>
                );
              })}
            </View>
          ) : null}

          {/* === Example: Pie Chart of Monthly consumption === */}
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Monthly Consumption (Pie Chart)</Text>
            {/* If we only have one budget in budgetData[0], create a slice for each category's total */}
            {budgetData.length > 0 && (
              () => {
                const singleBudget = budgetData[0];
                // Build pie data
                const pieData = singleBudget.categories.map((cat, index) => {
                  const catTotal = cat.subcategories.reduce(
                    (sum, sub) => sum + sub.amount,
                    0
                  );
                  return {
                    name: cat.name,
                    amount: catTotal,
                    color: index % 2 === 0 ? '#FF5722' : '#00E676', 
                    legendFontColor: '#fff',
                    legendFontSize: 12,
                  };
                });

                return (
                  <PieChart
                    data={pieData}
                    width={Dimensions.get('window').width - 60}
                    height={220}
                    chartConfig={{
                      backgroundColor: '#1A1A1A',
                      backgroundGradientFrom: '#1A1A1A',
                      backgroundGradientTo: '#1A1A1A',
                      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                    }}
                    accessor="amount"
                    backgroundColor="transparent"
                    paddingLeft="15"
                    center={[5, 5]}
                    absolute
                  />
                );
              }
            )()}
          </View>
          <Animatable.View animation={fadeIn} style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Compare Subcategories (Recurring Expenses)</Text>
            {(() => {
              if (!compareData) {
                return (
                  <View style={{ padding: 10 }}>
                    <Text style={{ color: '#fff' }}>Data is still loading...</Text>
                  </View>
                );
              }
              const dataset = buildBarDataset();
              if (!dataset) {
                return (
                  <View style={{ padding: 10 }}>
                    <Text style={{ color: '#fff' }}>
                      Not enough data to compare subcategories for two months.
                    </Text>
                  </View>
                );
              }

              // Build the bar chart data
              const barData = {
                labels: dataset.labels,
                datasets: [
                  {
                    data: dataset.currentData,
                    color: (opacity = 1) => `rgba(54, 162, 235, ${opacity})`, // Blue
                  },
                  {
                    data: dataset.prevData,
                    color: (opacity = 1) => `rgba(255, 99, 132, ${opacity})`, // Pink
                  },
                ],
              };

              return (
                <View style={{ marginTop: 10 }}>
                  <BarChart
                    data={barData}
                    width={Dimensions.get('window').width - 80}
                    height={600}
                    yAxisLabel="₹"
                    fromZero
                    
                    showValuesOnTopOfBars
                    chartConfig={{
                      backgroundGradientFrom: '#1A1A1A',
                      backgroundGradientTo: '#1A1A1A',
                      color: (opacity = 1) => `rgba(255,255,255,${opacity})`,
                      labelColor: (opacity = 1) => `rgba(255,255,255,${opacity})`,
                      barPercentage: 0.6,
                      style: {
                        marginVertical: 10,
                        borderRadius: 16,
                      },
                      formatYLabel: (value) => parseInt(value).toString(),
                    }}
                    style={{
                      marginVertical: 10,
                      borderRadius: 16,
                    }}
                    verticalLabelRotation={90}
                  />
                  <Text style={{ color: '#ccc', marginTop: 8 }}>
                    Blue: Current Month, Pink: Previous Month
                  </Text>
                </View>
              );
            })()}
          </Animatable.View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

// ----------------- STYLES -----------------
const styles = StyleSheet.create({
  // Overall container
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },

  // ------------------ TOP SECTION (WHITE) ------------------
  topContainer: {
    backgroundColor: '#000',
    padding: 0,
    paddingTop: 0, // extra padding if you want it below the notch
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcons: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 10,
  },

  // ------------------ BOTTOM SECTION (BLACK) ------------------
  bottomScrollContainer: {
    paddingHorizontal: 20,
    paddingBottom: 40,
  },

  // Modals
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalContent: {
    backgroundColor: '#1A1A1A',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    alignItems: 'center',
  },
  modalText: {
    color: '#fff',
    fontSize: 18,
    marginBottom: 15,
  },
  closeButton: {
    marginTop: 10,
  },
  searchInput: {
    width: '100%',
    padding: 10,
    backgroundColor: '#2A2A2A',
    color: '#fff',
    borderRadius: 8,
    marginBottom: 15,
  },

  // Sections
  sectionContainer: {
    backgroundColor: '#2A2A2A',
    padding: 20,
    borderRadius: 10,
    marginTop: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#fff',
  },

  // Inputs
  input: {
    height: 50,
    backgroundColor: '#1A1A1A',
    borderRadius: 8,
    paddingHorizontal: 15,
    fontSize: 16,
    marginBottom: 15,
    borderWidth: 1,
    borderColor: '#444',
    color: '#fff',
  },
  picker: {
    backgroundColor: '#1A1A1A',
    borderRadius: 8,
    marginBottom: 15,
    borderWidth: 1,
    borderColor: '#444',
    color: '#fff',
  },
  datePickerButton: {
    height: 50,
    backgroundColor: '#1A1A1A',
    borderRadius: 8,
    paddingHorizontal: 15,
    justifyContent: 'center',
    marginBottom: 15,
    borderWidth: 1,
    borderColor: '#444',
  },
  datePickerText: {
    fontSize: 16,
    color: '#fff',
  },

  // Category / Subcategories
  categoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  categoryName: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 5,
  },
  categoryProgressContainer: {
    height: 6,
    backgroundColor: '#666',
    borderRadius: 4,
    marginVertical: 4,
    width: '90%',
    overflow: 'hidden',
  },
  categoryProgressFill: {
    height: '100%',
    backgroundColor: '#FFC107',
  },
  categoryBudgetInput: {
    width: 120,
    height: 40,
    backgroundColor: '#1A1A1A',
    borderRadius: 8,
    paddingHorizontal: 10,
    fontSize: 16,
    borderWidth: 1,
    borderColor: '#444',
    color: '#fff',
    marginLeft: 15,
  },

  addButton: {
    backgroundColor: '#FFC107',
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: 15,
  },
  addButtonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: '600',
  },
  subcategoryItem: {
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subcategoryText: {
    fontSize: 16,
    color: '#fff',
  },
  subcategoryAmount: {
    fontSize: 16,
    color: '#fff',
  },
  subcategoryDate: {
    fontSize: 14,
    color: '#aaa',
  },
  actionButtons: {
    flexDirection: 'row',
  },
  editButton: {
    marginRight: 10,
  },
  deleteButton: {},

  // Submit Button
  submitButton: {
    backgroundColor: '#4CAF50',
    paddingVertical: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },

  // Budget Overview
  budgetCard: {
    backgroundColor: '#333',
    borderRadius: 12,
    padding: 15,
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#444',
  },
  budgetHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  incomeName: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },
  incomeAmount: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },
  budgetDetails: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 15,
  },
  divider: {
    width: 1, // Width of the divider
    backgroundColor: '#ccc', // Light gray color
    height: '80%', // Height of the divider relative to the content
    marginHorizontal: 10, // Space around the divider
  },
  detailItem: {
    alignItems: 'center',
  },
  detailLabel: {
    fontSize: 16,
    color: '#aaa',
  },
  detailValue: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },
  categoriesContainer: {
    marginTop: 10,
  },
  categoryCard: {
    backgroundColor: '#444',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
  },
  categoryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  categoryTitle: {
    fontSize: 16,
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  categoryBudget: {
    fontSize: 14,
    color: '#fff',
  },
  overviewSubcategoryItem: {
    backgroundColor: '#555',
    padding: 8,
    borderRadius: 6,
    marginBottom: 5,
  },
  subcategoryName: {
    fontSize: 14,
    color: '#fff',
    fontWeight: '600',
  },
  noSubcategoriesText: {
    fontSize: 14,
    color: '#ccc',
    marginVertical: 10,
  },
  progressBarContainer: {
    height: 20,
    backgroundColor: '#666',
    borderRadius: 5,
    marginTop: 10,
    overflow: 'hidden',
  },
  progressBarFill: {
    height: '100%',
    backgroundColor: '#FFC107',
  },
  progressText:{
    position: 'absolute',
    left: 10, // Align text to the left side
    top: 0, // Center the text vertically
    fontSize: 12,
    fontWeight: 'bold',
    color: '#333',
  },
  budgetDetail: {
    fontSize: 14,
    color: '#aaa',
  },
  spentDetail: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 'bold',
  },
  // Chart
  chartContainer: {
    marginVertical: 20,
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    borderRadius: 20,
  },

  rangeButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
    marginBottom: 20,
  },
  rangeButton: {
    backgroundColor: '#333',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  rangeButtonActive: {
    backgroundColor: '#FFC107',
  },
  rangeButtonText: {
    color: '#fff',
    fontSize: 14,
  },
  comparisonChartContainer: {
    backgroundColor: '#2A2A2A',
    borderRadius: 10,
    padding: 10,
    marginVertical: 10,
  },
  comparisonChartTitle: {
    color: '#fff',
    fontSize: 16,
    marginBottom: 10,
  },
  iconButton: {
    marginLeft: 10,
    padding: 8,
    backgroundColor: '#2A2A2A',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  addCategoryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
  },
  
  addCategoryInput: {
    flex: 1,
    backgroundColor: '#1A1A1A',
    borderRadius: 8,
    paddingHorizontal: 10,
    fontSize: 16,
    borderWidth: 1,
    borderColor: '#444',
    color: '#fff',
    marginRight: 10,
  },
  
  addCategoryButton: {
    backgroundColor: '#FFC107',
    padding: 10,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
