import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, SafeAreaView } from 'react-native';
import { TabView, SceneMap } from 'react-native-tab-view';
import * as Progress from 'react-native-progress'; // For progress bars
import SvgSpend from '../assets/spend.png';
import SvgSpend_two from '../assets/spend2.png';
// First Onboarding Screen
const FirstOnboarding = () => (
  <View style={styles.scene}>
    <Image source={require('../assets/spend.png')} style={[styles.logo,{width:300,height:300,}]} />
  </View>
);

// Second Onboarding Screen
const SecondOnboarding = () => (
  <View style={styles.scene}>
    <Image source={require('../assets/spend2.png')} style={[styles.logo,{width:300,height:300,}]} />
  </View>
);

const OnboardingScreen = ({ navigation }) => {
  const [index, setIndex] = useState(0); // Tab index
  const [routes] = useState([
    { key: 'first', title: 'First' },
    { key: 'second', title: 'Second' },
  ]);
  const [progress, setProgress] = useState(0); // Track progress bar filling

  useEffect(() => {
    const interval = 100; // Interval to update progress bar
    const progressTimer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 1) return 0;
        return prev + 0.02; // Fills bar every interval until full
      });
    }, interval);

    const timer = setInterval(() => {
      setIndex((prevIndex) => {
        setProgress(0); // Reset progress when changing tabs
        return (prevIndex + 1) % routes.length; // Cycle tabs
      });
    }, 5000); // Change tab every 5 seconds

    if (index === routes.length - 1) {
      setTimeout(() => {
        navigation.replace('Account'); // Redirect after onboarding
      }, 5000);
    }

    return () => {
      clearInterval(timer); // Cleanup interval
      clearInterval(progressTimer); // Cleanup progress timer
    };
  }, [index, routes.length, navigation]);

  const renderScene = SceneMap({
    first: FirstOnboarding,
    second: SecondOnboarding,
  });

  return (
    <SafeAreaView style={styles.safeArea}>
    
      {/* Progress bar */}
      <View style={styles.progressContainer}>
        <Progress.Bar
          progress={index === 0 ? progress : 1} // Show full if second onboarding
          width={100}
          height={5}
          color="#EFBF04"
          unfilledColor="#666"
          borderWidth={0}
        />
        <Progress.Bar
          progress={index === 1 ? progress : 0} // Progress for second onboarding
          width={100}
          height={5}
          color="#EFBF04"
          unfilledColor="#666"
          borderWidth={0}
        />
      </View>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: Dimensions.get('window').width }}
        swipeEnabled={false} // Disable manual swipe
        renderTabBar={() => null} // Hide the default TabBar
      />
    
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'black', // Black background for the SafeAreaView
  },
  container: {
    flex: 1,
    backgroundColor: 'black', // Full page black background
  },
  scene: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black', // Black background per screen
  },
  progressContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 10,
    backgroundColor: 'black',
  },
});

export default OnboardingScreen;
