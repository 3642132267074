// BlogEditor.js
import React, {useEffect, useState, useRef } from 'react';
import ReactQuill, {Quill} from 'react-quill'; // For Web
import 'react-quill/dist/quill.snow.css'; // Web-specific CSS

import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Alert,
  ScrollView,
  Image,
  Switch,
  Platform,
} from 'react-native';
import { RichEditor, RichToolbar, actions } from 'react-native-pell-rich-editor';
import * as ImagePicker from 'expo-image-picker'; // For image selection
import axios from 'axios';
import { baseURL } from '../apiConfig';

import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

const BlogEditor = ({ blog, onSave, onCancel }) => {
  const [title, setTitle] = useState(blog.title || '');
  const [content, setContent] = useState(blog.content || '');
  const [featuredImage, setFeaturedImage] = useState(blog.featuredImage || '');
  const [excerpt, setExcerpt] = useState(blog.excerpt || '');
  const richText = useRef();
  const [isFeatured, setIsFeatured] = useState(blog.featured || false);
  const [tags, setTags] = useState(blog.tags || '');

  // This is an effect to log changes in the editor
  useEffect(() => {
    console.log(content);
  }, [content]);
  
  // Function to save the blog (create or update)
  const handleSave = async () => {
    if (!title.trim()) {
      Alert.alert('Validation Error', 'Title is required.');
      return;
    }
    console.log("Here in Handle Save");
    try {
      const token = sessionStorage.getItem('token');
      console.log("Token: ", token);
      const blogData = {
        title,
        content,
        featuredImage,
        excerpt,
        featured: isFeatured,
        tags: tags.split(',').map((tag) => tag.trim()),
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        }
      };

      if (blog._id) {
        // Update existing blog
        await axios.put(`${baseURL}/blogs/${blog._id}`, blogData,config);
      } else {
        // Create new blog
        console.log("Posting New Blog");
        await axios.post(`${baseURL}/blogs`, blogData,config);
      }
      Alert.alert('Success', 'Blog saved successfully.');
      onSave();
    } catch (error) {
      console.error('Error saving blog:', error);
      Alert.alert('Error', 'Failed to save blog.');
    }
  };

  const handleImageUpload = async (uri) => {
    const formData = new FormData();
    const filename = uri.split('/').pop();
    const type = `image/${filename.split('.').pop()}`;
  
    formData.append('featuredImage', {
      uri,
      name: filename,
      type,
    });
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(`${baseURL}/blogs/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setFeaturedImage(response.data.url); // Set the uploaded image URL
      Alert.alert('Image Uploaded', 'Featured image uploaded successfully.');
    } catch (error) {
      console.error('Image upload failed:', error);
      Alert.alert('Error', 'Failed to upload image.');
    }
  };

  // Function to pick an image from the gallery
  const handleImagePick = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (permissionResult.granted === false) {
      Alert.alert('Permission Required', 'Permission to access the gallery is required!');
      return;
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
    });

    if (!pickerResult.cancelled) {
      // You can upload the image to your server here and get the image URL
      // For demonstration, we'll use the local URI
      setFeaturedImage(pickerResult.uri);
      await handleImageUpload(pickerResult.uri);
    }
  };

  // Define toolbar and module settings
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { 'header': '3' }, { 'header': '4' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
    blotFormatter: {},
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];


  // Define the custom icons for headers
/* const icon_h1 = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
        <path d="M1.992,12.728 C1.81066576,12.9093342 1.58966797,13 1.329,13 C1.06833203,13 0.84733424,12.9093342 0.666,12.728 C0.48466576,12.5466658 0.394,12.325668 0.394,12.065 L0.394,1.525 C0.394,1.26433203 0.48466576,1.04333424 0.666,0.862 C0.84733424,0.68066576 1.06833203,0.59 1.329,0.59 C1.58966797,0.59 1.81066576,0.68066576 1.992,0.862 C2.17333424,1.04333424 2.264,1.26433203 2.264,1.525 L2.264,5.503 C2.264,5.60500051 2.31499949,5.656 2.417,5.656 L7.381,5.656 C7.48300051,5.656 7.534,5.60500051 7.534,5.503 L7.534,1.525 C7.534,1.26433203 7.62466576,1.04333424 7.806,0.862 C7.98733424,0.68066576 8.20833203,0.59 8.469,0.59 C8.72966797,0.59 8.95066576,0.68066576 9.132,0.862 C9.31333424,1.04333424 9.404,1.26433203 9.404,1.525 L9.404,12.065 C9.404,12.325668 9.31333424,12.5466658 9.132,12.728 C8.95066576,12.9093342 8.72966797,13 8.469,13 C8.20833203,13 7.98733424,12.9093342 7.806,12.728 C7.62466576,12.5466658 7.534,12.325668 7.534,12.065 L7.534,7.271 C7.534,7.16899949 7.48300051,7.118 7.381,7.118 L2.417,7.118 C2.31499949,7.118 2.264,7.16899949 2.264,7.271 L2.264,12.065 C2.264,12.325668 2.17333424,12.5466658 1.992,12.728 Z" />
    </g>
</svg>`;

const icon_h2 = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
        <path d="M1.992,12.728 C1.81066576,12.9093342 1.58966797,13 1.329,13 C1.06833203,13 0.84733424,12.9093342 0.666,12.728 C0.48466576,12.5466658 0.394,12.325668 0.394,12.065 L0.394,1.525 C0.394,1.26433203 0.48466576,1.04333424 0.666,0.862 C0.84733424,0.68066576 1.06833203,0.59 1.329,0.59 C1.58966797,0.59 1.81066576,0.68066576 1.992,0.862 C2.17333424,1.04333424 2.264,1.26433203 2.264,1.525 L2.264,5.503 C2.264,5.60500051 2.31499949,5.656 2.417,5.656 L7.381,5.656 C7.48300051,5.656 7.534,5.60500051 7.534,5.503 L7.534,1.525 C7.534,1.26433203 7.62466576,1.04333424 7.806,0.862 C7.98733424,0.68066576 8.20833203,0.59 8.469,0.59 C8.72966797,0.59 8.95066576,0.68066576 9.132,0.862 C9.31333424,1.04333424 9.404,1.26433203 9.404,1.525 L9.404,12.065 C9.404,12.325668 9.31333424,12.5466658 9.132,12.728 C8.95066576,12.9093342 8.72966797,13 8.469,13 C8.20833203,13 7.98733424,12.9093342 7.806,12.728 C7.62466576,12.5466658 7.534,12.325668 7.534,12.065 L7.534,7.271 C7.534,7.16899949 7.48300051,7.118 7.381,7.118 L2.417,7.118 C2.31499949,7.118 2.264,7.16899949 2.264,7.271 L2.264,12.065 C2.264,12.325668 2.17333424,12.5466658 1.992,12.728 Z" />
    </g>
</svg>`; */


const icon_h3 = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h3" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="3" font-family="Helvetica Neue, Helvetica, Arial, sans-serif" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">3</tspan>
            </text>
        </g>
    </g>
</svg>`

const icon_h4 = `
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h4" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="4" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">4</tspan>
            </text>
        </g>
    </g>
</svg>`

// Adding custom icons to Quill's toolbar
/* Quill.import('ui/icons').header[1] = icon_h1;
Quill.import('ui/icons').header[2] = icon_h2; */
Quill.import('ui/icons').header[3] = icon_h3;
Quill.import('ui/icons').header[4] = icon_h4;

  return (
    <ScrollView style={styles.container}>
      <TextInput
        placeholder="Blog Title"
        style={styles.titleInput}
        value={title}
        onChangeText={setTitle}
      />
      
      <Text style={styles.sectionLabel}>Content:</Text>
      {/* Platform check: web or mobile */}
      {Platform.OS === 'web' ? (
        // WYSIWYG for Web: Draft.js
        <View style={styles.editorContainer}>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
            bounds={'#root'}
            placeholder="Start writing here..."
          />
        </View>
      ) : (
        // WYSIWYG for Mobile: RichEditor and RichToolbar
        <View style={styles.richTextContainer}>
          <RichEditor
            ref={richText}
            initialContentHTML={content}
            onChange={setContent}
            style={styles.richTextEditor}
            placeholder="Start writing here..."
          />
          <RichToolbar
            editor={richText}
            actions={[
              actions.setBold,
              actions.setItalic,
              actions.setUnderline,
              actions.heading1,
              actions.insertBulletsList,
              actions.insertOrderedList,
              actions.insertImage,
            ]}
            iconMap={{
              [actions.heading1]: ({ tintColor }) => (
                <Text style={{ color: tintColor, fontSize: 16 }}>H1</Text>
              ),
            }}
            onPressAddImage={handleImagePick}
          />
        </View>
      )}
      <TouchableOpacity style={styles.imageButton} onPress={handleImagePick}>
        <Text style={styles.imageButtonText}>Set Featured Image</Text>
      </TouchableOpacity>
      {featuredImage ? (
        <Image source={{ uri: featuredImage }} style={styles.featuredImage} />
      ) : null}
      <Text style={styles.sectionLabel}>Excerpt:</Text>
      <TextInput
        placeholder="Add an excerpt..."
        style={styles.excerptInput}
        value={excerpt}
        onChangeText={setExcerpt}
        multiline
      />
      <Text style={styles.sectionLabel}>Featured:</Text>
      <Switch
        value={isFeatured}
        onValueChange={setIsFeatured} // Update the state on toggle
      />
      <Text style={styles.sectionLabel}>Tags (comma-separated):</Text>
      <TextInput
        placeholder="Add tags..."
        style={styles.input}
        value={tags}
        onChangeText={setTags}
      />
      <View style={styles.buttonRow}>
        <TouchableOpacity style={styles.saveButton} onPress={handleSave}>
          <Text style={styles.saveButtonText}>Save</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
          <Text style={styles.cancelButtonText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

export default BlogEditor;

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  titleInput: {
    fontSize: 24,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderColor: '#ccc',
    marginBottom: 15,
  },
  sectionLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 15,
    marginBottom: 5,
  },
  richTextContainer: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    minHeight: 300,
    marginBottom: 15,
  },
  richTextEditor: {
    flex: 1,
    minHeight: 300,
  },
  editorContainer: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    minHeight: 300,
    padding: 10,
  },
  imageButton: {
    backgroundColor: '#2196F3',
    padding: 10,
    borderRadius: 8,
    alignItems: 'center',
  },
  imageButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  featuredImage: {
    width: '100%',
    height: 200,
    marginTop: 10,
  },
  excerptInput: {
    minHeight: 60,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    textAlignVertical: 'top',
  },
  buttonRow: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  saveButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 8,
    flex: 1,
    marginRight: 5,
    alignItems: 'center',
  },
  saveButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  cancelButton: {
    backgroundColor: '#E74C3C',
    padding: 10,
    borderRadius: 8,
    flex: 1,
    marginLeft: 5,
    alignItems: 'center',
  },
  cancelButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});
