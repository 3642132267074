import React, { useState, useEffect, useRef } from 'react';
import { 
  View, 
  Text, 
  TextInput, 
  TouchableOpacity,  
  Alert, 
  KeyboardAvoidingView, 
  ScrollView, 
  Platform, 
  RefreshControl,
  FlatList,
} from 'react-native';
import { ScaledSheet } from 'react-native-size-matters';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Picker } from '@react-native-picker/picker'; // Import Picker
import * as DocumentPicker from 'expo-document-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import Slider from '@react-native-community/slider';
import { baseURL } from '../apiConfig';

const AddRecordScreen = ({ navigation }) => {
  const [transactionName, setTransactionName] = useState('');
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [transactionDescription, setTransactionDescription] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isExpense, setIsExpense] = useState(true);
  const [amount, setAmount] = useState(0);
  const [category, setCategory] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [userId, setUserId] = useState(null);
  const [budgetData, setBudgetData] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [incomeAmount, setIncomeAmount] = useState(0)
  const [allocatedAmount, setAllocatedAmount] = useState(0); // Amount allocated to goals
  const [refreshing, setRefreshing] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toDateString());
  const expensesRef = useRef([]);

  // Check for uid and uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        setUserId(uid);
        const uname = sessionStorage.getItem('username');

        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          navigation.navigate("Account");
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };

    checkAuth();
  }, [navigation]);
  const fetchExpenses = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/expenses/currentdate`, { params: { userId } });
      if (response.data.success) {
        setExpenses(response.data.expenses);
        console.log("Expenses: ", response.data.expenses);
      } else {
        Alert.alert('No Expenses', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };
  const fetchGoals = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/goals`, { params: { userId }});
      
      if (response.data.success) {
        setGoals(response.data.goals);
        console.log("Goals: ", response.data.goals);
      } 
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  const fetchBudgetData = async () => {
    try {
      const response = await axios.get(`${baseURL}/budget?userId=${userId}`);
      if (response.data.success) {
        const budgets = response.data.budgets;
        if (Array.isArray(budgets) && budgets.length > 0) {
          console.log("Budgets: ", budgets);
          setBudgetData(budgets);
        }
      } else {
        Alert.alert('Error', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
      Alert.alert('Error', 'An error occurred while fetching budget data.');
    }
  };

  useEffect(() => {
    
    if (userId) {
      fetchExpenses();
      fetchBudgetData();
      fetchGoals();
    }
  }, []);

  useEffect(() => {
    fetchExpenses().then((data) => {
      expensesRef.current = data;
      setExpenses(data); 
    });
  }, []);

  // Handle date change from the DateTimePicker
  const handleDateChange = (event, selectedDate) => {
    const currentDate = selectedDate || transactionDate;
    setShowDatePicker(false);
    setTransactionDate(currentDate);
  };

  // Add transaction to the daily expenses list
  const handleAddExpense = () => {
    if (!transactionName || !amount || !category) {
      Alert.alert('Error', 'Please fill all fields.');
      return;
    }

    const newExpense = {
      userId,
      date: transactionDate.toISOString(),
      description: transactionDescription,
      amount: parseFloat(amount),
      parent_category:category,
      category: transactionName,
      type: 'Expense',
    };

    setExpenses((prevExpenses) => [...prevExpenses, newExpense]);
    setTransactionName('');
    setAmount('');
    setTransactionDescription('');
  };

  const handleEditExpense = (index) => {
    const selectedExpense = expenses[index];
    
    // Populate the form fields with the selected expense details
    setTransactionName(selectedExpense.category);
    setTransactionDescription(selectedExpense.description);
    setAmount(String(selectedExpense.amount));
    setCategory(selectedExpense.parent_category);
    setTransactionDate(new Date(selectedExpense.date));
  
    // Remove the item from the list temporarily
    /* const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses); */
    setExpenses((prevExpenses) => prevExpenses.filter((_, i) => i !== index));
  };

  const handleDeleteExpense = (index) => {
    Alert.alert(
      "Confirm Delete",
      "Are you sure you want to delete this expense?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Delete",
          onPress: () => {
            /* const updatedExpenses = [...expenses];
            updatedExpenses.splice(index, 1);
            setExpenses(updatedExpenses); */
            setExpenses((prevExpenses) => prevExpenses.filter((_, i) => i !== index));
          },
          style: "destructive",
        },
      ]
    );
  };

  // Save all expenses to the backend
  const handleSaveExpenses = async () => {
    if (expenses.length === 0) {
      Alert.alert('No Expenses', 'No expenses to save.');
      return;
    }

    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.post(`${baseURL}/transactions/bulk-expense`, { expenses,userId });
      if (response.data.success) {
        Alert.alert('Success', 'Expenses saved successfully!');
        setExpenses([]); // Clear the list after saving
      } else {
        Alert.alert('Error', 'Failed to save expenses.');
      }
    } catch (error) {
      console.error('Error saving expenses:', error);
      Alert.alert('Error', 'An error occurred while saving expenses.');
    }
  };

  // Handle adding the transaction
  const handleAddTransaction = async () => {
    try {
      handleSaveExpenses();
      if (!userId || !amount || !transactionDate || !category) {
        Alert.alert('Validation Error', 'Please fill in all required fields.');
        return;
      }
      if(!isExpense){
        if(allocatedAmount && !selectedGoal){
          Alert.alert('Validation Error', 'Please select a goal to allocate the income.');
          return
        }
        if(selectedGoal && !allocatedAmount){
          Alert.alert('Validation Error', 'Please allocate the income to the selected goal.');
          return
        }
        if(!transactionName){
          Alert.alert('Validation Error', 'Please enter the source of the income.');
          return
        }
        if(!allocatedAmount && amount>0){
          Alert.alert("Allocated Amount", "Please allocate the income to the selected goal.");
          console.log("Amount: ", amount);
          console.log("Allocated Amount: ", allocatedAmount);
          setIncomeAmount(amount);
        }
      }
      if(isExpense){
        if(!transactionName){
          Alert.alert('Validation Error', 'Please select a subcategory for the expense.');
          return
        }
        // Use the latest budget data
        const latestBudget = budgetData[0];
        if (!latestBudget) {
          Alert.alert('Error', 'No budget data found.');
          return;
        }

        // Find the selected subcategory in the budgetData
        let selectedSubcategory = null;
        for (const cat of latestBudget.categories) {
          const subcat = cat.subcategories.find((sub) => sub.name === transactionName);
          if (subcat) {
            selectedSubcategory = subcat;
            break;
          }
        }

        if (!selectedSubcategory) {
          Alert.alert('Error', 'Selected subcategory not found in the budget.');
          return;
        }

        // Calculate the total expenses for the selected category
        const totalExpensesForCategory = expenses
          .filter((expense) => expense.category === transactionName)
          .reduce((total, expense) => total + expense.amount, 0);

        // Calculate remaining budget
        const remainingBudget = selectedSubcategory.amount - totalExpensesForCategory;
        console.log("Selected Subcategory: ", selectedSubcategory);
        console.log("Total Expenses for Category: ", totalExpensesForCategory);
        console.log("selectedSubcategory.budgeted: ", selectedSubcategory.budgeted);
        console.log("Remaining Budget: ", remainingBudget);
        // Check if the new expense exceeds the budget
        if (amount > remainingBudget) {
          Alert.alert(
            'Budget Exceeded',
            `This expense exceeds the remaining budget for the category by ₹${(amount - remainingBudget).toFixed(
              2
            )}.`
          );
          return;
        }
      }
      
    
      const transactionData = {
        userId,
        date: transactionDate,
        description: transactionDescription,
        amount: incomeAmount>0 ? incomeAmount : amount,
        parent_category:category,
        category:transactionName,
        type: isExpense ? 'Expense' : 'Income',
        attachment,
      };
      console.log("Transaction Data: ", transactionData);
      const url = `${baseURL}/transactions/${isExpense ? 'expense' : 'income'}`;
      if(!isExpense || incomeAmount>0 || amount > 0){
        const response = await axios.post(url, transactionData);
        if(response.data.success){
          setIncomeAmount(0);
          setAmount(0);
          setTransactionDescription('');
          setCategory('');
          setTransactionName('');
          setAttachment(null);
        }
        Alert.alert('Success', 'Transaction added successfully!');
        console.log('Transaction added successfully:', response.data);

        // Refresh the data
        fetchExpenses();
        fetchBudgetData();
        fetchGoals();
      }
      if(allocatedAmount>0){
        Alert.alert("Allocated Amount: " + allocatedAmount);
        handleAllocate();
      }
    } catch (error) {
      console.error('Error adding transaction:', error);
      Alert.alert('Error', 'An error occurred while adding the transaction.');
    }
  };

  // Handle adding an attachment
  const handleAddAttachment = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: '*/*',
      });                         
      if (result.type === 'success') {
        setAttachment(result);
        console.log('Attachment name:', result.name);
        console.log('Attachment added:', result.uri);
      }
    } catch (error) {
      console.error('Error picking attachment:', error);
    }
  };

  const handleAmountChange = (value) => {
    const numericValue = parseFloat(value) || 0; // Convert string to number, default to 0 if invalid
    console.log("Amount is: ", numericValue);
    setAmount(numericValue);
  };


  // Handle slider change
  const handleSliderChange = (value) => {
    const remainingAmount = amount - value;
    console.log(`Allocated Amount: ${value}, Remaining Amount: ${amount - value}`);
    console.log("Income Amount: ", amount-value);
    setAllocatedAmount(parseFloat(value) || 0);
    setIncomeAmount(remainingAmount);
    console.log("Allocated Amount: ", allocatedAmount);
    console.log("Income Amount: ", incomeAmount);

  };

  useEffect(() => {
    console.log(`Allocated Amount (State): ${allocatedAmount}`);
    console.log(`Income Amount (State): ${incomeAmount}`);
  }, [allocatedAmount, incomeAmount]);
  

  // Handle allocated amount text input
  const handleAllocatedAmountInputChange = (value) => {
    const numericValue = parseFloat(value) || 0;

    // Ensure allocated amount does not exceed the total income
    if (numericValue <= amount) {
      setAllocatedAmount(numericValue);
      //setAmount(amount - numericValue);
    } else {
      Alert.alert('Invalid Input', 'Allocated amount cannot exceed total income.');
    }
  };

  const handleAllocate = async () => {
    try {
      const data = {
        userId,
        allocatedAmount,
        selectedGoal,
      };

      const response = await axios.post(`${baseURL}/goals/allocate`, data);
      if (response.data.success) {
        Alert.alert('Amount allocated to goals successfully!');
        setAllocatedAmount(0);
        setSelectedGoal(null);
      }
    } catch (error) {
      console.error('Error allocating money to goals:', error);
      Alert.alert('Error', 'Failed to allocate money to goals.');
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    
    Promise.all([fetchExpenses(), fetchGoals(), fetchBudgetData() ])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  return (
    <KeyboardAvoidingView 
      style={{ flex: 1 }} 
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      {/* Root container to hold both the ScrollView (70%) and the FlatList (30%) */}
      <View style={{ flex: 1 }}>
        
        {/* ScrollView container with flex 0.7 */}
        <View style={{ flex: 1 }}>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }} refreshControl={
                  <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }>
            <View style={styles.container}>

              <View style={styles.transactionTypeContainer}>
                {/* Expense Button */}
                <TouchableOpacity
                  onPress={() => setIsExpense(true)}
                  style={[
                    styles.transactionTypeButton,
                    isExpense ? styles.activeTransactionType : null,
                  ]}
                >
                  <Text style={isExpense ? styles.activeTransactionText : styles.inactiveTransactionText}>
                    {' '} - Expense
                  </Text>
                </TouchableOpacity>

                {/* Income Button */}
                <TouchableOpacity
                  onPress={() => setIsExpense(false)}
                  style={[
                    styles.transactionTypeButton,
                    !isExpense ? styles.activeTransactionType : null,
                  ]}
                >
                  <Text style={!isExpense ? styles.activeTransactionText : styles.inactiveTransactionText}>
                    {' '} + Income
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={styles.amountContainer}>
                <View style={styles.iconWrapper}>
                  <MaterialCommunityIcons 
                    name={isExpense ? "gift" : "cash"} 
                    size={40} 
                    color="#000" 
                    style={styles.icon} 
                  />
                </View>
                <View style={styles.amountDetailsContainer}>
                  <TextInput 
                    style={styles.amountText} 
                    value={String(amount)} 
                    onChangeText={handleAmountChange} 
                    keyboardType='numeric' 
                  />
                
                  <View style={styles.inputContainer}>
                    <Text style={styles.label}>Transaction Category</Text>
                    {isExpense && (
                      <>
                      <View style={{maxWidth:"100%", maxHeight: 200, overflow: 'hidden'}}>
                    <Picker
                      selectedValue={category}
                      onValueChange={(itemValue) => setCategory(itemValue)}
                      style={styles.pickerStyle}
                    >
                      <Picker.Item label="Select a category" value={null} />
                      {budgetData.length > 0 &&
                      budgetData[0].categories.map((cat) => (
                        <Picker.Item key={cat._id} label={cat.name} value={cat.name} />
                      ))}
                    </Picker>
                    </View>
                    </>
                    )}
                    {!isExpense && (
                    <>
                    <View style={{maxWidth:"100%", maxHeight: 200, overflow: 'hidden'}}>
                      <TextInput style={styles.input} 
                      value={String(category)}
                      placeholder='Enter Income Category'
                      placeholderTextColor={'#777'} 
                      onChangeText={(itemValue) => setCategory(itemValue)}
                      keyboardType='default'
                      />
                    </View>
                    </>
                    )}
                  </View>
                </View>
              </View>

              {!isExpense && (
                <View>
                  <Text style={styles.label}>Remaining Amount: ₹{amount-allocatedAmount}</Text>
                  <Text style={styles.label}>Allocate to Goals:</Text>
                  {/* Picker for selecting goals */}
                  <Picker
                    selectedValue={selectedGoal}
                    onValueChange={(itemValue) => setSelectedGoal(itemValue)}
                    style={styles.pickerStyle}
                  >
                    <Picker.Item label="Select a goal" value={null} />
                    {goals.map((goal) => (
                      <Picker.Item key={goal._id} label={goal.name} value={goal._id} />
                    ))}
                  </Picker>
                  <Slider
                    style={styles.sliderStyle}
                    minimumValue={0}
                    maximumValue={amount}
                    step={50}
                    value={allocatedAmount}
                    onSlidingComplete={(value) => handleSliderChange(value)}
                  />
                  <TextInput
                    style={styles.input}
                    value={String(allocatedAmount)}
                    onChangeText={handleAllocatedAmountInputChange}
                    keyboardType="numeric"
                  />
                  <Text style={styles.label}>Allocated Amount: ₹{allocatedAmount}</Text>
                </View>
              )}
                
              <View style={styles.inputContainer}>
              {isExpense && (
                <View>
                <Text style={styles.label}>Transaction From Category</Text>
                <Picker
                  selectedValue={transactionName}
                  onValueChange={(itemValue) => setTransactionName(itemValue)}
                  style={styles.pickerStyle}
                >
                  <Picker.Item label="Select a Sub Category" value={null} />
                  {budgetData.length > 0 &&
                    budgetData[0].categories
                    .filter((cat) => cat.name === category) // Only get the selected category
                    .flatMap((filteredCategory) => 
                      filteredCategory.subcategories.map((sub) => (
                        <Picker.Item key={sub._id} label={`${sub.name}`} value={sub.name} />
                      ))
                    )}
                </Picker>
                </View>
                )}
                {!isExpense && (
                  <TextInput style={styles.input}
                  value={String(transactionName)}
                  placeholder='Enter Income Source'
                  placeholderTextColor={'#777'}
                  onChangeText={(itemValue) => setTransactionName(itemValue)}
                  keyboardType='default'
                  />
                )}
              </View>

              <View style={styles.inputContainer}>
                <Text style={styles.label}>Date</Text>
                <TouchableOpacity 
                  style={styles.dateInput} 
                  onPress={() => setShowDatePicker(true)}
                >
                  <Text style={styles.dateText}>
                    {transactionDate.toLocaleDateString()} (Today)
                  </Text>
                  <Ionicons name="calendar" size={20} color="#777" />
                </TouchableOpacity>
                {showDatePicker && (
                  <DateTimePicker
                    value={transactionDate}
                    mode="date"
                    display="default"
                    onChange={handleDateChange}
                  />
                )}
              </View>

              <View style={styles.inputContainer}>
                <Text style={styles.label}>Description</Text>
                <TextInput
                  style={styles.input}
                  placeholder="Description (Optional)"
                  placeholderTextColor="#777"
                  value={transactionDescription}
                  onChangeText={setTransactionDescription}
                />
              </View>

              <TouchableOpacity 
                style={styles.attachmentContainer} 
                onPress={handleAddAttachment}
              >
                <Ionicons name="attach" size={20} color="#777" />
                {attachment ? (
                  <Text style={styles.attachmentText}>{attachment.name}</Text>
                ) : (
                  <Text style={styles.attachmentText}>Add attachment</Text>
                )}
              </TouchableOpacity>

              {isExpense && (
              <TouchableOpacity style={styles.addTransactionButton} onPress={handleAddExpense}>
                <Text style={styles.addTransactionButtonText}>Add Expense</Text>
                
              </TouchableOpacity>
              )}
              
              {/* List of added expenses */}
              <FlatList
                horizontal
                data={expenses}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item,index }) => (
                  <View style={styles.expenseItem}>
                    <View style={styles.expenseDetails}>  
                      <Text style={styles.expenseText}>Parent: {item.parent_category}</Text>
                      <Text style={styles.expenseText}>Sub: {item.category}</Text>
                      <Text style={styles.expenseText}>Amount: ₹{item.amount}</Text>
                    </View>
                    <View style={styles.actionButtons}>
                      <TouchableOpacity onPress={() => handleEditExpense(index)} style={styles.editButton}>
                        <Ionicons name="pencil-outline" size={20} color="#fff" />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() =>handleDeleteExpense(index)} style={styles.deleteButton}>
                        <Ionicons name="trash-outline" size={20} color="#fff" />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              />

              <TouchableOpacity 
                style={styles.addTransactionButton} 
                onPress={handleAddTransaction}
              >
                <Text style={styles.addTransactionButtonText}>Add transaction</Text>
              </TouchableOpacity>

            </View>
          </ScrollView>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = ScaledSheet.create({
  transactionTypeTextInactive: {
    color: '#777',
    fontSize: 18,
  },
  inactiveTransactionType: {
    backgroundColor: '#333',
    borderRadius: 8,
  },
  inactiveTransactionText: {
    color: '#777',
    fontSize: 18,
  },
  container: {
    flex: 1,
    backgroundColor: '#1A1A1A',
   
    paddingLeft:"20%",
    paddingRight:"20%",
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerText: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 20,
  },
  transactionTypeContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  transactionTypeButton: {
    flex: 1,
    alignItems: 'stretch',
    paddingVertical: 10,
    margin: 5,
  },
  activeTransactionType: {
    backgroundColor: '#444',
    borderRadius: 8,
  },
  transactionTypeText: {
    color: '#777',
    fontSize: 18,
  },
  activeTransactionText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  amountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    backgroundColor: '#333',
    padding: 15,
    borderRadius: 8,
  },
  iconWrapper: {
    width: 60,
    height: 60,
    backgroundColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  amountDetailsContainer: {
    flexDirection: 'column',
  },
  amountText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
  },
  categoryText: {
    color: '#fff',
    fontSize: '16@ms',
    padding: 5,
  },
  inputContainer: {
    marginBottom: 20,
  },
  label: {
    color: '#fff',
    marginBottom: 8,
  },
  /* label: {
    fontSize: 18,
    marginVertical: 10,
  }, */
  button: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    alignItems:'center',
    marginTop: 20,
  },
  buttonText: {
    color: '#000',
    fontWeight:'bold',
    fontSize: 16,
    
  },
  input: {
    backgroundColor: '#2A2A2A',
    color: '#fff',
    borderRadius: 8,
    padding: 12,
  },
  expenseItem: { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    marginRight: 10,
    margin:15,
  },
  expenseDetails: {
    flexDirection: 'col',
    alignItems: 'left',
  },
  expenseText: { color: '#fff' },
  actionButtons: {
    flexDirection: 'row',
  },
  editButton: {
    
    padding: 8,
    borderRadius: 5,
    marginLeft: 5,
  },
  
  deleteButton: {
    
    padding: 8,
    borderRadius: 5,
    marginLeft: 5,
  },
  dateInput: {
    backgroundColor: '#2A2A2A',
    color: '#fff',
    borderRadius: 8,
    padding: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateText: {
    color: '#fff',
  },
  attachmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  attachmentText: {
    color: '#777',
    marginLeft: 10,
  },
  addTransactionButton: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
  },
  addTransactionButtonText: {
    color: '#000',
    fontWeight: 'bold',
  },
  pickerStyle: {
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: 8,
    marginBottom: 20,
  },
  sliderStyle: {
    marginBottom: 20,
  },
});

export default AddRecordScreen;
