import React, { useState, useContext } from 'react';
import { View, StyleSheet, Alert, KeyboardAvoidingView, Platform, Pressable, useWindowDimensions, ScrollView } from 'react-native';
import { TextInput, Button, Text, Checkbox } from 'react-native-paper';
import axios from 'axios';
import { baseURL } from '../apiConfig';
import { AuthContext } from '../AuthContext_web';

const AccountScreen = () => {
  const { signIn } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const [signInData, setSignInData] = useState({ username: '', password: '' });
  const [signUpData, setSignUpData] = useState({ firstName: '', lastName: '', username: '', email: '', password: '' });
  const [checked, setChecked] = useState(false);

  const handleInputChange = (field, value, isLogin) => {
    if (isLogin) {
      setSignInData({ ...signInData, [field]: value });
    } else {
      setSignUpData({ ...signUpData, [field]: value });
    }
  };

  const handleSignin = async () => {
    const { username, password } = signInData;
    if (!username || !password) {
      Alert.alert('Validation Error', 'Please enter both username and password.');
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/auth/signin`, { username, password });
      if (response.data.success) {
        const { token, role, userId, username } = response.data;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role);
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('isLoggedIn', 'true');
        await signIn(token, role);
        Alert.alert('Success', 'You are now signed in.');
      } else {
        Alert.alert('Error', response.data.message || 'Invalid credentials.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred during sign-in.');
    }
  };

  const handleSignup = async () => {
    const { firstName, lastName, username, email, password } = signUpData;
    if (!firstName || !lastName || !username || !email || !password || !checked) {
      Alert.alert('Validation Error', 'Please fill out all fields and agree to the terms.');
      return;
    }
    try {
      const name = `${firstName} ${lastName}`;
      const response = await axios.post(`${baseURL}/auth/signup`, { name, username, email, password });
      if (response.data.success) {
        Alert.alert('Success', 'Your account has been created.');
      } else {
        Alert.alert('Error', response.data.message || 'Signup failed.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred during sign-up.');
    }
  };

  return (
    <KeyboardAvoidingView style={styles.flex} behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
      <ScrollView contentContainerStyle={[styles.container, { flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={[styles.section, isMobile ? styles.fullWidth : styles.half]}>
          <Text style={styles.title}>Sign in to your Account</Text>
          <Button icon="google" mode="outlined" style={styles.googleButton} textColor="#000">Continue with Google</Button>
          <Text style={styles.orText}>Or login with</Text>
          <TextInput label="Username" mode="outlined" value={signInData.username} onChangeText={(text) => handleInputChange('username', text, true)} style={styles.input} left={<TextInput.Icon name="account" />} />
          <TextInput label="Password" mode="outlined" value={signInData.password} onChangeText={(text) => handleInputChange('password', text, true)} secureTextEntry style={styles.input} left={<TextInput.Icon name="lock" />} />
          <Button mode="contained" onPress={handleSignin} style={styles.button} textColor="#fff">Login</Button>
          <Text style={styles.signupText}>
            Don't have an account? <Pressable onPress={() => {}}><Text style={styles.linkText}>Sign Up</Text></Pressable>
          </Text>
        </View>

        <View style={[styles.section, isMobile ? styles.fullWidth : styles.half]}>
          <Text style={styles.title}>Create your account</Text>
          <TextInput label="First Name" mode="outlined" value={signUpData.firstName} onChangeText={(text) => handleInputChange('firstName', text, false)} style={styles.input} />
          <TextInput label="Last Name" mode="outlined" value={signUpData.lastName} onChangeText={(text) => handleInputChange('lastName', text, false)} style={styles.input} />
          <TextInput label="Username" mode="outlined" value={signUpData.username} onChangeText={(text) => handleInputChange('username', text, false)} style={styles.input} keyboardType="default" />
          <TextInput label="Email" mode="outlined" value={signUpData.email} onChangeText={(text) => handleInputChange('email', text, false)} style={styles.input} keyboardType="email-address" />
          <TextInput label="Password" mode="outlined" value={signUpData.password} onChangeText={(text) => handleInputChange('password', text, false)} secureTextEntry style={styles.input} />
          <Checkbox.Item label="I agree to the User Agreement and Privacy Policy." status={checked ? 'checked' : 'unchecked'} onPress={() => setChecked(!checked)} labelStyle={{ color: '#000' }} />
          <Button mode="contained" onPress={handleSignup} style={styles.button} textColor="#fff">Register</Button>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default AccountScreen;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    backgroundColor: '#f9f9f9',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    flexGrow: 1,
  },
  section: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    marginVertical: 10,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  half: {
    width: '45%',
    marginHorizontal: 10,
  },
  fullWidth: {
    width: '100%',
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000',
  },
  input: {
    marginBottom: 16,
  },
  button: {
    marginTop: 10,
    backgroundColor: '#000',
  },
  googleButton: {
    marginBottom: 10,
    borderColor: '#ccc',
  },
  orText: {
    textAlign: 'center',
    marginVertical: 10,
    color: '#000',
  },
  signupText: {
    textAlign: 'center',
    marginTop: 15,
    color: '#000',
  },
  linkText: {
    color: '#000',
    fontWeight: 'bold',
  },
});
