import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet, Image, Animated } from 'react-native';
import axios from 'axios';
import { baseURL } from '../apiConfig';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [categories] = useState(['All', 'Technology', 'Lifestyle', 'Finance']);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const navigation = useNavigation();
    
    const opacity = new Animated.Value(1); // Initialize opacity for fade-in effect

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${baseURL}/blogs`);
                setBlogs(response.data.blogs);
                // Trigger the fade-in animation once data is loaded
                Animated.timing(opacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true,
                }).start();
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
        fetchBlogs();
    }, []);

    const filteredBlogs = selectedCategory === 'All'
        ? blogs
        : blogs.filter(blog => blog.category === selectedCategory);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const renderBlogCard = ({ item }) => (
        <TouchableOpacity
            style={[styles.card]}
            onPress={() => navigation.navigate('BlogDescription', { blogId: item._id })}
            activeOpacity={0.85}
        >
            {item.featuredImage && (
                <Image source={{ uri: item.featuredImage }} style={styles.image} />
            )}
            <View style={styles.cardContent}>
                <Text style={styles.title}>{item.title}</Text>
                <Text style={styles.excerpt}>{item.excerpt || item.content.substring(0, 100) + '...'}</Text>

                {/* Display Tags */}
                {item.tags && item.tags.length > 0 && (
                    <View style={styles.tagsContainer}>
                        {item.tags.map((tag, index) => (
                            <Text key={index} style={styles.tag}>
                                #{tag}
                            </Text>
                        ))}
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );

    return (
        <Animated.View style={[styles.container, { opacity }]}>
            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.filterContainer}>
                {categories.map((category) => (
                    <TouchableOpacity
                        key={category}
                        onPress={() => handleCategorySelect(category)}
                        style={[
                            styles.filterButton,
                            selectedCategory === category && styles.selectedFilter
                        ]}
                    >
                        <Text style={styles.filterText}>{category}</Text>
                    </TouchableOpacity>
                ))}
            </ScrollView>

            <FlatList
                data={filteredBlogs}
                renderItem={renderBlogCard}
                keyExtractor={(item) => item._id}
                contentContainerStyle={styles.list}
            />
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#ffffff',
        padding: 10,
    },
    filterContainer: {
        flexDirection: 'column', 
    },
    filterButton: {
        backgroundColor: '#e0e0e0',
        borderRadius: 20,
       
    },
    selectedFilter: {
        backgroundColor: '#f8f8f8',
    },
    filterText: {
        color: '#000',
        fontWeight: 'bold',
        padding:10,
    },
    list: {
        paddingBottom: 20,
    },
    card: {
        backgroundColor: '#f0f0f0',
        borderRadius: 8,
        marginVertical: 10,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 4,
    },
    image: {
        width: '100%',
        height: 200,
        resizeMode: 'cover',
    },
    cardContent: {
        padding: 15,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5,
    },
    excerpt: {
        fontSize: 14,
        color: '#666',
    },
    tagsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
    },
    tag: {
        backgroundColor: '#eee',
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginRight: 5,
        marginBottom: 5,
        borderRadius: 15,
        fontSize: 12,
        color: '#333',
    },
});

export default Blog;
