import React, { useRef, useState, useEffect } from 'react';
import { View, Text, Image, FlatList, StyleSheet, useWindowDimensions } from 'react-native';

const testimonials = [
  {
    id: '1',
    text: '“Lorem2 ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”',
    name: 'JOHN CARTER',
    role: 'ROLE, COMPANY',
    avatar: require('../assets/avatar.png'),
    dark: false, // White background for side cards
  },
  {
    id: '2',
    text: '“Lorem3 ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”',
    name: 'JOHN CARTER',
    role: 'ROLE, COMPANY',
    avatar: require('../assets/avatar.png'),
    dark: true, // Black background for center card
  },
  {
    id: '3',
    text: '“Lorem4 ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”',
    name: 'JOHN CARTER',
    role: 'ROLE, COMPANY',
    avatar: require('../assets/avatar.png'),
    dark: false, // White background for side cards
  },
];

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const flatListRef = useRef(null);

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  useEffect(() => {
    const interval = setInterval(() => {
      let nextIndex = (activeIndex + 1) % testimonials.length; // Loop back after the last item
      setActiveIndex(nextIndex);
      flatListRef.current?.scrollToIndex({ index: nextIndex, animated: true });
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [activeIndex]);

  return (
    <View style={styles.container}>
      <Text style={styles.header}>What our users say?</Text>

      <FlatList
        ref={flatListRef}
        data={testimonials}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        getItemLayout={(data, index) => ({
            length: width * 0.8,
            offset: (width * 0.8) * index,
            index,
        })}
        onMomentumScrollEnd={(event) => {
            const newIndex = Math.round(event.nativeEvent.contentOffset.x / (width * 0.8));
            setActiveIndex(newIndex);
        }}
        renderItem={({ item }) => (
          <View
            style={[
              styles.card,
              { width: isMobile ? width * 0.85 : width * 0.5 },
              item.dark ? styles.darkCard : styles.lightCard,
            ]}
          >
            <Text style={[styles.testimonialText, item.dark && styles.testimonialTextDark]}>
              {item.text}
            </Text>
            <View style={styles.userInfo}>
              <Image source={item.avatar} style={styles.avatar} />
              <View>
                <Text style={[styles.userName, item.dark && styles.userNameDark]}>
                  {item.name}
                </Text>
                <Text style={[styles.userRole, item.dark && styles.userRoleDark]}>
                  {item.role}
                </Text>
              </View>
            </View>
          </View>
        )}
      />

      <View style={styles.pagination}>
        {testimonials.map((_, index) => (
          <View key={index} style={[styles.dot, activeIndex === index && styles.activeDot]} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 40,
    paddingHorizontal: 10,
  },
  header: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 20,
    textAlign: 'center',
  },
  card: {
    borderRadius: 20,
    padding: 20,
    marginHorizontal: 10,
    justifyContent: 'space-between',
  },
  darkCard: {
    backgroundColor: '#111',
  },
  lightCard: {
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    elevation: 5,
  },
  testimonialText: {
    fontSize: 16,
    color: '#333',
    marginBottom: 20,
  },
  testimonialTextDark: {
    color: '#ddd',
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000',
  },
  userNameDark: {
    color: '#fff',
  },
  userRole: {
    fontSize: 12,
    color: '#777',
  },
  userRoleDark: {
    color: '#aaa',
  },
  pagination: {
    flexDirection: 'row',
    marginTop: 15,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#ccc',
    marginHorizontal: 5,
  },
  activeDot: {
    backgroundColor: '#FDCB2E',
  },
});

export default TestimonialCarousel;
