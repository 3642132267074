import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Keyboard,
  KeyboardAvoidingView,
} from 'react-native';
import axios from 'axios'; // Import axios
import {baseURL} from '../apiConfig'; // Import the API configuration file
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  setUserName,
  setUserId,
  setPrimaryGoal,
  setIncome,
  addRecurringExpense,
  editRecurringExpense, 
  deleteRecurringExpense,
  setTimeCommitments,
  setCalendar,
} from '../onboardingSlice';

import * as SecureStore from 'expo-secure-store';
import { TabView, SceneMap } from 'react-native-tab-view';
import * as Progress from 'react-native-progress';
import { MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';

// Import SVGs
import BudgetInitial from '../assets/initial_question.png';
import PrimaryGoal from '../assets/primary_goal.png';
import Income from '../assets/income.png';
import RecordingExpense from '../assets/recording_expense.png';
import TimeRecord from '../assets/time_record.png';
import { ja } from 'react-native-paper-dates';

const StepScreen = ({
  ImageComponent,
  metaTitle,
  title,
  description,
  buttonText,
  onContinue,
  onBack,
  progress,
  children,
}) => (
  <View style={styles.container}>
    <View style={styles.headerContainer}>
      <TouchableOpacity style={styles.arrowButton} onPress={onBack}>
        <MaterialIcons name="arrow-back" size={24} color="#000" />
      </TouchableOpacity>
      <View style={styles.progressBarContainer}>
        {Array.from({ length: 6 }).map((_, i) => (
          <Progress.Bar
            key={i}
            progress={i < progress ? 1 : 0}
            width={Dimensions.get('window').width / 7}
            height={6}
            color="#EFBF04"
            unfilledColor="#E0E0E0"
            borderWidth={0}
          />
        ))}
      </View>
    </View>

    <View style={styles.splitBackground}>
      <View style={styles.topHalf}>
        <Image source={ImageComponent} style={{width:200,height:200, resizeMOde: 'contain'}}/>
        
      </View>
      <View style={styles.bottomHalf}>
        <Text style={styles.metaTitle}>{metaTitle}</Text>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>

        {children}

        <TouchableOpacity style={styles.button} onPress={onContinue}>
          <Text style={styles.buttonText}>{buttonText}</Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
);

const BudgetOnBoarding = () => {
  const navigation = useNavigation();
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: 'First' },
    { key: 'second', title: 'Second' },
    { key: 'third', title: 'Third' },
    { key: 'fourth', title: 'Fourth' },
    { key: 'fifth', title: 'Fifth' },
    { key: 'sixth', title: 'Sixth' },
  ]);

  const dispatch = useDispatch();
  const onboardingData = useSelector((state) => state.onboarding);

  useEffect(() => {
    const checkAuth = () => {
      try {
        const uid =  sessionStorage.getItem('userId');
        const uname = sessionStorage.getItem('username');
        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          //navigation.navigate("Login");
          navigation.navigate('Welcome');
        } else {
          console.log('User is logged in:', uname);
          dispatch(setUserName(uname));
          dispatch(setUserId(uid));
        }
      } catch (error) {
        console.error('Error reading AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.'+ error);
        console.log('Error reading Storage:', error);
        navigation.navigate('Account');
      }
    };

    checkAuth();
  }, [navigation, dispatch]);

  const handleFinish = async () => {
    console.log('Onboarding Data:', onboardingData);

    alert('Finished', 'Your data has been logged to the console.');
    try {
      const response = await axios.post(`${baseURL}/budget/saveBudget`,onboardingData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.data.success) {
        sessionStorage.setItem('onboardingData', JSON.stringify(onboardingData)); // Ensure data is fully saved
        console.log('Onboarding data saved successfully!');
        
        Alert.alert('Success', 'Onboarding completed!', [
          { text: 'OK', 
            onPress: () => {
              onClose(); // ✅ Close the modal
              navigation.navigate('BudgetHomeScreen'); // ✅ Navigate to Welcome page
            },
          },
        ]);
      } else {
        Alert.alert('Error', response.data.message || 'Failed to save onboarding data.');
      }
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      Alert.alert('Error', 'Something went wrong while saving onboarding data.');
    }
    
  };

  const renderScene = SceneMap({
    first: () => (
      <FirstStep onContinue={() => setIndex(1)} onBack={() => navigation.goBack()} progress={1} />
    ),
    second: () => (
      <SecondStep onContinue={() => setIndex(2)} onBack={() => setIndex(0)} progress={2} />
    ),
    third: () => <ThirdStep onContinue={() => setIndex(3)} onBack={() => setIndex(1)} progress={3} />,
    fourth: () => (
      <FourthStep
        onContinue={() => setIndex(4)}
        onBack={() => setIndex(2)}
        progress={4}
      />
    ),
    fifth: () => (
      <FifthStep onContinue={() => setIndex(5)} onBack={() => setIndex(3)} progress={5} onboardingData={onboardingData} />
    ),
    sixth: () => (
      <SixthStep onContinue={handleFinish} onBack={() => setIndex(4)} progress={6} />
    ),
  });

  return (
    <SafeAreaView style={styles.safeArea}>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: Dimensions.get('window').width }}
        swipeEnabled={false}
        renderTabBar={() => null}
      />
    </SafeAreaView>
  );
};

const FirstStep = ({ onContinue, onBack, progress }) => {
  const userName = useSelector((state) => state.onboarding.userName);
  const capitalizedUserName = userName
    ? userName.charAt(0).toUpperCase() + userName.slice(1)
    : 'User';

  return (
    <StepScreen
      ImageComponent={BudgetInitial}
      title={`Hi ${capitalizedUserName}, let's embark on a journey to save your time and money`}
      description=""
      buttonText="Let's Go"
      onContinue={onContinue}
      onBack={onBack}
      progress={progress}
    />
  );
};

const SecondStep = ({ onContinue, onBack, progress }) => {
  const dispatch = useDispatch();
  const selectedGoal = useSelector((state) => state.onboarding.primaryGoal);
  const [localGoal, setLocalGoal] = useState(selectedGoal);

  const options = [
    { label: 'Time Mastery', value: 'timemastery' },
    { label: 'Financial Freedom', value: 'financialfreedom' },
  ];

  return (
    <StepScreen
      
      ImageComponent={PrimaryGoal}
      title="Choose your path..."
      description=""
      buttonText="Continue"
      onContinue={() => {
        if (localGoal) {
          dispatch(setPrimaryGoal(localGoal));
          onContinue();
        } else {
          Alert.alert('Error', 'Please select a goal to proceed.');
        }
      }}
      onBack={onBack}
      progress={progress}
    >
      <View style={styles.optionsContainer}>
        {options.map((option) => (
          <TouchableOpacity
            key={option.value}
            style={[
              styles.optionButton,
              localGoal === option.value && styles.selectedOptionButton,
            ]}
            onPress={() => setLocalGoal(option.value)}
          >
            <Text
              style={[
                styles.optionText,
                localGoal === option.value && styles.selectedOptionText,
              ]}
            >
              {option.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </StepScreen>
  );
};

const ThirdStep = ({ onContinue, onBack, progress }) => {
  const dispatch = useDispatch();
  const syncChoice = useSelector((state) => state.onboarding.calendar); // Redux state
  const [localSyncChoice, setLocalSyncChoice] = useState(syncChoice);

  const options = [
    { label: 'Sync your accounts', value: 'sync' },
    { label: 'No thanks', value: 'skip' },
  ];

  const handleContinue = () => {
    if (localSyncChoice) {
      dispatch(setCalendar(localSyncChoice)); // Save the user's choice in Redux
      onContinue();
    } else {
      Alert.alert('Error', 'Please select an option to proceed.');
    }
  };

  return (
    <StepScreen
     
      ImageComponent={Income}
      title="Would you like to ..."
      buttonText="Continue"
      onContinue={() => {
        if (localSyncChoice) {
          dispatch(setCalendar(localSyncChoice));
          onContinue();
        } else {
          Alert.alert('Error', 'Please select a choice to proceed.');
        }
      }}
      onBack={onBack}
      progress={progress}
    >
      <View style={styles.optionsContainer}>
        {options.map((option) => (
          <TouchableOpacity
            key={option.value}
            style={[
              styles.optionButton,
              localSyncChoice === option.value && styles.selectedOptionButton,
            ]}
            onPress={() => setLocalSyncChoice(option.value)}
          >
            <Text
              style={[
                styles.optionText,
                localSyncChoice === option.value && styles.selectedOptionText,
              ]}
            >
              {option.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </StepScreen>
  );
};

const FourthStep = ({ onContinue, onBack, progress }) => {
  const dispatch = useDispatch();
  const income = useSelector((state) => state.onboarding.income);
  const [localIncome, setLocalIncome] = useState(income);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currency, setCurrency] = useState('₹');
  const currencies = ['₹', '$']; // Supported currencies
  return (
    <StepScreen
     
      ImageComponent={Income}
      metaTitle="Level up your income"
      title="What's your current monthly income?"
      description=""
      buttonText="Continue"
      onContinue={() => {
        if (localIncome) {
          dispatch(setIncome(localIncome));
          onContinue();
        } else {
          Alert.alert('Error', 'Please enter your income.');
        }
      }}
      onBack={onBack}
      progress={progress}
    >
      <View style={styles.dropdownContainer}>
        {/* Currency Input Section */}
        <View style={styles.inputContainer}>
          {/* Currency Dropdown */}
          <TouchableOpacity
            style={styles.currencyDropdown}
            onPress={() => setDropdownVisible(!dropdownVisible)}
          >
            <Text style={styles.currencyText}>{currency}</Text>
            <MaterialIcons name="arrow-drop-down" size={24} color="#fff" />
          </TouchableOpacity>

          {/* Number Input Field */}
          <TextInput
            style={styles.textInput}
            placeholder="0"
            placeholderTextColor="#999"
            keyboardType="numeric"
            value={localIncome}
            onChangeText={(text) => setLocalIncome(text)}
          />
        </View>

        {/* Dropdown Menu */}
        {dropdownVisible && (
          <View style={styles.dropdownMenu}>
            {currencies.map((cur) => (
              <TouchableOpacity
                key={cur}
                style={styles.dropdownItem}
                onPress={() => {
                  setCurrency(cur);
                  setDropdownVisible(false);
                }}
              >
                <Text style={styles.dropdownText}>{cur}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>
    </StepScreen>
  );
};

const FifthStep = ({ onContinue, onBack, progress, onboardingData }) => {
  const dispatch = useDispatch();
  const recurringExpenses = useSelector((state) => state.onboarding.recurringExpenses); // Fetch from Redux

  const [expenseName, setExpenseName] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(new Date());
  const [editingExpense, setEditingExpense] = useState(null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false); // Toggle date picker visibility

  const handleSaveExpense = () => {
    if (!expenseName.trim() || !amount.trim()) {
      Alert.alert('Error', 'Please enter both an expense name and amount.');
      return;
    }
  
    const parsedAmount = parseFloat(amount); // Convert to a number
    if (isNaN(parsedAmount)) {
      Alert.alert('Error', 'Please enter a valid number for the amount.');
      return;
    }

    // Check if the total expenses exceed the income
    
      // Calculate the total expenses including the new or edited one
      const totalExpenses = recurringExpenses.reduce((total, expense) => {
        if (editingExpense && expense.id === editingExpense.id) {
          return total; // Exclude the current expense being edited
        }
        return total + expense.amount;
      }, 0) + parsedAmount;

      if (totalExpenses > parseFloat(onboardingData.income || 0)) {
        Alert.alert(
          'Error',
          `Total expenses cannot exceed your income. Remaining income: ₹${(
            parseFloat(onboardingData.income || 0) - (totalExpenses - parsedAmount)
          ).toFixed(2)}`
        );
        return;
      }
    
  
    if (editingExpense) {
      dispatch(
        editRecurringExpense({
          id: editingExpense.id,
          name: expenseName,
          amount: parsedAmount,
          date: date.toISOString(),
        })
      );
    } else {
      dispatch(
        addRecurringExpense({
          id: Date.now().toString(),
          name: expenseName,
          amount: parsedAmount,
          date: date.toISOString(),
        })
      );
    }
  
    // Clear inputs after saving
    setExpenseName('');
    setAmount('');
    setDate(new Date());
    setEditingExpense(null);
  };

  const handleEdit = (expense) => {
    // Calculate total expenses excluding the current expense being edited
    const totalExpensesWithoutCurrent = recurringExpenses.reduce((total, exp) => {
      if (exp.id !== expense.id) {
        return total + exp.amount;
      }
      return total;
    }, 0);

    // Check if editing this expense would exceed the income
    const remainingIncome = parseFloat(onboardingData.income || 0) - totalExpensesWithoutCurrent;
    if (expense.amount > remainingIncome) {
      Alert.alert(
        'Error',
        `Editing this expense exceeds your income. Remaining income: ₹${remainingIncome.toFixed(2)}`
      );
      return;
    }
    setEditingExpense(expense);
    setExpenseName(expense.name);
    setAmount(expense.amount.toString());
    setDate(new Date(expense.date));
  };

  const handleDelete = (id) => {
    dispatch(deleteRecurringExpense(id)); // Dispatch delete action
  };

  const renderExpenseItem = ({ item }) => (
    <View style={styles.expenseItem}>
      <View style={styles.expenseDetails}>
        <Text style={styles.expenseName}>{item.name}</Text>
        <Text style={styles.expenseAmount}>
          ₹{typeof item.amount === 'number' ? item.amount.toFixed(2) : item.amount}
        </Text>
        <Text style={styles.expenseDate}>{new Date(item.date).toLocaleDateString()}</Text>
      </View>
      <View style={styles.expenseActions}>
        <TouchableOpacity onPress={() => handleEdit(item)}>
          <MaterialIcons name="edit" size={24} color="#4CAF50" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDelete(item.id)}>
          <MaterialIcons name="delete" size={24} color="#F44336" />
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <StepScreen
      
      ImageComponent={RecordingExpense}
      metaTitle="Level up your income"
      title="Add Your Recurring Expenses"
      buttonText="Continue"
      onContinue={onContinue}
      onBack={onBack}
      progress={progress}
    >
      <FlatList
        data={recurringExpenses}
        keyExtractor={(item) => item.id}
        renderItem={renderExpenseItem}
        
        ListHeaderComponent={
          <View style={styles.expenseContainer}>
            <TextInput
              style={styles.inputField}
              placeholder="Expense Name"
              placeholderTextColor="#999"
              value={expenseName}
              onChangeText={setExpenseName}
            />
            <TextInput
              style={styles.inputField}
              placeholder="Amount"
              placeholderTextColor="#999"
              keyboardType="numeric"
              value={amount}
              onChangeText={setAmount}
            />
            <TouchableOpacity
              style={styles.datePicker}
              onPress={() => setDatePickerVisible(true)}
            >
              <Text style={styles.dateText}>{date.toLocaleDateString()}</Text>
              <MaterialIcons name="calendar-today" size={24} color="#000" />
            </TouchableOpacity>
            {isDatePickerVisible && (
              <DateTimePicker
                value={date}
                mode="date"
                display="default"
                onChange={(event, selectedDate) => {
                  setDatePickerVisible(false);
                  if (selectedDate) setDate(selectedDate);
                }}
              />
            )}
            
            <TouchableOpacity
              style={styles.saveButton}
              onPress={handleSaveExpense}
            >
              <Text style={styles.saveButtonText}>
                {editingExpense ? 'Save Changes' : 'Add Expense'}
              </Text>
            </TouchableOpacity>
          </View>
        }
        ListFooterComponent={<View style={styles.divider} />}
      />
    </StepScreen>
  );
};

const SixthStep = ({ onContinue, onBack, progress }) => {
  const dispatch = useDispatch();
  const timeCommitments = useSelector((state) => state.onboarding.timeCommitments); // Redux state
  const [selectedCommitment, setSelectedCommitment] = useState(timeCommitments || null); // Local state for selected commitment
  const [options, setOptions] = useState([
    { label: 'Job', value: 'job' },
    { label: 'Family', value: 'family' },
    { label: 'Hobbies', value: 'hobbies' },
  ]); // Default options
  const [newOption, setNewOption] = useState(''); // Input for adding a new custom option
  
  const handleAddOption = () => {
    if (newOption.trim() === '') {
      Alert.alert('Error', 'Please enter a valid option.');
      return;
    }

    // Prevent duplicate options
    if (options.some((option) => option.value === newOption.trim().toLowerCase())) {
      Alert.alert('Error', 'This option already exists.');
      return;
    }

    const customOption = { label: newOption, value: newOption.trim().toLowerCase() };
    setOptions([...options, customOption]); // Add the new option
    setNewOption(''); // Clear the input
  };

  const handleContinue = () => {
    if (!selectedCommitment) {
      Alert.alert('Error', 'Please select or add a commitment to proceed.');
      return;
    }
    dispatch(setTimeCommitments(selectedCommitment)); // Save the selected commitment to Redux
    onContinue(); // Proceed to the next step
  };

  return (
    <StepScreen
      
      ImageComponent={TimeRecord}
      metaTitle="Conquer your time"
      title="What are your major time commitments?"
      buttonText="Finish"
      onContinue={handleContinue}
      onBack={onBack}
      progress={progress}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={100} // Adjust this value based on layout
      >
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          {/* Display Options */}
          <View style={styles.optionsContainer}>
            {options.map((option) => (
              <TouchableOpacity
                key={option.value}
                style={[
                  styles.optionButton,
                  selectedCommitment === option.value && styles.selectedOptionButton,
                ]}
                onPress={() => setSelectedCommitment(option.value)}
              >
                <Text
                  style={[
                    styles.optionText,
                    selectedCommitment === option.value && styles.selectedOptionText,
                  ]}
                >
                  {option.label}
                </Text>
              </TouchableOpacity>
            ))}
          </View>

          {/* Add New Option */}
          <View style={styles.addCommitmentContainer}>
            <TextInput
              style={styles.input}
              placeholder="Add a new commitment"
              placeholderTextColor="#999"
              value={newOption}
              onChangeText={setNewOption}
              onSubmitEditing={handleAddOption} // Allow 'Enter' to add the custom option
              blurOnSubmit={false}
            />
            <TouchableOpacity style={styles.addButton} onPress={handleAddOption}>
              <Text style={styles.addButtonText}>+ Add</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </StepScreen>
  );
};


const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'black',
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingTop: 10,
    backgroundColor: 'white',
  },
  arrowButton: {
    marginLeft: 10,
  },
  progressBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  splitBackground: {
    flex: 1,
  },
  topHalf: {
    flex: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  bottomHalf: {
    flex: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'black',
    padding: 10,
  },
  metaTitle:{
    fontSize:15,
    fontWeight:100,
    color:'#ccc',
    marginBottom:10,
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#fff',
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    flexDirection:'row',
    fontSize: 24,
    color: '#a9a9a9',
    textAlign: 'center',
    marginBottom: 20,
    flexWrap:'wrap',
  },
  button: {
    position:'relative',
    /* bottom:0, */
    backgroundColor: '#000',
    borderRadius: 8,
    borderWidth:1,
    boarderColor:'#ccc',
    overflow:'hidden',
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent:'flex-end', 
    alignItems: 'center',
    marginBottom: 10,
    width:'100%',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },
  optionsContainer: {
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'flex-start',
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',

  },
  optionButton: {
    width: '80%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor:'#000',
    borderRadius: 8,
    borderColor:'#555',
    borderWidth:1,
    marginVertical: 10,
    alignItems: 'center',
    color:'#ccc',
  },
  selectedOptionButton: {
    borderColor:'#ccc',
    borderWidth:2,
  },
  optionText: {
    fontSize: 16,
    color: '#555',
  },
  selectedOptionText: {
    color: '#ccc',
    fontWeight: 'bold',
  },
  nothanksTouch:{
    flexDirection:'row',
  },
  nothanksText:{
    color:'#555',
    padding:20,
  },
  selectedNothanksTouch:{
    
  },
  selectedNothanksText:{
    color:'#ccc',
    fontWeight:'bold',
  },
  listHeadercomponent:
  {
    marginBottom:10,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#555',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#000',
    width: '90%',
    height: 50,
    marginBottom:10,
  },
  currencyDropdown: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    backgroundColor: '#333',
    height: '100%',
    width: 70,
  },
  currencyText: {
    color: '#fff',
    fontSize: 16,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 10,
    color: '#fff',
    fontSize: 16,
  },
  dropdownMenu: {
    marginTop: 5,
    width: 70,
    backgroundColor: '#333',
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: '#555',
    borderWidth: 1,
  },
  dropdownItem: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#555',
  },
  dropdownText: {
    color: '#fff',
    fontSize: 16,
  },
  expenseContainer:{
    flex:1,
    width:"90%",
    marginBottom:20,
    maxHeight:200,
  },
  expenseInputContainer: {flexDirection: 'column',  marginBottom: 20, width:"90%", },
  expenseInput: {
    flex: 1,
    color: '#fff',
    padding: 10,
    borderRadius: 8,
    marginRight: 0,
    width:'70%',
  },
  iconButton: {
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    width:'30%',
  },
  currencyDropdown: {
    flexDirection: 'row',
    alignItems: 'center',
   /*  backgroundColor: '#333', */
    borderRadius: 8,
    padding: 10,
    /* borderWidth: 1,
    borderColor: '#555', */
    marginRight: 0,
  },
  currencyText: { color: '#fff', fontSize: 16 },
  amountInputContainer:{
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#555',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#000',
    width: '50%',
    height: 50,
    marginBottom:0,
  },
  amountInput: {
    /* backgroundColor: '#333', */
    color: '#fff',
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    marginRight: 10,
    textAlign: 'left',
    marginBottom:10,
  },
  amount:{
    padding:10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    marginRight: 10,
    textAlign: 'left',
    marginBottom:10,
    color:'#fff',
  },
  editingExpense:{
    flexDirection:'row',
    justifyContent:'center',
  },
  editingExpenseSave:{
    flexDirection:'row',
    justifyContent:'center',
  },
  dateInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    marginBottom:10,
  },
  dateText: { color: '#999', fontSize: 16 },
  addButton: { alignSelf: 'center', marginTop: 10 },
  addButtonText: { color: '#fff', fontSize: 16, textDecorationLine: 'underline', marginBottom:10, },
  divider: { height: 1, backgroundColor: '#555', marginVertical: 20 },
  expenseItem: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#555',
  },
  categoryIcon: { padding: 10, borderRadius: 50, backgroundColor: '#555', marginRight: 10 },
  expenseDetails: { flex: 1 },
  expenseName: { color: '#fff',},
  expenseAmount: { color: '#fff', fontSize: 16, fontWeight: 'bold' },
  expenseDate: { color: '#fff',},
  expenseInfo: { color: '#999', fontSize: 14 },
  expenseActions: { flexDirection: 'row', alignItems: 'center' },
  dropdownMenu: { position: 'absolute', top: 50, backgroundColor: '#333', borderRadius: 8, borderWidth: 1, borderColor: '#555' },
  dropdownItem: { padding: 10, alignItems: 'center' },
  dropdownText: { color: '#fff', fontSize: 16 },

  saveButton: { backgroundColor: 'transparent', padding: 10, borderRadius: 8, borderWidth: 1, borderColor: '#555', alignItems: 'center' },
  saveButtonText: { color: '#fff', fontSize: 16 },

  addOtherContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    width: '100%',
    justifyContent: 'center',
  },
  
  addOtherInput: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: '#555',
    borderRadius: 8,
    color: '#fff',
    backgroundColor: '#333',
    marginRight: 10,
  },
  
  addOtherButton: {
    backgroundColor: '#EFBF04',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  addOtherButtonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
  },
  scrollViewContent: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  commitmentsContainer: {
    marginBottom: 20,
  },
  commitmentItem: {
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
  },
  commitmentText: {
    color: '#fff',
    fontSize: 16,
  },
  addCommitmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  formContainer: {
    width: '100%',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  inputField: {
    width: '90%',
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    marginBottom: 10,
    color: '#fff',
    backgroundColor: '#333',
  },
  datePicker: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#555',
    backgroundColor: '#333',
    marginBottom: 10,
  },
  dateText: {
    color: '#fff',
    fontSize: 16,
  },
  saveButton: {
    width: '90%',
    padding: 12,
    backgroundColor: '#EFBF04',
    borderRadius: 8,
    alignItems: 'center',
  },
  saveButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  expenseList: {
    width: '100%',
    marginTop: 20,
    paddingHorizontal: 20,
  },
  expenseItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#333',
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#555',
  },
  expenseDetails: {
    flex: 1,
  },
  expenseName: {
    color: '#fff',
    fontSize: 16,
  },
  expenseAmount: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  expenseDate: {
    color: '#999',
    fontSize: 14,
  },
  expenseActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default BudgetOnBoarding;