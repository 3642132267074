// DashboardScreen.js

import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { baseURL } from '../apiConfig';
import { useNavigation } from '@react-navigation/native';

const DashboardScreen = () => {
  const navigation = useNavigation();
  const [stats, setStats] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  const fetchDashboardStats = async () => {
    try {
      const response = await api.get(`${baseURL}/admin/stats`);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchDashboardStats().then(() => setRefreshing(false));
  };

  if (!stats) {
    return (
      <View style={styles.loadingContainer}>
        
        <View style={styles.container}>
          <Text style={styles.title}>Admin Dashboard</Text>
          <Text style={styles.statLabel}>No Stats Found</Text>
        </View>
      </View>
    );
  }

  return (
    <ScrollView
      style={styles.container}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
    >
      <Text style={styles.title}>Admin Dashboard</Text>
      <View style={styles.statContainer}>
        <View style={styles.statCard}>
          <Icon name="article" size={40} color="#2196F3" />
          <Text style={styles.statNumber}>{stats.totalBlogs}</Text>
          <Text style={styles.statLabel}>Total Blogs</Text>
        </View>
        <View style={styles.statCard}>
          <Icon name="pages" size={40} color="#4CAF50" />
          <Text style={styles.statNumber}>{stats.totalPages}</Text>
          <Text style={styles.statLabel}>Total Pages</Text>
        </View>
        <View style={styles.statCard}>
          <Icon name="people" size={40} color="#E91E63" />
          <Text style={styles.statNumber}>{stats.totalUsers}</Text>
          <Text style={styles.statLabel}>Total Users</Text>
        </View>
      </View>
      <View style={styles.quickLinks}>
        <Text style={styles.sectionTitle}>Quick Links</Text>
        <View style={styles.linkContainer}>
          <TouchableOpacity
            style={styles.linkCard}
            onPress={() => navigation.navigate('Blogs')}
          >
            <Icon name="add" size={30} color="#fff" />
            <Text style={styles.linkText}>Add New Blog</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.linkCard}
            onPress={() => navigation.navigate('Pages')}
          >
            <Icon name="add" size={30} color="#fff" />
            <Text style={styles.linkText}>Add New Page</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.linkCard}
            onPress={() => navigation.navigate('Users')}
          >
            <Icon name="person-add" size={30} color="#fff" />
            <Text style={styles.linkText}>Add New User</Text>
          </TouchableOpacity>
        </View>
      </View>
      {/* Add more dashboard content as needed */}
    </ScrollView>
  );
};

export default DashboardScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    backgroundColor: '#f4f6f8',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#333',
  },
  statContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  statCard: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    alignItems: 'center',
    marginHorizontal: 5,
    elevation: 2,
  },
  statNumber: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  statLabel: {
    fontSize: 16,
    color: '#666',
  },
  quickLinks: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
  },
  linkContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  linkCard: {
    flex: 1,
    backgroundColor: '#2196F3',
    borderRadius: 8,
    padding: 15,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  linkText: {
    fontSize: 16,
    color: '#fff',
    marginTop: 5,
  },
});
