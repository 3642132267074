import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  TextInput,
  Alert,
  SafeAreaView,
  ScrollView,
  RefreshControl,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import { Ionicons } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import { baseURL } from '../apiConfig';

const SetGoalAmountScreen = ({ navigation }) => {
  const [userId, setUserId] = useState(null);
  const [budgetData, setBudgetData] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [allocatedAmount, setAllocatedAmount] = useState('');
  const [remainingBudget, setRemainingBudget] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  // Check for uid and uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        setUserId(uid);

        const uname = sessionStorage.getItem('username');
        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          navigation.navigate("Account");
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };

    checkAuth();
  }, [navigation]);

  useEffect(() => {
    if (userId) {
      fetchBudgetData();
      fetchGoals();
    }
  }, [userId]);

  const fetchBudgetData = async () => {
    try {
      const response = await axios.get(`${baseURL}/budget?userId=${userId}`);
      if (response.data.success) {
        const budgets = response.data.budgets;
        if (Array.isArray(budgets) && budgets.length > 0) {
          console.log('Budgets: ', budgets);
          setBudgetData(budgets);

          // Calculate remaining budget
          const latestBudget = budgets[0];
          const totalBudgetedAmount = latestBudget.categories.reduce(
            (total, category) => total + category.budgeted,
            0
          );
          const remaining = latestBudget.incomeAmount - (totalBudgetedAmount + latestBudget.savings + latestBudget.investments);
          setRemainingBudget(remaining);
        }
      } else {
        Alert.alert('Error', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
      Alert.alert('Error', 'An error occurred while fetching budget data.');
    }
  };

  const fetchGoals = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/goals`, { params: { userId } });

      if (response.data.success) {
        setGoals(response.data.goals);
        console.log('Goals: ', response.data.goals);

        // Set default goal if available
        if (response.data.goals.length > 0) {
            setSelectedGoal(response.data.goals[0]._id);
        }
      }
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  const handleSetAmount = async () => {
    if (!selectedGoal) {
      Alert.alert('Error', 'Please select a goal.');
      return;
    }

    const amountToAllocate = parseFloat(allocatedAmount);
    if (isNaN(amountToAllocate) || amountToAllocate <= 0) {
      Alert.alert('Error', 'Please enter a valid amount.');
      return;
    }

    if (amountToAllocate > remainingBudget) {
      Alert.alert(
        'Budget Exceeded',
        `The amount exceeds the remaining budget of ₹${remainingBudget.toFixed(2)}.`
      );
      return;
    }

    try {
      // Step 1: Allocate amount to goal
      const allocateResponse = await axios.post(`${baseURL}/goals/allocate`, {
        userId,
        allocatedAmount: amountToAllocate,
        selectedGoal,
      });

      if (!allocateResponse.data.success) {
        Alert.alert('Error', allocateResponse.data.error || 'Failed to allocate amount.');
        return;
      }

      // Step 2: Update budget.incomeAmount
      const latestBudget = budgetData[0]; // Assuming the latest budget is at index 0
      if (latestBudget) {
        const updatedIncomeAmount = latestBudget.incomeAmount - amountToAllocate;

        await axios.put(`${baseURL}/budget/${latestBudget._id}`, {
          incomeAmount: updatedIncomeAmount,
        });

        Alert.alert('Success', 'Amount allocated to goal successfully.');

        // Refresh data
        setAllocatedAmount('');
        fetchBudgetData();
        fetchGoals();
      } else {
        Alert.alert('Error', 'No budget data found to update.');
      }
    } catch (error) {
      console.error('Error allocating amount to goal:', error);
      Alert.alert('Error', 'An error occurred while allocating amount.');
    }
  };

  /* const renderGoalItem = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.goalItem,
        selectedGoal?._id === item._id && styles.selectedGoalItem,
      ]}
      onPress={() => setSelectedGoal(item)}
    >
      <Text style={styles.goalText}>{item.name}</Text>
      <Text style={styles.goalAmount}>
        Target: ₹{item.targetAmount} | Saved: ₹{item.savedAmount}
      </Text>
    </TouchableOpacity>
  ); */

  const onRefresh = () => {
    setRefreshing(true);
    
    Promise.all([fetchBudgetData(), fetchGoals() ])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  return (
    <SafeAreaView style={styles.container}>
        <ScrollView refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }>
        <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.navigate('GoalsListScreen')}>
          <Ionicons name="arrow-back" size={24} color="#fff" />
        </TouchableOpacity>
        <Text style={styles.title}>Set Goal Amount</Text>
      </View>
      <Text style={styles.subtitle}>
        Remaining Budget: ₹{remainingBudget.toFixed(2)}
      </Text>

      {/* <FlatList
        data={goals}
        renderItem={renderGoalItem}
        keyExtractor={(item) => item._id}
        contentContainerStyle={styles.goalList}
      /> */}

      {/* Dropdown Picker for Goals */}
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedGoal}
          onValueChange={(itemValue) => setSelectedGoal(itemValue)}
          style={styles.picker}
        >
          {goals.map((goal) => (
            <Picker.Item
              key={goal._id}
              label={`${goal.name} (Saved: ₹${goal.savedAmount} | Target: ₹${goal.targetAmount})`}
              value={goal._id}
            />
          ))}
        </Picker>
      </View>

      <TextInput
        style={styles.input}
        placeholder="Enter amount to allocate"
        placeholderTextColor="#AAA"
        keyboardType="numeric"
        value={allocatedAmount}
        onChangeText={setAllocatedAmount}
      />

      <TouchableOpacity style={styles.button} onPress={handleSetAmount}>
        <Text style={styles.buttonText}>Set Amount</Text>
      </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    padding: 100,
    paddingVertical: 50,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerText: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FFF',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#AAA',
    marginBottom: 20,
  },
  goalList: {
    marginBottom: 20,
  },
  goalItem: {
    backgroundColor: '#1F1F1F',
    padding: 15,
    borderRadius: 8,
    marginBottom: 10,
  },
  selectedGoalItem: {
    backgroundColor: '#333',
  },
  goalText: {
    fontSize: 16,
    color: '#FFF',
  },
  goalAmount: {
    fontSize: 14,
    color: '#AAA',
    marginTop: 5,
  },
  pickerContainer: {
    backgroundColor: '#1F1F1F',
    borderRadius: 8,
    marginBottom: 20,
  },
  picker: {
    color: '#FFF',
  },
  input: {
    backgroundColor: '#1F1F1F',
    color: '#FFF',
    borderRadius: 8,
    padding: 15,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#333',
  },
  button: {
    backgroundColor: '#FFC107',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
  },
  buttonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default SetGoalAmountScreen;
