// BlogScreen.js
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Alert } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import BlogEditor from './BlogEditor'; // Component for adding/editing blogs
import axios from 'axios';
import { baseURL } from '../apiConfig';

const BlogScreen = () => {
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${baseURL}/blogs`);
      setBlogs(response.data.blogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const handleDelete = async(blogId) => {
    window.alert("Blog Id to be deleted: "+blogId);
    const confirmation = window.confirm('Are you sure you want to delete this blog?');
    if (confirmation) {
      try {
        // Retrieve the token from sessionStorage
        const token = sessionStorage.getItem('token');
        if (!token) {
          window.alert('Authorization denied. No token found.');
          return;
        }
        // Make the DELETE request with the token in the Authorization header
        await axios.delete(`${baseURL}/blogs/${blogId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to the Authorization header
          },
        });
        fetchBlogs(); // Refresh the list of pages
        window.alert('Blog deleted successfully.');
      } catch (error) {
        console.error('Error deleting blog:', error);
        window.alert('Failed to delete blog.');
      }
    }
  };

  const renderBlogItem = ({ item }) => (
    <View style={styles.blogItem}>
      <View style={styles.blogInfo}>
        <Text style={styles.blogTitle}>{item.title}</Text>
        <Text style={styles.blogDate}>Last modified: {new Date(item.createdAt).toLocaleDateString()}</Text>
        {/* Display Tags */}
        {item.tags && item.tags.length > 0 && (
          <View style={styles.tagsContainer}>
            {item.tags.map((tag, index) => (
              <Text key={index} style={styles.tag}>
                #{tag}
              </Text>
            ))}
          </View>
        )}
      </View>
      <View style={styles.blogActions}>
        <TouchableOpacity onPress={() => setEditingBlog(item)}>
          <Icon name="create-outline" size={20} color="#2196F3" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDelete(item._id)}>
          <Icon name="trash-outline" size={20} color="#E74C3C" />
        </TouchableOpacity>
      </View>
    </View>
  );

  if (editingBlog) {
    return (
      <BlogEditor
        blog={editingBlog}
        onSave={() => {
          setEditingBlog(null);
          fetchBlogs();
        }}
        onCancel={() => setEditingBlog(null)}
      />
    );
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.addButton}
        onPress={() => setEditingBlog({})}
      >
        <Icon name="add-circle-outline" size={20} color="#fff" />
        <Text style={styles.addButtonText}>Add New Blog</Text>
      </TouchableOpacity>
      <FlatList
        data={blogs}
        keyExtractor={(item) => item._id}
        renderItem={renderBlogItem}
        contentContainerStyle={styles.listContainer}
      />
    </View>
  );
};

export default BlogScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addButton: {
    flexDirection: 'row',
    backgroundColor: '#000',
    padding: 10,
    borderRadius: 8,
    alignItems: 'center',
    alignSelf: 'flex-start',
    margin: 10,
  },
  addButtonText: {
    color: '#fff',
    marginLeft: 5,
    fontSize: 16,
  },
  listContainer: {
    paddingBottom: 20,
  },
  blogItem: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  blogInfo: {
    flex: 1,
  },
  blogTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  blogDate: {
    fontSize: 12,
    color: '#666',
    marginTop: 5,
  },
  blogActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 8,
  },
  tag: {
    backgroundColor: '#e0f7fa',
    color: '#00796b',
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 12,
    fontSize: 12,
  },
});
