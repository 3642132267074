import { Alert, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import { baseURL,imageURL } from '../apiConfig';

export const fetchUserData = async (setUserData, setLoading, navigation) => {
    try {
        setLoading(true);
        if(Platform.OS === 'web'){
            const userId = sessionStorage.getItem('userId');
            const userToken = sessionStorage.getItem('token');
            if (!userId || !userToken) {
                console.warn("⚠️ SecureStore not ready, retrying...");
                setTimeout(() => fetchUserData(setUserData, setLoading, navigation), 1000);
                return;
            }
            const response = await axios.get(`${baseURL}/users/${userId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
    
            if(response.data.msg === "User not found."){
                console.log('User not found');
                navigation.navigate('Login');
                return;
            }
    
            const { user } = response.data;
            const imageUrl = user.image ? `${imageURL}/${user.image.replace(/\\/g, '/')}` : null;
            setUserData({
                name: user.name || 'Your Name',
                phone: user.phone || 'Not Available',
                image: imageUrl,
            });
        } else {
            const userId = await SecureStore.getItemAsync('uid');
            const userToken = await SecureStore.getItemAsync('token');
            if (!userId || !userToken) {
                console.warn("⚠️ SecureStore not ready, retrying...");
                setTimeout(() => fetchUserData(setUserData, setLoading, navigation), 1000);
                return;
            }
            const response = await axios.get(`${baseURL}/users/${userId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
    
            if(response.data.msg === "User not found."){
                console.log('User not found');
                navigation.navigate('Login');
                return;
            }
    
            const { user } = response.data;
            const imageUrl = user.image ? `${imageURL}/${user.image.replace(/\\/g, '/')}` : null;
            setUserData({
                name: user.name || 'Your Name',
                phone: user.phone || 'Not Available',
                image: imageUrl,
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn("⚠️ User not found. Redirecting to Login.");
            navigation.navigate('Login');
        } else {
            console.error("❌ Error fetching user data:", error.message);
        }
    } finally {
        setLoading(false);
    }
};

export const fetchUserDataRaw = async () => {
    try {
        if(Platform.OS === 'web'){
            const userId = sessionStorage.getItem('userId');
            const userToken = sessionStorage.getItem('token');
            if (!userId || !userToken) {
                console.warn("⚠️ SecureStore not ready, retrying...");
                return { success: false, data: null };
            }
            const response = await axios.get(`${baseURL}/users/${userId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });

            if(response.data.msg === "User not found."){
                console.log('User not found');
                return { success: false, data: null };
            }

            const { user } = response.data;
            const imageUrl = user.image ? `${imageURL}/${user.image.replace(/\\/g, '/')}` : null;
            return {
                success: true,
                data: {
                    name: user.name || 'Your Name',
                    phone: user.phone || 'Not Available',
                    image: imageUrl,
                },
            };
        } else {
            const userId = await SecureStore.getItemAsync('uid');
            const userToken = await SecureStore.getItemAsync('token');
            if (!userId || !userToken) {
                console.warn("⚠️ SecureStore not ready, retrying...");
                return { success: false, data: null };
            }
            const response = await axios.get(`${baseURL}/users/${userId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });

            if(response.data.msg === "User not found."){
                console.log('User not found');
                return { success: false, data: null };
            }

            const { user } = response.data;
            const imageUrl = user.image ? `${imageURL}/${user.image.replace(/\\/g, '/')}` : null;
            return {
                success: true,
                data: {
                    name: user.name || 'Your Name',
                    phone: user.phone || 'Not Available',
                    image: imageUrl,
                },
            };
        }
        } catch (err) {
        console.error("fetchUserDataRaw failed:", err);
        return { success: false, data: null };
        }
  };