import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, StyleSheet, ScrollView } from 'react-native';
import axios from 'axios';
import RenderHtml from 'react-native-render-html';
import { baseURL } from '../apiConfig';
import { useWindowDimensions } from 'react-native';

const BlogDescriptionScreen = ({ route }) => {
    const { blogId } = route.params;
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const { width } = useWindowDimensions();

    useEffect(() => {
        // Fetch the specific blog by ID
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${baseURL}/blogs/${blogId}`);
                setBlog(response.data);
            } catch (error) {
                console.error('Error fetching blog:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [blogId]);

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#000" />
            </View>
        );
    }

    if (!blog) {
        return (
            <View style={styles.container}>
                <Text style={styles.errorText}>Blog not found.</Text>
            </View>
        );
    }

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.title}>{blog.title}</Text>
            <RenderHtml
                contentWidth={width}
                source={{ html: blog.content }}
                tagsStyles={{
                    h3: { fontSize: 20, fontWeight: 'bold', color: '#333' },
                    p: { fontSize: 16, color: '#333' },
                    a: { color: '#1e90ff' },
                }}
                enableExperimentalMarginCollapsing={true}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: '#ffffff',
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#000',
        marginBottom: 10,
    },
    errorText: {
        fontSize: 18,
        color: '#ff0000',
        textAlign: 'center',
    },
});

export default BlogDescriptionScreen;
