// SignupScreen.js

import React, { useState } from 'react';
import { View, StyleSheet, Alert, KeyboardAvoidingView, Platform,Pressable } from 'react-native';
import { TextInput, Button, Text, Title, TouchableRipple } from 'react-native-paper';
import axios from 'axios';
import { baseURL } from '../apiConfig';

const SignupScreen = ({ navigation }) => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignup = async () => {
    if (!name || !username || !email || !password) {
      Alert.alert('Validation Error', 'Please fill out all fields.');
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/auth/signup`, {
        name,
        username,
        email,
        password,
      });

      if (response.data.success) {
        Alert.alert('Success', 'Your account has been created.');
        navigation.navigate('Signin');
      } else {
        Alert.alert('Error', response.data.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      Alert.alert('Error', 'An error occurred during sign-up.');
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.innerContainer}>
        <Title style={styles.title}>Sign Up</Title>

        <TextInput
          label="Name"
          mode="outlined"
          value={name}
          onChangeText={setName}
          style={styles.input}
          textColor='#000'
          placeholderTextColor={{color: '#000'}}
          left={<TextInput.Icon name="account" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
            },
          }}
        />

        <TextInput
          label="Username"
          mode="outlined"
          value={username}
          onChangeText={setUsername}
          style={styles.input}
          textColor='#000'
          placeholderTextColor={{color: '#000'}}
          left={<TextInput.Icon name="account" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
            },
          }}
        />

        <TextInput
          label="Email"
          mode="outlined"
          value={email}
          onChangeText={setEmail}
          style={styles.input}
          textColor='#000'
          placeholderTextColor={{color: '#000'}}
          keyboardType="email-address"
          autoCapitalize="none"
          left={<TextInput.Icon name="email" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
            },
          }}
        />

        <TextInput
          label="Password"
          mode="outlined"
          value={password}
          onChangeText={setPassword}
          style={styles.input}
          textColor='#000'
          placeholderTextColor={{color:'#000'}}
          secureTextEntry
          left={<TextInput.Icon name="lock" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
            },
          }}
        />

        <Button
          mode="contained"
          onPress={handleSignup}
          style={styles.button}
          contentStyle={styles.buttonContent}
          textColor='#fff'
        >
          Sign Up
        </Button>

        <Pressable
          onPress={() => navigation.navigate('Signin')}
          onHoverIn={() => setIsHovered(true)}   // Detect hover start
          onHoverOut={() => setIsHovered(false)}  // Detect hover end
          style={[
            styles.linkContainer,
            isHovered && styles.hoverUnderline,      // Apply border style on hover
          ]}
        >
          <Text style={styles.linkText}>Already have an account? Sign In</Text>
        </Pressable>
      </View>
    </KeyboardAvoidingView>
  );
};

export default SignupScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f8f9fa', // Bootstrap's light gray background
    justifyContent: 'center', // Center the content vertically
    padding: 16, // Bootstrap-like padding
  },
  innerContainer: {
    width: '100%', // Full width for small screens
    maxWidth: 400, // Max width for large screens
    padding: 20,
    backgroundColor: '#ffffff', // White background for the form
    borderRadius: 8, // Rounded corners
    shadowColor: '#000', // Shadow for elevation effect
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // Elevation for Android
    alignSelf: 'center', // Centers the form horizontally
  },
  title: {
    fontSize: 28, // Similar to Bootstrap's heading sizes
    marginBottom: 24,
    textAlign: 'center',
    color: '#343a40', // Bootstrap's dark text color
    fontWeight: 'bold',
  },
  input: {
    marginBottom: 16,
    backgroundColor: '#ffffff', // White input fields
    borderRadius: 4, // Rounded edges for inputs
  },
  button: {
    marginTop: 8,
    borderRadius: 4,
    backgroundColor: '#000', // Bootstrap's primary blue color
  },
  buttonContent: {
    paddingVertical: 12, // More vertical padding to resemble Bootstrap buttons
  },
  linkContainer: {
    marginTop: 16,
    alignSelf: 'center',
  },
  linkText: {
    color: '#333', // Bootstrap's link color
    fontSize: 16,
    fontWeight: '600', // Semi-bold for readability
  },
  hoverUnderline: {
    textDecorationLine: 'underline',
  },
});
