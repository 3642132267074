// PageEditor.js
import React, { useEffect, useState, useRef } from 'react';
import ReactQuill, {Quill} from 'react-quill'; // For Web
import 'react-quill/dist/quill.snow.css'; // Web-specific CSS

import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Alert,
  ScrollView,
  Image,
  Platform,
} from 'react-native';
import { RichEditor, RichToolbar, actions } from 'react-native-pell-rich-editor'; // Rich text editor component
import * as ImagePicker from 'expo-image-picker'; // For image selection
import axios from 'axios';
import { baseURL } from '../apiConfig';

import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

const PageEditor = ({ page, onSave, onCancel }) => {
  const [title, setTitle] = useState(page.title || '');
  const [content, setContent] = useState(page.content || '');
  const [featuredImage, setFeaturedImage] = useState(page.featuredImage || '');
  const [excerpt, setExcerpt] = useState(page.excerpt || '');
  const [template, setTemplate] = useState(page.template || 'default');

  const richText = useRef();

    // This is an effect to log changes in the editor
    useEffect(() => {
      console.log(content);
    }, [content]);

  // Function to save the page (create or update)
  const handleSave = async () => {
    if (!title.trim()) {
      Alert.alert('Validation Error', 'Title is required.');
      return;
    }

    // Get the token from sessionStorage
    const token = sessionStorage.getItem('token');
    console.log("Token: ",token);
    // Check if token exists
    if (!token) {
      Alert.alert('Authentication Error', 'You are not logged in.');
      console.log('Authentication Error', 'You are not logged in.');
      return;
    }

    try {
      const token = sessionStorage.getItem('token');

      const pageData = {
        title,
        content,
        featuredImage,
        excerpt,
        template,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        }
      };
      console.log("PageData: ",pageData,config);
      if (page._id) {
        window.alert("Update this page");
        // Update existing page
        console.log("Updating...");
        await axios.put(`${baseURL}/pages/${page._id}`, pageData,config);
      } else {
        window.alert("Creating a new page");
        // Create new page
        console.log("Creating new page...");
        await axios.post(`${baseURL}/pages`, pageData,config);
      }
      Alert.alert('Success', 'Page saved successfully.');
      console.log("Success");
      onSave();
    } catch (error) {
      console.error('Error saving page:', error.response ? error.response.data : error);
      Alert.alert('Error', error.response ? error.response.data.msg : 'Failed to save page.');
    }
  };

  // Function to pick an image from the gallery
  const handleImagePick = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (permissionResult.granted === false) {
      Alert.alert('Permission Required', 'Permission to access the gallery is required!');
      return;
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!pickerResult.cancelled) {
      // You can upload the image to your server here and get the image URL
      // For demonstration, we'll use the local URI
      setFeaturedImage(pickerResult.uri);
    }
  };

  // Define toolbar and module settings
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { 'header': '3' }, { 'header': '4' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
    blotFormatter: {},
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const icon_h3 = `
  <svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="h3" fill="currentColor">
              <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
              <text id="3" font-family="Helvetica Neue, Helvetica, Arial, sans-serif" font-size="9" font-weight="500">
                  <tspan x="11.3" y="11">3</tspan>
              </text>
          </g>
      </g>
  </svg>`

  const icon_h4 = `
  <svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="h4" fill="currentColor">
              <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
              <text id="4" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                  <tspan x="11.3" y="11">4</tspan>
              </text>
          </g>
      </g>
  </svg>`

  Quill.import('ui/icons').header[3] = icon_h3;
  Quill.import('ui/icons').header[4] = icon_h4;

  return (
    <ScrollView style={styles.container}>
      <TextInput
        placeholder="Page Title"
        style={styles.titleInput}
        value={title}
        onChangeText={setTitle}
      />
      <Text style={styles.sectionLabel}>Content:</Text>
      {/* Platform check: web or mobile */}
      {Platform.OS === 'web' ? (
        // WYSIWYG for Web: Draft.js
        <View style={styles.editorContainer}>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
            bounds={'#root'}
            placeholder="Start writing here..."
          />
        </View>
      ) : (
        // WYSIWYG for Mobile: RichEditor and RichToolbar
        <View style={styles.richTextContainer}>
          <RichEditor
            ref={richText}
            initialContentHTML={content}
            onChange={setContent}
            style={styles.richTextEditor}
            placeholder="Start writing here..."
          />
          <RichToolbar
            editor={richText}
            actions={[
              actions.setBold,
              actions.setItalic,
              actions.setUnderline,
              actions.heading1,
              actions.insertBulletsList,
              actions.insertOrderedList,
              actions.insertImage,
            ]}
            iconMap={{
              [actions.heading1]: ({ tintColor }) => (
                <Text style={{ color: tintColor, fontSize: 16 }}>H1</Text>
              ),
            }}
            onPressAddImage={handleImagePick}
          />
        </View>
      )}
      <TouchableOpacity style={styles.imageButton} onPress={handleImagePick}>
        <Text style={styles.imageButtonText}>Set Featured Image</Text>
      </TouchableOpacity>
      {featuredImage ? (
        <Image source={{ uri: featuredImage }} style={styles.featuredImage} />
      ) : null}
      <Text style={styles.sectionLabel}>Excerpt:</Text>
      <TextInput
        placeholder="Add an excerpt..."
        style={styles.excerptInput}
        value={excerpt}
        onChangeText={setExcerpt}
        multiline
      />
      <Text style={styles.sectionLabel}>Template:</Text>
      <TextInput
        placeholder="Template name (e.g., default, custom-template)"
        style={styles.templateInput}
        value={template}
        onChangeText={setTemplate}
      />
      <View style={styles.buttonRow}>
        <TouchableOpacity style={styles.saveButton} onPress={handleSave}>
          <Text style={styles.saveButtonText}>Save</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
          <Text style={styles.cancelButtonText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

export default PageEditor;

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  titleInput: {
    fontSize: 24,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderColor: '#ccc',
    marginBottom: 15,
  },
  sectionLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 15,
    marginBottom: 5,
  },
  richTextEditor: {
    minHeight: 300,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  imageButton: {
    marginTop: 15,
    backgroundColor: '#2196F3',
    padding: 10,
    borderRadius: 8,
    alignItems: 'center',
  },
  imageButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  featuredImage: {
    width: '100%',
    height: 200,
    marginTop: 10,
  },
  excerptInput: {
    minHeight: 60,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    textAlignVertical: 'top',
  },
  templateInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
  },
  buttonRow: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  saveButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 8,
    flex: 1,
    marginRight: 5,
    alignItems: 'center',
  },
  saveButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  cancelButton: {
    backgroundColor: '#E74C3C',
    padding: 10,
    borderRadius: 8,
    flex: 1,
    marginLeft: 5,
    alignItems: 'center',
  },
  cancelButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});
