import React, { createContext, useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Load the token and role from sessionStorage when the app loads
  useEffect(() => {
    const loadAuthState = () => {
      try {
        const token = sessionStorage.getItem('token');
        const role = sessionStorage.getItem('role');
        setUserToken(token);
        setUserRole(role);
      } catch (error) {
        console.error('Error loading auth state:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAuthState();
  }, []);

  // Define the authentication functions
  const authContext = {
    userToken,
    userRole,
    isLoading,
    isAuthenticated,
    signIn: (token, role) => {
      try {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role);
        setUserToken(token);
        setUserRole(role);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
    },
    signOut: () => {
      try {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        setUserToken(null);
        setUserRole(null);
        setIsAuthenticated(false);

      } catch (error) {
        console.error('Error during sign-out:', error);
      }
    },
  };

  // If the app is still loading auth state, show a loading indicator
  if (isLoading) {
    return null; // Or render a loading screen
  }

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
