import React, { useState, useContext } from 'react';
import { View, StyleSheet, Alert, KeyboardAvoidingView, Platform, Pressable } from 'react-native';
import { TextInput, Button, Text, Title, TouchableRipple } from 'react-native-paper';
import axios from 'axios';
import { baseURL } from '../apiConfig';
import { AuthContext } from '../AuthContext_web';
import { useFonts, OpenSans_400Regular, OpenSans_600SemiBold, OpenSans_700Bold } from '@expo-google-fonts/open-sans';

const SigninScreen = ({ navigation }) => {
  const [username, setUsername] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [password, setPassword] = useState('');
  const { signIn } = useContext(AuthContext);

  const handleSignin = async () => {
    if (!username || !password) {
      Alert.alert('Validation Error', 'Please enter both username and password.');
      return;
    }

    try {

      const response = await axios.post(`${baseURL}/auth/signin`, { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`, // Add token if needed
          },
          withCredentials: true,
        }
      );
      if (response.data.success) {
        const { token, role, userId, username } = response.data;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('isLoggedIn', 'true');
        sessionStorage.setItem('username', username);
        await signIn(token, role);
        Alert.alert('Success', 'You are now signed in.');
      } else {
        Alert.alert('Error', response.data.message || 'Invalid username or password.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred during sign-in.');
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.innerContainer}>
        <Title style={styles.title}>Sign In</Title>

        <TextInput
          label="Username"
          mode="outlined"
          value={username}
          onChangeText={setUsername}
          style={styles.input}
          textColor='#000'
          placeholderTextColor={{color:'#000'}}
          keyboardType="default"
          autoCapitalize="none"
          textinputColor='#000'
          left={<TextInput.Icon name="account" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
              text: '#000',
              placeholder: '#808080', // Placeholder color
            },
          }}
        />

        <TextInput
          label="Password"
          mode="outlined"
          value={password}
          onChangeText={setPassword}
          style={styles.input}
          placeholderTextColor={{color:'#000'}}
          textColor='#000'
          secureTextEntry
          left={<TextInput.Icon name="lock" />}
          theme={{
            colors: {
              primary: '#808080', // Grey color on focus
              text: '#000',       // Black text color
              placeholder: '#808080', // Placeholder color
            },
          }}
        />

        <Button
          mode="contained"
          onPress={handleSignin}
          style={styles.button}
          textColor='rgb(255,255,255)'
          contentStyle={styles.buttonContent}
        >
          Sign In
        </Button>

        <Pressable
          onPress={() => navigation.navigate('Signup')}
          onHoverIn={() => setIsHovered(true)}   // Detect hover start
          onHoverOut={() => setIsHovered(false)}  // Detect hover end
          style={[
            styles.linkContainer,
            isHovered && styles.hoverUnderline,      // Apply border style on hover
          ]}
        >
          <Text style={styles.linkText}>Don't have an account? Sign Up</Text>
        </Pressable>
      </View>
    </KeyboardAvoidingView>
  );
};

export default SigninScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f0f0f0', // Light gray background
    justifyContent: 'center',
    padding: 16,
  },
  innerContainer: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: '#ffffff', // White background for the form
    borderRadius: 8,
    padding: 20,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  title: {
    fontSize: 28,
    marginBottom: 24,
    textAlign: 'center',
    color: '#333333', // Dark gray for contrast
    
    fontWeight: '700', // Bold title
  },
  input: {
    marginBottom: 16,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    color: '#000', 
  },
  button: {
    marginTop: 8,
    borderRadius: 4,
    backgroundColor: '#333333', // Dark gray button color
  },
  buttonTxt: {
    color: '#fff',
  },
  buttonContent: {
    paddingVertical: 12,
    fontFamily: 'OpenSans_600SemiBold',
    fontWeight: '600', // Semi-bold for emphasis
    color: '#ffffff',
  },
  linkContainer: {
    marginTop: 16,
    alignSelf: 'center',
    borderRadius: 4,
  },
  linkText: {
    color: '#333333', // Dark gray for links
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    fontWeight: '600', // Semi-bold for readability
  },
  hoverUnderline: {
    textDecorationLine: 'underline',
  },
});
