import React, { createContext, useState, useEffect } from 'react';
import {Platform} from 'react-native';
import { fetchUserData } from '../services/userService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ name: '', phone: '', image: null });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if(Platform.OS === 'web'){
          const uid = sessionStorage.getItem('userId');
          const uname = sessionStorage.getItem('username');
          setIsLoggedIn(!!uid && !!uname);
        } else {
        const uid = await SecureStore.getItemAsync('uid');
        const uname = await SecureStore.getItemAsync('uname');
        setIsLoggedIn(!!uid && !!uname);
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {
    fetchUserData(setUser, () => {}); // Fetch user data on app start
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};
