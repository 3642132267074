// UsersScreen.js

import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Alert,
  FlatList,
  Image,
  KeyboardAvoidingView,
  Platform,
  TextInput,
} from 'react-native';
import {
  Text,
  DataTable,
  IconButton,
  Button,
  Dialog,
  Portal,
  Paragraph,
  Menu,
  Provider as PaperProvider,
  ActivityIndicator,
} from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import { baseURL } from '../apiConfig';

const UsersScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [visibleRoleMenu, setVisibleRoleMenu] = useState(false);
  const [roleMenuAnchor, setRoleMenuAnchor] = useState(null);
  const [newRole, setNewRole] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newName, setNewName] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newImage, setNewImage] = useState('');
  const [newImageFile, setNewImageFile] = useState(null); // For image file upload

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        window.alert('Authorization denied. No token found.');
        return;
      }
      const response = await axios.get(`${baseURL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add token to the Authorization header
        },
      });
      console.log(response.data.users);
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
      Alert.alert('Error', 'Failed to fetch users.');
    } finally {
      setLoading(false);
    }
  };

  const showEditDialog = (user) => {
    setSelectedUser(user);
    setNewUsername(user.username);
    setNewEmail(user.email);
    setNewName(user.name || '');
    setNewPhone(user.profile.phone || '');
    setNewImage(user.image || '');
    setVisibleEditDialog(true);
  };

  const hideEditDialog = () => {
    setVisibleEditDialog(false);
    setSelectedUser(null);
    setNewImageFile(null); // Clear selected image file
  };

  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      Alert.alert('Permission Denied', 'We need permission to access your media library to upload an image.');
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsEditing: true,
    });

    if (!result.canceled) {
      setNewImage(result.uri); // Display the selected image
      setNewImageFile({
        uri: result.uri,
        type: result.type || 'image/jpeg', // Ensure MIME type is set
        name: result.fileName || 'profile_picture.jpg', // Set a default name
      });
    } else {
      console.log('Image picker canceled');
    }
  };

  const confirmEdit = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      };
      
      const formData = new FormData();
      formData.append('username', newUsername);
      formData.append('email', newEmail);
      formData.append('name', newName);
      formData.append('phone', newPhone);

      if (newImageFile) {
        formData.append('image', {
          uri: newImageFile.uri,
          type: newImageFile.type,
          name: newImageFile.name,
        });
      }
      console.log("New Image File: ",newImageFile);
      console.log("Form Data: ",formData);
      await axios.put(`${baseURL}/users/${selectedUser._id}`, formData, config);
      fetchUsers();
      Alert.alert('Success', 'User profile updated.');
    } catch (error) {
      console.error('Error updating user profile:', error);
      Alert.alert('Error', 'Failed to update user profile.');
    } finally {
      hideEditDialog();
    }
  };

  const showDeleteDialog = (userId) => {
    setSelectedUserId(userId);
    setVisibleDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setVisibleDeleteDialog(false);
    setSelectedUserId(null);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${baseURL}/users/${selectedUserId}`);
      fetchUsers();
      Alert.alert('Success', 'User deleted successfully.');
    } catch (error) {
      console.error('Error deleting user:', error);
      Alert.alert('Error', 'Failed to delete user.');
    } finally {
      hideDeleteDialog();
    }
  };

  const openRoleMenu = (event, userId) => {
    setSelectedUserId(userId);
    setRoleMenuAnchor({ x: event.nativeEvent.pageX, y: event.nativeEvent.pageY });
    setVisibleRoleMenu(true);
  };

  const closeRoleMenu = () => {
    setVisibleRoleMenu(false);
    setSelectedUserId(null);
  };

  const changeUserRole = async (role) => {
    try {
      const token = sessionStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        }
      };
      window.alert("chaning role of user id: "+selectedUserId);
      await axios.put(`${baseURL}/users/${selectedUserId}/role`, { role },config);
      fetchUsers();
      Alert.alert('Success', 'User role updated.');
    } catch (error) {
      console.error('Error updating user role:', error);
      Alert.alert('Error', 'Failed to update user role.');
    } finally {
      closeRoleMenu();
    }
  };

  const renderUserItem = ({ item }) => (
    <DataTable.Row style={styles.row}>
      <DataTable.Cell style={styles.cell}>
      {item.image ? (
        <Image
          source={{ uri: `${baseURL}/assets/${item.profile.image}` }} // Display image from assets folder
          style={styles.imagePreview}
        />
      ) : (
        <Text>No Image</Text>
      )}
    </DataTable.Cell>
      <DataTable.Cell style={styles.cell} textStyle={styles.textBlack}>{item.username}</DataTable.Cell>
      <DataTable.Cell style={styles.cell} textStyle={styles.textBlack}>{item.email}</DataTable.Cell>
      <DataTable.Cell style={styles.cell} textStyle={styles.textBlack}>{item.role}</DataTable.Cell>
      <DataTable.Cell style={styles.cell} textStyle={styles.textBlack}>
        {item.profile ? item.profile.phone : 'No Phone'}
      </DataTable.Cell>
      <DataTable.Cell style={styles.actionsColumn}>
        <IconButton
          icon="pen"
          size={20}
          onPress={() => showEditDialog(item)}
        />
        <IconButton
          icon="pencil"
          size={20}
          onPress={(event) => openRoleMenu(event, item._id)}
        />
        <IconButton
          icon="delete"
          size={20}
          onPress={() => showDeleteDialog(item._id)}
        />
      </DataTable.Cell>
    </DataTable.Row>
  );

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator animating size="large" color="#6200ee" />
      </View>
    );
  }

  return (
    <PaperProvider>
      <View style={styles.container}>
        <Text style={styles.title}>User Management</Text>
        <DataTable>
          <DataTable.Header style={styles.header}>
            <DataTable.Title style={styles.title} textStyle={styles.textWhite}>Image</DataTable.Title>
            <DataTable.Title style={styles.title} textStyle={styles.textWhite}>Username</DataTable.Title>
            <DataTable.Title style={styles.title} textStyle={styles.textWhite}>Email</DataTable.Title>
            <DataTable.Title style={styles.title} textStyle={styles.textWhite}>Role</DataTable.Title>
            <DataTable.Title style={styles.title} textStyle={styles.textWhite}>Phone</DataTable.Title>
            <DataTable.Title style={styles.actionsColumn} textStyle={styles.textWhite}>Actions</DataTable.Title>
          </DataTable.Header>

          <FlatList
            data={users}
            keyExtractor={(item) => item._id}
            renderItem={renderUserItem}
          />
        </DataTable>

        {/* Edit User Dialog */}
        <Portal>
          <Dialog visible={visibleEditDialog} onDismiss={hideEditDialog}>
            <Dialog.Title>Edit User</Dialog.Title>
            <Dialog.Content>
              <TextInput
                label="Username"
                value={newUsername}
                style={styles.textWhite}
                onChangeText={setNewUsername}
              />
              <TextInput
                label="Email"
                value={newEmail}
                style={styles.textWhite}
                onChangeText={setNewEmail}
              />
              <TextInput
                label="Name"
                value={newName}
                style={styles.textWhite}
                onChangeText={setNewName}
              />
              <TextInput
                label="Phone"
                value={newPhone}
                style={styles.textWhite}
                onChangeText={setNewPhone}
              />
              <Button mode="outlined" onPress={pickImage}>
                Choose Image
              </Button>
              {newImage && (
                <Image
                  source={{ uri: newImage }}
                  style={styles.imagePreview}
                  resizeMode="cover"
                />
              )}
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideEditDialog}>Cancel</Button>
              <Button onPress={confirmEdit}>Save</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {/* Delete Confirmation Dialog */}
        <Portal>
          <Dialog visible={visibleDeleteDialog} onDismiss={hideDeleteDialog}>
            <Dialog.Title>Confirm Delete</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Are you sure you want to delete this user?</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideDeleteDialog}>Cancel</Button>
              <Button onPress={confirmDelete}>Delete</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {/* Role Selection Menu */}
        <Menu
          visible={visibleRoleMenu}
          onDismiss={closeRoleMenu}
          anchor={roleMenuAnchor}
        >
          <Menu.Item onPress={() => changeUserRole('admin')} title="Admin" />
          <Menu.Item onPress={() => changeUserRole('editor')} title="Editor" />
          <Menu.Item onPress={() => changeUserRole('author')} title="Author" />
          <Menu.Item onPress={() => changeUserRole('contributor')} title="Contributor" />
          <Menu.Item onPress={() => changeUserRole('viewer')} title="Viewer" />
        </Menu>
      </View>
    </PaperProvider>
  );
};

export default UsersScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginBottom: 16,
    backgroundColor: '#fff',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    marginBottom: 16,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    backgroundColor: '#f8f9fa', // Light gray background
    padding: 10,
  },
  header: {
    backgroundColor: '#000', // Bootstrap-like primary blue color
  },
  row: {
    backgroundColor: '#fff', // White background for rows
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0', // Light gray border between rows
  },
  cell: {
    paddingVertical: 8, // Vertical padding for cells
    fontSize: 16, // Adjust font size for readability
    color:'#000',
  },
  actionsColumn: {
    justifyContent: 'flex-end', // Align actions to the right
    flexDirection: 'row', // Layout buttons horizontally
  },
  textBlack: {
    color: '#000', // Enforce black text color
  },
  textWhite: {
    color: '#fff', // Enforce black text color
  },
  actionButton: {
    marginHorizontal: 4, // Spacing between buttons
    borderRadius: 4, // Rounded corners for buttons
  },
  imagePreview: 
  { width: 50, 
    height: 50, 
    marginVertical: 8, 
    borderRadius: 25, 
  },
});
