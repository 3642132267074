import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  Switch,
  SafeAreaView,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import { MaterialIcons } from '@expo/vector-icons';
import { baseURL } from '../apiConfig';
import { Ionicons } from '@expo/vector-icons';

const AddGoalScreen = ({ navigation }) => {
  const [name, setName] = useState('');
  const [targetAmount, setTargetAmount] = useState('');
  const [description, setDescription] = useState('');
  const [userId, setUserId] = useState(null);
  const [reminder, setReminder] = useState(false);

  // Check for uid and uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        setUserId(uid);
        const uname = sessionStorage.getItem('username');

        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          navigation.navigate("Account");
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };

    checkAuth();
  }, [navigation]);

  const handleAddGoal = async () => {
    if (!name || !targetAmount) {
      Alert.alert('Name and target amount are required.');
      return;
    }

    const goalData = {
      userId: userId,
      name,
      targetAmount: parseFloat(targetAmount),
      description,
    };

    try {
      const response = await axios.post(`${baseURL}/goals`, goalData);

      if (response.data.success) {
        alert('Goal added successfully!');
        navigation.goBack();
      } else {
        alert('Error adding goal:', result.error);
      }
    } catch (error) {
      console.error('Error adding goal:', error);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
    <View>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.navigate('GoalsListScreen')}>
          <Ionicons name="arrow-back" size={24} color="#fff" />
        </TouchableOpacity>
        <Text style={styles.headerText}>Add new goal</Text>
      </View>
      <View style={styles.circleContainer}>
      <View style={styles.iconCircle}>
        <MaterialIcons name="savings" size={24} color="#FFF" /> {/* Add your desired icon here */}
      </View>
        <TextInput style={styles.amountText} 
          placeholder="Enter target amount"
          placeholderTextColor="#AAA"
          value={targetAmount}
          onChangeText={setTargetAmount}
        />
      </View>
      <Text style={styles.label}>Transaction Name</Text>
      <TextInput
        style={styles.input}
        placeholder="Enter transaction name"
        placeholderTextColor="#AAA"
        value={name}
        onChangeText={setName}
      />
      <Text style={styles.label}>Date</Text>
      <TouchableOpacity style={styles.dateInput}>
        <Text style={styles.dateText}>10 Jan, 2024 - 10 Feb, 2024</Text>
        <Ionicons name="calendar" size={20} color="#AAA" />
      </TouchableOpacity>
      <Text style={styles.label}>Description</Text>
      <TextInput
        style={styles.descriptionInput}
        placeholder="Description (Optional)"
        placeholderTextColor="#AAA"
        value={description}
        onChangeText={setDescription}
      />
      <TouchableOpacity style={styles.attachment}>
        <Ionicons name="attach" size={20} color="#AAA" />
        <Text style={styles.attachmentText}>Add attachment</Text>
      </TouchableOpacity>
      <View style={styles.reminderContainer}>
        <Text style={styles.label}>Reminder</Text>
        <Switch
          value={reminder}
          onValueChange={setReminder}
          trackColor={{ true: '#00FF00', false: '#666' }}
          thumbColor={reminder ? '#00FF00' : '#FFF'}
        />
      </View>
      <TouchableOpacity style={styles.createButton} onPress={handleAddGoal}>
        <Text style={styles.createButtonText}>Create goal</Text>
      </TouchableOpacity>
    </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#121212',
    padding: 20,
    paddingVertical: 40,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerText: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  circleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  iconCircle: {
    width: 50,
    height: 50,
    borderRadius: 25, // Makes it a circle
    backgroundColor: '#FFD700', // Example color (golden yellow)
    justifyContent: 'center', // Center the icon horizontally
    alignItems: 'center', // Center the icon vertically
    marginRight: 10, // Add spacing between the circle and the TextInput
  },
  amountText: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
  },
  label: {
    color: '#AAA',
    fontSize: 14,
    marginBottom: 5,
  },
  input: {
    backgroundColor: '#1F1F1F',
    color: '#FFF',
    borderRadius: 8,
    padding: 15,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#333',
  },
  dateInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1F1F1F',
    color: '#FFF',
    borderRadius: 8,
    padding: 15,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#333',
  },
  dateText: {
    color: '#FFF',
  },
  descriptionInput: {
    backgroundColor: '#1F1F1F',
    color: '#FFF',
    borderRadius: 8,
    padding: 15,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#333',
    height: 80,
  },
  attachment: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  attachmentText: {
    color: '#AAA',
    marginLeft: 10,
  },
  reminderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  createButton: {
    backgroundColor: '#FFF',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
  },
  createButtonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default AddGoalScreen;
