import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext_web'; // Assuming you have AuthContext

const useAxiosInterceptors = () => {
  const signOut = useContext(AuthContext); // Access signOut from context
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Return response if it's successful
      return response;
    },
    (error) => {
      // If the error is due to a 401 response, trigger the sign-out process
      if (error.response && error.response.status === 401) {
        console.error('Token expired or unauthorized. Logging out...');
        signOut(); // Trigger sign-out when the token is expired or unauthorized
      }
      return Promise.reject(error);
    }
  );
};

export default useAxiosInterceptors;
