// PagesScreen.js
import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Alert,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import PageEditor from './PageEditor'; // Component for adding/editing pages
import axios from 'axios';
import { baseURL } from '../apiConfig';

const PagesScreen = () => {
  const [pages, setPages] = useState([]);
  const [editingPage, setEditingPage] = useState(null);

  useEffect(() => {
    fetchPages();
  }, []);

  // Function to fetch pages from the server
  const fetchPages = async () => {
    try {
      const response = await axios.get(`${baseURL}/pages`);
      setPages(response.data.pages);
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  // Function to handle page deletion
  const handleDelete = async(pageId) => {
    window.alert("Page Id to be deleted: "+pageId);
    const confirmation = window.confirm('Are you sure you want to delete this page?');
    if (confirmation) {
      try {
        // Retrieve the token from sessionStorage
        const token = sessionStorage.getItem('token');
        if (!token) {
          window.alert('Authorization denied. No token found.');
          return;
        }
        // Make the DELETE request with the token in the Authorization header
        await axios.delete(`${baseURL}/pages/${pageId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to the Authorization header
          },
        });
        fetchPages(); // Refresh the list of pages
        window.alert('Page deleted successfully.');
      } catch (error) {
        console.error('Error deleting page:', error);
        window.alert('Failed to delete page.');
      }
    }
  };

  // Function to render each page item in the list
  const renderPageItem = ({ item }) => (
    <View style={styles.pageItem}>
      <View style={styles.pageInfo}>
        <Text style={styles.pageTitle}>{item.title}</Text>
        <Text style={styles.pageDate}>Last modified: {new Date(item.createdAt).toLocaleDateString()}</Text>
      </View>
      <View style={styles.pageActions}>
        <TouchableOpacity onPress={() => setEditingPage(item)}>
          <Icon name="create-outline" size={24} color="#2196F3" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleDelete(item._id)}>
          <Icon name="trash-outline" size={24} color="#E74C3C" />
        </TouchableOpacity>
      </View>
    </View>
  );

  // If editingPage is not null, show the PageEditor component
  if (editingPage !== null) {
    return (
      <PageEditor
        page={editingPage}
        onSave={() => {
          setEditingPage(null);
          fetchPages();
        }}
        onCancel={() => setEditingPage(null)}
      />
    );
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.addButton}
        onPress={() => setEditingPage({})}
      >
        <Icon name="add-circle-outline" size={20} color="#fff" />
        <Text style={styles.addButtonText}>Add New Page</Text>
      </TouchableOpacity>
      <FlatList
        data={pages}
        keyExtractor={(item) => item._id}
        renderItem={renderPageItem}
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={<Text style={styles.emptyText}>No pages available.</Text>}
      />
    </View>
  );
};

export default PagesScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addButton: {
    flexDirection: 'row',
    backgroundColor: '#000',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    margin: 10,
  },
  addButtonText: {
    color: '#fff',
    marginLeft: 5,
    fontSize: 16,
  },
  listContainer: {
    paddingBottom: 20,
  },
  pageItem: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  pageInfo: {
    flex: 1,
  },
  pageTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pageDate: {
    fontSize: 12,
    color: '#666',
    marginTop: 5,
  },
  pageActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyText: {
    textAlign: 'center',
    marginTop: 20,
    color: '#666',
  },
});
