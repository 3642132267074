// onboardingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    userName: '',
    userId: '',
    primaryGoal: '',
    calendar: '',
    income: '',
    recurringExpenses: [],
    timeCommitments: [],
  },
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setPrimaryGoal: (state, action) => {
      state.primaryGoal = action.payload;
    },
    setCalendar: (state, action) => {
        state.calendar = action.payload;
      },
    setIncome: (state, action) => {
      state.income = action.payload;
    },
    addRecurringExpense: (state, action) => {
        const newExpense = {
            ...action.payload,
            amount: parseFloat(action.payload.amount), // Ensure amount is a number
          };
          state.recurringExpenses.push(newExpense);
    },
    editRecurringExpense: (state, action) => {
        const index = state.recurringExpenses.findIndex(
            (expense) => expense.id === action.payload.id
        );
        if (index !== -1) {
            state.recurringExpenses[index] = {
            ...action.payload,
            amount: parseFloat(action.payload.amount), // Ensure amount is a number
            };
        }
    },
    deleteRecurringExpense: (state, action) => {
        state.recurringExpenses = state.recurringExpenses.filter(
            (expense) => expense.id !== action.payload
        );
    },
    setTimeCommitments: (state, action) => {
      state.timeCommitments = action.payload;
    },
  },
});

export const {
  setUserName,
  setUserId,
  setPrimaryGoal,
  setCalendar,
  setIncome,
  addRecurringExpense,
  editRecurringExpense,
  deleteRecurringExpense,
  setTimeCommitments,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
