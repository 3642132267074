import React, {useEffect, useState, useRef} from 'react';
import { View, Text, StyleSheet, ScrollView, TextInput, TouchableOpacity, Image, FlatList, Modal, Alert,useWindowDimensions, SafeAreaView, Platform, RefreshControl } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';

import DateTimePicker from '@react-native-community/datetimepicker';
import axios from 'axios';
import { baseURL } from '../apiConfig';
import SvgMoney from '../assets/boost_money.png'; // Import SVG as a component
import LogoWhite from '../assets/logo_white.png'; // Import PNG as a component
import BudgetOnBoarding from '../webview/BudgetOnBoarding';


const HomeScreen = ({ navigation }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const [modalVisible, setModalVisible] = useState(false);
  const [budgetData, setBudgetData] = useState([]);
  const [groupedExpenses, setGroupedExpenses] = useState({});
  const [expensesData, setExpensesData] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalBillsExpenses, setTotalBillsExpenses] = useState(0);
  const [totalNeedsExpenses, setTotalNeedsExpenses] = useState(0);
  const [totalWantsExpenses, setTotalWantsExpenses] = useState(0);
  const [goals, setGoals] = useState([]);

  const nav = navigation || useNavigation();
  
  const [sixMonthsBudgetData, setSixMonthsBudgetData] = useState([]);
  const [sixMonthsExpenseData, setSixMonthsExpenseData] = useState([]);
  const [budgetOverview, setBudgetOverview] = useState({
    totalSavings: 0,
    totalInvestments: 0,
    totalBudget: 0,
    totalExpenses: 0,
  });

  // Sample news data (replace with API)
  const newsData = [
    { id: '1', title: '10 Top-Performing Dividend Stocks of November 2024', tag: 'Free access', image: require('../assets/news1.png'), time: '2 min read • Nov 05, 2024' },
    { id: '2', title: 'Energy & Utilities Roundup: Market Talk', tag: 'Subscription', image: require('../assets/news2.png'), time: '2 min read • Nov 05, 2024' },
    { id: '3', title: 'Bitcoin Euphoria Threatens to Break These ETFs', tag: 'Subscription', image: require('../assets/news3.png'), time: '2 min read • Nov 05, 2024' },
  ];

  const months = [...Array(12)].map((_, i) => ({ id: `${i + 1}`, name: new Date(0, i).toLocaleString('default', { month: 'short' }) }));

  const [selectedMonth, setSelectedMonth] = useState('5'); // Default: May
  const flatListRef = useRef(null);
  const [onboardingData, setOnboardingData] = useState({});

  useEffect(() => {
      const checkAuth = () => {
        try {
          const data = sessionStorage.getItem('onboardingData');
          if (data) {
            console.log('Onboarding Data:', JSON.parse(data));
            setOnboardingData(JSON.parse(data));
          }
        } catch (error) {
          console.error('Error reading AsyncStorage:', error);
          Alert.alert('Error', 'Something went wrong. Please try again.'+ error);
          console.log('Error reading Storage:', error);
          navigation.navigate('Account');
        }
      };
  
      checkAuth();
    }, []);

    const fetchBudgetData = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        const response = await axios.get(`${baseURL}/budget/current?userId=${uid}`);
        
        if (response.data.success && response.data.budgets.length > 0) {
          const budgets = response.data.budgets;
          setBudgetData(budgets);
          
          let totalSavings = 0;
          let totalInvestments = 0;
          let totalBudget = 0;
          let totalExpenses = 0;
  
          budgets.forEach((budget) => {
            totalSavings += budget.savings;
            totalInvestments += budget.investments;
            totalBudget += budget.incomeAmount;
  
            if (budget.categories) {
              budget.categories.forEach((category) => {
                if (category.subcategories) {
                  category.subcategories.forEach((sub) => {
                    totalExpenses += parseFloat(sub.amount) || 0;
                  });
                }
              });
            }
          });
  
          setBudgetOverview({
            totalSavings,
            totalInvestments,
            totalBudget,
            totalExpenses,
          });
        } else {
          Alert.alert('Info', 'No budget found for the current month.');
        }
      } catch (error) {
        console.error('Error fetching budget data:', error);
      }
    };
  
    const fetchExpenseData = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        const response = await axios.get(`${baseURL}/expenses/current?userId=${uid}`);
        
        if (response.data.success && response.data.expenses.length > 0) {
          setExpensesData(response.data.expenses);
  
          let totalExpense = response.data.expenses.reduce((sum, expense) => sum + parseFloat(expense.amount), 0);
          setTotalExpenses(totalExpense);
        } else {
          Alert.alert('Info', 'No expenses found for the current month.');
        }
      } catch (error) {
        console.error('Error fetching expense data:', error);
      }
    };
  
    useEffect(() => {
      fetchBudgetData();
      fetchExpenseData();
    }, []);

  const handleMonthScroll = (direction) => {
    const currentIndex = months.findIndex((m) => m.id === selectedMonth);
    let newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;

    if (newIndex < 0) newIndex = 0;
    if (newIndex > 11) newIndex = 11;

    setSelectedMonth(months[newIndex].id);
    flatListRef.current.scrollToIndex({ index: newIndex, animated: true });
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.scrollContent}>

        {/* Navbar */}
        <View style={[styles.navbar, isMobile && styles.navbarMobile]}>
          <Image source={require('../assets/logo_white.png')} style={[styles.logo, isMobile && styles.logoMobile]} />
          <View style={[styles.navLinks, isMobile && styles.navLinksMobile]}>
            <Text style={styles.navText}>Home</Text>
            <Text style={styles.navText}>Insights</Text>
            <TouchableOpacity onPress={() => nav.navigate('TrackerScreen')}>
              <Text style={styles.navText}>Tracker</Text>
            </TouchableOpacity>
            <Text style={styles.navText}>Threads</Text>
            <Icon name="person-outline" size={20} color="#000" />
          </View>
        </View>

        <View style={[styles.heroSection, isMobile && styles.heroSectionMobile]}>
          <View style={[styles.heroTextContainer, isMobile && styles.heroTextContainerMobile]}>
            <Text style={[styles.heroText, isMobile && styles.heroTextMobile]}>Ready to boost your $savings today?</Text>
            {budgetData.length === 0 && (
              <TouchableOpacity style={[styles.button, isMobile && styles.buttonMobile]} onPress={() => setModalVisible(true)}>
                <Text style={styles.buttonText}>Let’s begin</Text>
              </TouchableOpacity>
            )}
          </View>
          <Image source={require('../assets/boost_money.png')} style={[styles.heroImage, isMobile && styles.heroImageMobile]} />
        </View>

        {/* Responsive Content */}
        <View style={[styles.widgetContainer, isMobile && styles.widgetContainerMobile]}>
          <Text style={styles.widgetTitle}>Budget Overview</Text>
          <View style={styles.budgetRow}><Text style={styles.budgetLabel}>Total Budget</Text><Text style={styles.budgetValue}>₹{budgetOverview.totalBudget}</Text></View>
          <View style={styles.budgetRow}><Text style={styles.budgetLabel}>Total Expenses</Text><Text style={styles.budgetValue}>₹{totalExpenses}</Text></View>
          <View style={styles.progressBarContainer}>
            <View style={[styles.progressBar, { width: `${(totalExpenses / (budgetOverview.totalBudget || 1)) * 100}%` }]} />
          </View>
          <Text style={styles.budgetLabel}>Remaining: ₹{Math.max(budgetOverview.totalBudget - totalExpenses, 0)}</Text>
        </View>

        {/* Month Navigation */}
        <View style={styles.monthNavigation}>
          <TouchableOpacity style={styles.navButton} onPress={() => handleMonthScroll('left')}><Icon name="chevron-back-outline" size={18} color="#fff" /></TouchableOpacity>
          <FlatList
            horizontal
            data={months}
            ref={flatListRef}
            keyExtractor={(item) => item.id}
            contentContainerStyle={styles.monthsContainer}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => setSelectedMonth(item.id)}>
                <Text style={[styles.monthText, selectedMonth === item.id && styles.activeMonth]}>{item.name}</Text>
              </TouchableOpacity>
            )}
            showsHorizontalScrollIndicator={false}
          />
          <TouchableOpacity style={styles.navButton} onPress={() => handleMonthScroll('right')}><Icon name="chevron-forward-outline" size={18} color="#fff" /></TouchableOpacity>
        </View>

        {/* Modal */}
        <Modal transparent visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeButton}><Icon name="close-outline" size={30} /></TouchableOpacity>
              <BudgetOnBoarding />
            </View>
          </View>
        </Modal>

      {/* Budget Tracker & Goal Creation */}
        <View style={[styles.row, isMobile && styles.rowMobile]}>
          <View style={[styles.trackerCard, isMobile && styles.trackerCardMobile]}>
            <View style={styles.trackerHeader}>
              <Text style={styles.trackerTitle}>Income & expenses</Text>
              <View style={styles.trackerIcons}>
                <TouchableOpacity><Icon name="search-outline" size={20} color="#fff" /></TouchableOpacity>
                <TouchableOpacity><Icon name="calendar-outline" size={20} color="#fff" style={{ marginLeft: 10 }} /></TouchableOpacity>
              </View>
            </View>
            <View style={styles.trackerOptions}>
              {['Daily', 'Monthly', 'Yearly', 'All'].map((label, idx) => (
                <TouchableOpacity key={idx} style={[styles.trackerButton, idx === 0 && styles.activeTrackerButton]}>
                  <Text style={idx === 0 ? styles.activeTrackerButtonText : styles.trackerButtonText}>{label}</Text>
                </TouchableOpacity>
              ))}
            </View>
            <View style={styles.graphPlaceholder} />
            <View style={styles.monthNavigation}>
              <TouchableOpacity style={styles.navButton} onPress={() => handleMonthScroll('left')}><Icon name="chevron-back-outline" size={18} color="#fff" /></TouchableOpacity>
              <FlatList
                horizontal
                data={months}
                ref={flatListRef}
                keyExtractor={(item) => item.id}
                contentContainerStyle={styles.monthsContainer}
                renderItem={({ item }) => (
                  <TouchableOpacity onPress={() => setSelectedMonth(item.id)}>
                    <Text style={[styles.monthText, selectedMonth === item.id && styles.activeMonth]}>{item.name}</Text>
                  </TouchableOpacity>
                )}
                showsHorizontalScrollIndicator={false}
              />
              <TouchableOpacity style={styles.navButton} onPress={() => handleMonthScroll('right')}><Icon name="chevron-forward-outline" size={18} color="#fff" /></TouchableOpacity>
            </View>
          </View>

          <View style={[styles.sideCards, isMobile && styles.sideCardsMobile]}>
            <TouchableOpacity style={styles.budgetGoalCard} onPress={() => nav.navigate('BudgetScreen')}>
              <Text style={styles.goalText}>+ Create your budget</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.budgetGoalCard} onPress={() => nav.navigate('AddGoalScreen')}>
              <Text style={styles.goalText}>+ Create your goal</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.addButton}><Text style={styles.addText}>+</Text></TouchableOpacity>
          </View>
        </View>

      {/* News Section */}
      <Text style={styles.sectionTitle}>Our latest news</Text>
      <FlatList
        data={newsData}
        numColumns={3}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <View style={styles.newsCard}>
            <Image source={item.image} style={styles.newsImage} />
            <View style={styles.newsContent}>
              <Text style={[styles.newsTag, item.tag === 'Subscription' ? styles.subscriptionTag : styles.freeTag]}>{item.tag}</Text>
              <Text style={styles.newsTitle}>{item.title}</Text>
              <Text style={styles.newsTime}>{item.time}</Text>
            </View>
          </View>
        )}
      />

      {/* Footer */}
      <View style={styles.footerContainer}>
        <View style={[styles.footerTop, isMobile && styles.footerTopMobile]}>
          <View style={styles.footerLeftBox}>
            <Image source={require('../assets/savingy_logo.png')} style={styles.footerLogo} />
            <Text style={styles.footerText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nulla integer.
            </Text>
            <View style={styles.footerStoresRow}>
              <Image source={require('../assets/app_store.png')} style={styles.storeBadge} />
              <Image source={require('../assets/play_store.png')} style={styles.storeBadge} />
            </View>
          </View>

          <View style={styles.footerRightLinks}>
            {['Tracker', 'Insights', 'Threads', 'Contact us'].map((link, i) => (
              <Text key={i} style={styles.footerLink}>{link}</Text>
            ))}
            <View style={styles.footerSocialsRow}>
              <Image source={require('../assets/instagram.png')} style={styles.socialIcon} />
              <Image source={require('../assets/facebook.png')} style={styles.socialIcon} />
              <Image source={require('../assets/linkedin.png')} style={styles.socialIcon} />
            </View>
          </View>
        </View>

        <View style={styles.footerBottom}>
          <Text style={styles.footerBottomText}>All rights reserved</Text>
          <View style={styles.footerBottomLinks}>
            <Text style={styles.footerBottomText}>Privacy policy</Text>
            <Text style={styles.footerBottomText}>Terms and conditions</Text>
          </View>
        </View>
      </View>

    </ScrollView>
    </SafeAreaView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9FBFA',
    paddingHorizontal: 30,
  },
  navbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
    alignItems: 'center',
  },
  logo: {
    width: "10%",
    height: 30,
  },
  navLinks: {
    flexDirection: 'row',
    gap: 20,
  },
  navText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  heroContainer: {
    margin: 50,
    padding: 40,
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderRadius: 10,
    shadowColor: '#ccc',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  heroContainerMobile: {
    margin: 20,
    marginHorizontal: 5,
    padding: 10,
  },
  heroText: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: 600,
  },
  heroTextMobile: {
    width: '100%',
    fontSize: 22,
  },
  heroButton: {
    marginTop: 40,
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 10,
  },
  heroButtonMobile: {
    marginTop: 20,
  },
  heroButtonText: {
    color: 'white',
    fontSize: 16,
  },
  button: {
    width: "25%",
    backgroundColor: '#000',
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 8,
    marginTop: 15,
  },
  buttonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
    fontWeight: 'thin',
  },
  widgetContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 20,
    marginBottom: 20,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
  },
  budgetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  budgetLabel: {
    fontSize: 16,
    color: '#555',
  },
  budgetValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  progressBarContainer: {
    height: 10,
    backgroundColor: '#e0e0e0',
    borderRadius: 5,
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#FFD700',
  },
  heroImageContainer: {
    width: '45%',  // Ensures proper spacing
    alignItems: 'flex-end',  // Aligns image properly
  },
  heroImage: {
    height: 180,  // Makes the SVG illustration match the original design
    resizeMode: 'contain', // Ensures it does not stretch
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 20,
  },
  rowMobile: {
    flexDirection: 'column',
    gap: 12,
    paddingHorizontal: 12,
  },
  trackerCard: {
    backgroundColor: '#000', 
    padding: 20,
    borderRadius: 15,
    width: '55%',
  },
  trackerCardMobile: {
    width: '100%',
  },
  trackerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trackerTitle: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  trackerIcons: {
    flexDirection: 'row',
  },
  trackerOptions: {
    flexDirection: 'row',
    marginTop: 15,
    backgroundColor: '#1C1C1C',
    padding: 5,
    borderRadius: 10,
    justifyContent: 'space-between',
  },
  trackerButton: {
    flex: 1,
    paddingVertical: 10,
    borderRadius: 8,
    alignItems: 'center',
  },
  activeTrackerButton: {
    backgroundColor: '#fff',
  },
  activeTrackerButtonText: {
    color: '#000',
    fontWeight: 'bold',
  },
  trackerButtonText: {
    color: '#A5A5A5',
  },
  graphPlaceholder: {
    backgroundColor: '#111',
    height: 120,
    marginTop: 15,
    borderRadius: 10,
  },

  /** 🔹 Month Navigation with Scroll **/
  monthNavigation: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  navButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 20,
    padding: 5,
  },
  monthsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  monthText: {
    color: '#A5A5A5',
    fontSize: 14,
    fontWeight: 'bold',
    paddingHorizontal: 10,
  },
  activeMonth: {
    color: '#fff',
    fontSize: 15,
    textDecorationLine: 'underline',
  },
  sectionTitle: {
    fontSize: 30,
    textAlign: 'center',
    padding: 20,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  budgetGoalCard: {
    backgroundColor: '#fff',
    padding: 55,
    borderRadius: 10,
    marginBottom: 10,
    width: '100%',
  },
  newsCard: {
    backgroundColor: '#fff',
    borderRadius: 12,
    marginBottom: 15,
    overflow: 'hidden',
    width: '32%',
  },
  footer: {
    backgroundColor: '#000',
    padding: 20,
    marginTop: 30,
  },
  sideCards: {
    width: '35%',  // Takes 30% of screen width (Aligned Right)
    alignItems: 'flex-end', // Aligns contents to the right
  },
  sideCardsMobile: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  budgetGoalCard: {
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 10,
    width: '100%', // Makes sure buttons take full width
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ddd',
  },
  goalText: {
    fontSize: 16,
    color: '#333',
    padding: 55,
    textAlign: 'center',
  },

  /** 🔹 Floating "+" Button **/
  addButton: {
    backgroundColor: '#FFD700',
    width: 50,
    height: 50,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10, // Adds spacing
    elevation: 5,  // Adds shadow (Android)
    shadowColor: '#000', 
    shadowOpacity: 0.2, 
    shadowOffset: { width: 0, height: 3 }, 
    shadowRadius: 4,
  },
  addText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
  },

  /** 🔹 NEWS SECTION FIX **/
  sectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 15,
  },

  newsCard: {
    backgroundColor: '#fff',
    borderRadius: 12,
    marginBottom: 15,
    marginRight: 10,
    overflow: 'hidden',
    width: '32%',
  },
  newsImage: {
    width: '100%',
    height: 120,
  },
  newsContent: {
    padding: 10,
  },
  newsTag: {
    fontSize: 12,
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 4,
    alignSelf: 'flex-start',
    marginBottom: 5,
    fontWeight: 'bold',
  },
  freeTag: {
    backgroundColor: '#48C774',
    color: '#fff',
  },
  subscriptionTag: {
    backgroundColor: '#FFD700',
    color: '#000',
  },
  newsTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  newsTime: {
    fontSize: 12,
    color: '#777',
  },

  footerContainer: {
    backgroundColor: '#111',
    padding: 20,
    paddingBottom: 40,
  },
  footerTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  footerTopMobile: {
    flexDirection: 'column',
    gap: 30,
  },
  footerLeftBox: {
    backgroundColor: '#222',
    padding: 20,
    borderRadius: 15,
    width: 320,
  },
  footerLogo: {
    width: 120,
    height: 40,
    marginBottom: 10,
    resizeMode: 'contain',
    alignSelf: 'flex-start',
  },
  footerText: {
    color: 'white',
    fontSize: 14,
    marginBottom: 15,
  },
  footerStoresRow: {
    flexDirection: 'row',
    gap: 10,
  },
  storeBadge: {
    width: 120,
    height: 40,
    resizeMode: 'contain',
    marginRight: 10,
  },
  footerRightLinks: {
    gap: 10,
  },
  footerLink: {
    color: 'white',
    fontSize: 16,
    marginBottom: 10,
  },
  footerSocialsRow: {
    flexDirection: 'row',
    marginTop: 50,
    paddingTop: 50,
    gap: 15,
    alignContent: 'flex-start',
  },
  socialIcon: {
    width: 30,
    height: 30,
  },
  footerBottom: {
    marginTop: 30,
    borderTopWidth: 1,
    borderColor: '#444',
    paddingTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerBottomText: {
    color: 'white',
  },
  footerBottomLinks: {
    flexDirection: 'row',
    gap: 20,
  },
  /** 🔹 Modal Styling **/
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    width: '80%',
    height: '80%',
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 10,
  },
  closeButton: {
    alignSelf: 'flex-end',
    padding: 10,
  },
  safeArea: { flex: 1, backgroundColor: '#F9FBFA' },
  scrollContent: { padding: 20 },
  navbar: {
    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10, marginBottom: 20
  },
  navbarMobile: {
    flexDirection: 'column', alignItems: 'flex-start', gap: 10
  },
  logo: { width: 100, height: 30, resizeMode: 'contain' },
  logoMobile: { alignSelf: 'flex-start' },
  navLinks: { flexDirection: 'row', gap: 15 },
  navLinksMobile: { flexDirection: 'column', alignItems: 'flex-start' },
  navText: { fontSize: 16, fontWeight: 'bold', color: '#000' },
});
