import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Alert, ActivityIndicator, RefreshControl, Image  } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import Icons from 'react-native-vector-icons/FontAwesome';
import { LineChart } from 'react-native-chart-kit';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import { baseURL } from '../apiConfig'; 
import SvgMoney from '../assets/home_money.png';
import OnboardingScreen from './OnboardingScreen';


const HomeScreen = ({ navigation }) => {
  // Budget Overview state
  const [budgetOverview, setBudgetOverview] = useState({
    totalSavings: 0,
    totalInvestments: 0,
    totalBudget: 0,
    totalExpenses: 0, // Added to track total expenses
  });

  const [refreshing, setRefreshing] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const [totalExpenses, setTotalExpenses] = useState(0); // Track total expenses
  const [totalBillsExpenses, setTotalBillsExpenses] = useState(0); // Track total bills expenses
  const [totalNeedsExpenses, setTotalNeedsExpenses] = useState(0); // Track total needs expenses
  const [totalWantsExpenses, setTotalWantsExpenses] = useState(0); // Track total wants expenses

  const [groupedExpenses,setGroupedExpenses] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});

  const [goals,setGoals] = useState([]);

  const [budgetSixMonthsData, setBudgetSixMonthsData] = useState([]); // State to store the fetched budget data
  const [expenseSixMonthsData, setExpenseSixMonthsData] = useState([]); // State to store the fetched budget data
  const [loading, setLoading] = useState(false); // State to handle loading state
  const [error, setError] = useState(null); // State to handle errors

  const loadFonts = async () => {
    await Font.loadAsync({
      'Outfit': require('../assets/fonts/Outfit-VariableFont_wght.ttf'), 
    });
    setFontsLoaded(true);
  };
  const [boardingData, setBoardingData] = useState([]);

  useEffect(() => {
    loadFonts();
  }, []);


  useEffect(() => {
    const checkOnBoardingData = async () => {
      try {
        const data = sessionStorage.getItem('onboardingData');
        if (data) {
          setBoardingData(JSON.parse(data)); // Parse the JSON data
          console.log("Boarding Data Home Page: ", data);
        } else {
          console.log("No onboarding data found.");
        }
      }catch (error) {
        console.error('Error reading AsyncStorage:', error);
        Alert.alert('Error', 'BoardingData Not Found!');
      }
    };
    checkOnBoardingData();
  }, []);


  // Check for user auth in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        const uname = sessionStorage.getItem('username');
    
        console.log(" UID:", uid);
        console.log(" Username:", uname);
    
        if (!uid || !uname) {
          Alert.alert("Error", "No user data found. Redirecting to Login.");
          return;
        }
    
        setUserName(uname);
        setUserId(uid);
        Alert.alert("Welcome back!", `User: ${uname}\nUID: ${uid}`);
      } catch (error) {
        console.error("Error:", error);
        Alert.alert("Error", "Something went wrong. Please try again.");
      }
    };

    checkAuth();
  }, [navigation]);

  // Function to fetch six months of budget data
  const fetchSixMonthsBudgetData = async () => {
    setLoading(true); // Start loading
    setError(null); // Reset error state

    try {
      const userId = sessionStorage.getItem('userId');
      console.log("User ID: ", userId);
      const response = await axios.get(`${baseURL}/budget/six`, {
        params: { userId },
      });

      console.log("✅ API Response:", response.data); // Debugging

      if (response.data.success) {
        setBudgetSixMonthsData(response.data.data); // Save the fetched data to state
      } else {
        console.warn("Error Message from API: ", response.data.message);
        Alert(response.data.message||'Failed to fetch data.');
      }
    } catch (err) {
      //console.error('Error fetching budget data:', err);
      //console.error("Error Details:", err.message);
      if (err.response) {
        Alert.alert('Info', err.response.data.message);
        //console.error("❌ Server Response:", err.response.data);
      }
      if(err.response.status === 404){
        Alert.alert('Info', err.response.data.message);
      }
    } finally {
      setLoading(false); // End loading
    }
  };

    // Function to fetch six months of expense data
    const fetchSixMonthsExpenseData = async () => {
      setLoading(true); // Start loading
      setError(null); // Reset error state
  
      try {
        const userId = sessionStorage.getItem('userId');
        console.log("User ID: ", userId);
        const response = await axios.get(`${baseURL}/expenses/six`, {
          params: { userId },
        });
        console.log("✅ API Response:", response.data); // Debugging
        if (response.data.success) {
          setExpenseSixMonthsData(response.data.data); // Save the fetched data to state
        } else {
          console.warn("Error Message from API: ", response.data.message);
          Alert.alert(response.data.message || 'Failed to fetch data.');
        }
      } catch (err) {
        console.error('Error fetching expense data:', err);
        console.error("Error Details:", err.message);

        if (err.response) {
          console.error("❌ Server Response:", err.response.data);
        }

        Alert.alert('An error occurred while fetching data.');
      } finally {
        setLoading(false); // End loading
      }
    };

  // Helper function to convert `monthYear` to abbreviations
  const getMonthName = (monthYear) => {
    const [year, month] = monthYear.split('-').map(Number);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[month - 1];
  };

  const getMonthNameWithYear = (monthYear) => {
    const [year, month] = monthYear.split('-').map(Number); // Split the string "YYYY-MM"
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const shortYear = year % 100; // Get the last two digits of the year (e.g., 2024 -> 24)
    return `${monthNames[month - 1]} ${shortYear}`; // Format as "Month YY"
  };

  // Prepare data for the chart
  const months = budgetSixMonthsData.map((data) => getMonthNameWithYear(data.monthYear));
  const budgetTotals = budgetSixMonthsData.map((data) => data.totalBudget);
  const expenseTotals = expenseSixMonthsData.map((data) => data.totalExpense);
  const savings = budgetSixMonthsData.map((data, index) => {
    const totalExpense = expenseSixMonthsData[index]?.totalExpense || 0;
    return data.totalBudget - totalExpense; // Calculate savings as Budget - Expenses
  });

  const fetchBudgetData = async () => {
    try {
      const uid = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/budget/current?userId=${uid}`);
      if(response.data.success && response.data.message != "No budgets found for the current month.") {
        if (response.data.success) {
          const budgets = response.data.budgets;
          setBudgetData(budgets);
          //console.log(JSON.stringify(budgets, null, 2));
          let totalSavings = 0;
          let totalInvestments = 0;
          let totalBudget = 0;
          let totalExpenses = 0;
          

        if (Array.isArray(budgets) && budgets.length > 0) {
          budgets.forEach((budget) => {
            totalSavings += budget.savings;
            totalInvestments += budget.investments;
            totalBudget += budget.incomeAmount;

              if (budget.categories && Array.isArray(budget.categories)) {
                budget.categories.forEach((category) => {
                  if (category.subcategories && Array.isArray(category.subcategories)) {
                    category.subcategories.forEach((sub) => {
                      totalExpenses += parseFloat(sub.amount) || 0;
                    });
                  }
                });
              }
            });
          } else {
            Alert.alert('Info', 'No budgets found for the user.');
          }

          setBudgetOverview({
            totalSavings,
            totalInvestments,
            totalBudget,
            totalExpenses, // Updated
          });
        } else {
          console.log('Error fetching budget data');
        }
      }else{
        Alert.alert("No budget found for the user for the current month!");
      }
    } catch (error) {
      console.error('Error fetching budget data:', error);
    }
  };

  const fetchGoals = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/goals`, { params: { userId }});
      if (response.data.success) {
        setGoals(response.data.goals);
      } else {
        Alert.alert('Error fetching goals:',response.data.message);
      }
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };


  const fetchExpenseData = async () => {
    try {
      const uid = sessionStorage.getItem('userId'); 
      const response = await axios.get(`${baseURL}/expenses/current?userId=${uid}`);
      
      //console.log("Expenses found: ", response.data.expenses);
      if (response.data.success && response.data.message === "No expenses found for the current month") {
        Alert.alert("No expenses found for the current month!");
      }
      if (response.data.success && response.data.message != "No expenses found for the current month") {
        const expenses = response.data.expenses;
        const grouped = expenses.reduce((acc, expense) => {
          const { parent_category, category, amount } = expense;
          if (!acc[parent_category]) {
            acc[parent_category] = [];
          }
          acc[parent_category].push({ category, amount });
          return acc;
        }, {});
        setGroupedExpenses(grouped);
        if (expenses && Array.isArray(expenses)) {
          let totalExpense = 0;
          let totalBillsExpense = 0;
          let totalNeedsExpense = 0;
          let totalWantsExpense = 0;
          // Process the expense data array
          expenses.forEach((expense) => {
            const { parent_category, amount } = expense;

            // Accumulate total expenses
            totalExpense += parseFloat(amount) || 0;

            // Accumulate totals based on parent category
            if (parent_category === 'Recurring Expenses') {
              totalBillsExpense += parseFloat(amount) || 0;
            }
            if (parent_category === 'Needs') {
              totalNeedsExpense += parseFloat(amount) || 0;
            }
            if (parent_category === 'Wants') {
              totalWantsExpense += parseFloat(amount) || 0;
            }
          });
          
    
          setExpensesData(expenses);
          setTotalExpenses(totalExpense);
          setTotalBillsExpenses(totalBillsExpense);
          setTotalNeedsExpenses(totalNeedsExpense);
          setTotalWantsExpenses(totalWantsExpense);
          
        }
      } else {
        Alert.alert('Error', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching expense data:', error);
      Alert.alert('Error', 'An error occurred while fetching budget data.');
    }

  }

  useEffect(() => {
    fetchBudgetData();
    fetchExpenseData();
    fetchGoals();
    fetchSixMonthsBudgetData();
    fetchSixMonthsExpenseData();
  }, []);

  // Calculate the total expense for each category
  const getCategoryExpenses = (categoryName) => {
    return expensesData
      .filter(expense => expense.category === categoryName)
      .reduce((total, expense) => total + expense.amount, 0);
  };

  // Render category cards
  const renderCategoryCards = (category) => {
    return category.subcategories.map(subcategory => {
      const expenseAmount = getCategoryExpenses(subcategory.name);
      const progress = expenseAmount / subcategory.amount;

      return (
        <View key={subcategory.name} style={styles.card}>
          <Text style={styles.subcategoryName}>{subcategory.name}</Text>
          <Text style={styles.subcategoryAmount}>Amount: ₹{subcategory.amount}</Text>
          <Text style={styles.spentAmount}>Spent: ₹{expenseAmount}</Text>
          <View style={styles.progressBarContainer}>
            <View style={[styles.progressBar, { width: `${progress * 100}%` }]} />
            <Text style={styles.progressText}>
              {(progress * 100).toFixed(1)}%
            </Text>
          </View>
        </View>
      );
    });
  };


  // Group expenses by category
  const groupExpensesByCategory = () => {
    const grouped = expensesData.reduce((acc, expense) => {
      if (!acc[expense.parent_category]) {
        acc[expense.parent_category] = [];
      }
      acc[expense.parent_category].push(expense);
      return acc;
    }, {});
    return grouped;
  };

  const renderCategoryExpenses = () => {
    // State to track which categories are expanded
   
    const groupedExpenses = groupExpensesByCategory();
  
    // Function to toggle the expand/collapse state of a category
    const toggleCategory = (category) => {
      setExpandedCategories((prevState) => ({
        ...prevState,
        [category]: !prevState[category],
      }));
    };
  
    return Object.keys(groupedExpenses).map((category, index) => {
      const expenses = groupedExpenses[category];
      const totalCategoryAmount = expenses.reduce((sum, expense) => sum + expense.amount, 0);
      const isExpanded = expandedCategories[category];
  
      return (
        <View key={index} style={styles.categoryExpenseContainer}>
          {/* Category Header with Expand/Collapse Toggle */}
          <TouchableOpacity onPress={() => toggleCategory(category)} style={styles.categoryHeader}>
            <Text style={styles.categoryExpenseTitle}>{category}</Text>
            <Text style={styles.chevron}>{isExpanded ? '▲' : '▼'}</Text>
          </TouchableOpacity>
  
          {/* Show expenses only if the category is expanded */}
          {isExpanded && (
            <ScrollView style={styles.expensesContainer}>
              {expenses.map((expense, idx) => (
                <View key={idx} style={styles.expenseCard}>
                  <Text style={styles.expenseCategory}>{expense.category}</Text>
                  <Text style={styles.expenseAmount}>₹ {expense.amount}</Text>
                </View>
              ))}
            </ScrollView>
          )}
  
          {/* Total Amount Displayed Outside */}
          <Text style={styles.totalAmount}>Total: ₹ {totalCategoryAmount}</Text>
        </View>
      );
    });
  };



  const onRefresh = () => {
    setRefreshing(true);
    
    Promise.all([fetchBudgetData(), fetchExpenseData(), fetchGoals() ])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  const handleBegin = () => {
    navigation.navigate('budgetOnboarding')
  };

  /* if (!fontsLoaded) {
    return (
      <AppLoading
        startAsync={loadFonts}
        onFinish={() => setFontsLoaded(true)}
        onError={console.warn}
      />
    );
  } */

  if (loading) {
    return <ActivityIndicator size="large" color="#000" style={styles.loader} />;
  }

  if (error) {
    return <Text style={styles.error}>{error}</Text>;
  }

  return (
    <ScrollView
      style={styles.container}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    >
      <View style={styles.widgetContainer}>
        <View style={styles.userHi}>
        <Text style={styles.widgetTitle_user}>Hi {userName}, 👋</Text>
        {/* <Icons name="hand-paper-o" size={24} color="orange" /> */}
        </View>
        <Text style={styles.widget_slogan}>Welcome to Savingy!</Text>
      </View>
      {(budgetData.length === 0) && (
        <View style={styles.widgetContainer}>
          
          <Image source={require('../assets/home_money.png')} style={{width:"100%"}} />
          <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('BudgetOnboarding')}>
            <Text style={styles.buttonText}>Let's begin</Text>
          </TouchableOpacity>
        </View>
      )}

    <View style={styles.widgetContainer}>
      <View style={styles.row}>
        <View>
          <Text style={styles.widgetTitle}>Income</Text>
        </View>
        {/* Divider Line */}
        <View style={styles.divider} />
        <View>
          <Text style={styles.budgetValue} numberOfLines={1} ellipsizeMode="tail">₹{budgetOverview.totalBudget}</Text>
        </View>
      </View>
    </View>

      {/* Budget Overview Widget */}
      <View style={styles.widgetContainer}>
        <Text style={styles.widgetTitle}>Budget Overview</Text>

        {/* Total Budget and Expenses Row */}
        <View style={styles.budgetRow}>
          <View style={styles.budgetColumn}>
            <View style={styles.budgetIcon}>
              <Icon name="arrow-up-outline" size={20} color="green" style={styles.budgetIcon} />
              <Text style={styles.budgetLabel}>Total Budget</Text>
            </View>
            <Text style={styles.budgetValue}>₹{budgetOverview.totalBudget}</Text>
          </View>
          {/* Divider Line */}
          <View style={styles.divider} />
          <View style={styles.budgetColumn}>
            <View style={styles.expenseIcon}>
              <Icon name="arrow-down-outline" size={20} color="red" style={styles.budgetIcon} />
              <Text style={styles.budgetLabel}>Total Expenses</Text>
            </View>
            <Text style={styles.budgetValue}>₹{totalExpenses}</Text>
          </View>
        </View>

        {/* Progress Bar */}
        <View style={styles.progressBarContainer}>
          <View
            style={[
              styles.progressBar,
              { width: `${(totalExpenses / (budgetOverview.totalBudget)) * 100}%` },
            ]}
          />
          <Text style={styles.progressText}>
            {((totalExpenses / (budgetOverview.totalBudget)) * 100).toFixed(1)}%
          </Text>
        </View>

        {/* Remaining Amount */}
        <View style={styles.budgetRow}>
          <Text style={styles.budgetLabel}>Remaining Amount:</Text>
          <Text style={styles.budgetValue}>
            ₹{Math.max(budgetOverview.totalBudget - totalExpenses)}
          </Text>
        </View>
        <View>
          <Text style={styles.budgetValue}>
            {(((budgetOverview.totalBudget - totalExpenses)/budgetOverview.totalBudget)*100).toFixed(0)}% left of Budget
          </Text>
        </View>
      </View>

      <View>
        <ScrollView contentContainerStyle={styles.cardContainer}>
          {budgetData.map((budget, index) => (
            <View key={index} style={styles.budgetSection}>
              {budget.categories.map((category) => (
                <View key={category.name} style={styles.categoryContainer}>
                  <Text style={styles.categoryTitle}>
                    {category.name === 'Recurring Expenses' && '💸 '}
                    {category.name === 'Needs' && '🧻 '}
                    {category.name === 'Wants' && '🤑 '}
                    {category.name}
                  </Text> 
                  <ScrollView horizontal style={styles.cardScroll}>
                    {renderCategoryCards(category)}
                  </ScrollView>
                </View>
              ))}
            </View>
          ))}

          <View>
            <Text style={styles.categoryTitle}>🎯 Goals</Text>
          </View>
          
          <ScrollView horizontal style={styles.cardScroll}>
            {goals.map((goal, index) => (
              <View style={styles.card}>
                <View style={styles.cardHeader}>
                  <Text style={styles.goalAmount}>₹{goal.targetAmount}</Text>
                  <TouchableOpacity onPress={() => navigation.navigate('EditGoal', { goal: goal })}>
                    <Text style={styles.menuIcon}>⋮</Text>
                  </TouchableOpacity>
                </View>
                <Text style={styles.goalName}>{goal.name}</Text>
                <Text style={styles.savingTipAmount}>
                  ₹{goal.savedAmount} saved of ₹{goal.targetAmount}
                </Text>
                <View style={styles.progressBarContainer}>
                  <View style={[styles.progressBar, { width: `${100-(((goal.targetAmount-goal.savedAmount)/goal.targetAmount)*100)}%` }]} />
                  <Text style={styles.progressText}>
                    {100-(((goal.targetAmount-goal.savedAmount)/goal.targetAmount)*100).toFixed(1)}%
                  </Text>
                </View>
                <Text style={[styles.savingTipPercent,{fontWeight:'bold'}]}>
                  ₹{(((goal.targetAmount-goal.savedAmount)/goal.targetAmount)*100)}% left to reach goal
                </Text>
                <Text style={styles.goalStatus}>
                  Status: {goal.status}
                </Text>
              </View>
            ))}
          </ScrollView>
          
        </ScrollView>
      </View>

      {budgetSixMonthsData.length > 0 && expenseSixMonthsData.length > 0 && (
        <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>Last 6 Months Budget vs Expenses</Text>
          <LineChart
            data={{
              labels: months,
              datasets: [
                {
                  data: savings,
                  color: () => `rgba(75, 192, 192, 1)`, // Green color for savings
                  strokeWidth: 2,
                },
                {
                  data: budgetTotals,
                  color: () => `rgba(236, 222, 34, 1)`, // Blue for budget
                  strokeWidth: 2,
                },
                {
                  data: expenseTotals,
                  color: () => `rgba(255, 99, 132, 1)`, // Red for expenses
                  strokeWidth: 2,
                },
              ],
            }}
            width={380} // Chart width
            height={250} // Chart height
            fromZero={true} // Start y-axis from 0
            xAxisInterval={1} // Define x-axis interval to avoid overcrowding
            yAxisInterval={1} // Define y-axis interval to avoid overcrowding
            chartConfig={{
              backgroundGradientFrom: '#555',
              backgroundGradientTo: '#000',
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              style: {
                borderRadius: 16,
                paddingLeft: 50, // Ensure padding for y-axis
                marginRight: 20, // Prevent overflow on the right
              },
              /* propsForBackgroundLines: {
                strokeWidth: 1, // Adjust gridline thickness
                strokeDasharray: '', // Solid lines
              }, */
              decimalPlaces: 0, // Avoid decimals in y-axis labels
              propsForVerticalLabels: {
                paddingLeft: 20, // Add space for y-axis values
              },
              propsForLabels: {
                paddingLeft: 10,
              },
            }}
            yAxisLabel='₹'
            bezier
            style={{
              marginVertical: 8,
              borderRadius: 16,
              overflow: 'hidden',
            }}
          />

          {/* Legend */}
          <View style={styles.legendContainer}>
            <View style={styles.legendItem}>
              <View style={[styles.legendColor, { backgroundColor: 'rgba(75, 192, 192, 1)' }]} />
              <Text style={styles.legendLabel}>Savings</Text>
            </View>
            <View style={styles.legendItem}>
              <View style={[styles.legendColor, { backgroundColor: 'rgba(236, 222, 34, 1)' }]} />
              <Text style={styles.legendLabel}>Budget</Text>
            </View>
            <View style={styles.legendItem}>
              <View style={[styles.legendColor, { backgroundColor: 'rgba(255, 99, 132, 1)' }]} />
              <Text style={styles.legendLabel}>Expenses</Text>
            </View>
          </View>
        </View>
      )}

      <View>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={styles.budgetExpenseLabel}>My Transaction</Text>
          {/* Render Categories */}
          {renderCategoryExpenses()}
        </ScrollView>
      </View>
    </ScrollView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#ffffff',
  },
  widgetContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 15,
    marginBottom: 20,
    elevation: 3,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 },
  },
  row: {
    flexDirection: 'row',
    width:'100%',
    justifyContent: 'space-between', // Ensures equal spacing between elements
    alignItems: 'center', // Vertically aligns items in the center
  },
  widgetTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Outfit',
    color: '#000',
  },
  widgetTitle_user: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Outfit',
    marginBottom: 15,
    textTransform: 'capitalize',
    color: '#000',
  },
  widget_slogan: {
    color: '#555',
    fontFamily: 'Outfit',
  },
  userHi:{
    flexDirection: 'row',
    
  },
  budgetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  budgetColumn: {
    flex: 1,
    alignItems: 'center',
  },
  budgetLabel: {
    fontSize: 16,
    color: '#555',
  },
  budgetValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  progressBarContainer: {
    height: 20,
    backgroundColor: '#e0e0e0',
    borderRadius: 5,
    overflow: 'hidden',
    marginVertical: 10,
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#FFD700', // Yellow progress bar
    borderRadius: 5,
  },
  progressText: {
    position: 'absolute',
    left: 10, // Align text to the left side
    top: 2, // Center the text vertically
    fontSize: 12,
    fontWeight: 'bold',
    color: '#333',
  },
  budgetIcon:{
    flexDirection:'row',
  },
  expenseIcon:{
    flexDirection:'row',
  },
  divider: {
    width: 1, // Width of the divider
    backgroundColor: '#ccc', // Light gray color
    height: '80%', // Height of the divider relative to the content
    marginHorizontal: 10, // Space around the divider
  },
  taskOverview: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  taskBlock: {
    flex: 1,
    borderRadius: 8,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: '#d3d3d3',
  },
  taskBlockTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000',
  },
  taskCount: {
    fontSize: 16,
    fontWeight: '600',
    marginTop: 5,
    color: '#333',
  },
  viewDetailsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  viewDetailsText: {
    fontSize: 16,
    color: '#fff',
    marginRight: 5,
  },
  budgetOverview: {
    marginBottom: 15,
  },
  budgetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  budgetExpenseLabel: {
    fontSize: 16,
    color: '#fff',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  budgetValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  dealsContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#000', // Black button color
    borderRadius: 8,
    padding:20,
    /* paddingVertical: 12,
    paddingHorizontal: 16, */
    alignItems: 'center',
    marginTop: 12,
  },
  buttonText: {
    color: '#fff', // White text color for visibility on black background
    fontSize: 16,
    fontWeight: '600', // Slightly bold text
  },

  cardContainer: {
    borderRadius: 12,
    padding: 20,
    backgroundColor: '#fff',
    marginBottom: 20,
    elevation: 3,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 },
  },
  cardHeader: {
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  budgetSection: {
    marginBottom: 20,
  },
  categoryContainer: {
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
  },
  categoryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  categoryTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  chevron: {
    fontSize: 18,
    color:"#fff",
  },
  cardScroll: {
    flexDirection: 'row',
  },
  card: {
    backgroundColor: '#fff',
    padding: 10,
    marginRight: 10,
    borderRadius: 8,
    width: 200,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    borderWidth:1,
    borderColor:'#ccc',
  },
  subcategoryName: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  subcategoryAmount: {
    fontSize: 14,
    marginVertical: 5,
  },
  spentAmount: {
    fontSize: 14,
    marginBottom: 5,
  },
  /* progressBarContainer: {
    height: 8,
    backgroundColor: '#e0e0e0',
    borderRadius: 4,
    overflow: 'hidden',
  },
   progressBar: {
    height: '100%',
    backgroundColor: '#efbf04',
  }, */
  container: {
    flex: 1,
    padding: 10,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingBottom: 20,
    backgroundColor: '#000',
    borderRadius: 8,
    padding: 10,
    marginBottom: 50,
  },
  categoryExpenseContainer: {
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    backgroundColor: '#292929',
    color: '#fff',
  },
  categoryExpenseTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#fff',
  },
  expensesContainer: {
    marginTop: 10,
    backgroundColor: '#292929',
    borderRadius: 5,
  },
  expenseCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
    backgroundColor: '#292929',
    borderRadius: 5,
    padding: 20,
    marginRight: 10,
    width: "100%",
    alignItems: 'center',
    alignItems: 'flex-start',
    /* elevation: 3,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 }, */
    borderBottomWidth:1,
    borderStyle:'dashed',
    borderBottomColor: '#ccc',
  },
  expenseCategory: {
    fontSize: 16,
    fontWeight: '600',
    color:'#999',
  },
  expenseAmount: {
    fontSize: 14,
    color: '#999',
  },
  totalAmount: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'right',
    color: '#fff',
  },

  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  goalAmount: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#000',
  },
  menuIcon: {
    fontSize: 20,
    color: '#000',
  },
  goalName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000', 
    marginBottom: 5,
  },
  goalDate: {
    fontSize: 14,
    color: '#BBBBBB',
    marginBottom: 10,
  },
  goalLeft: {
    fontSize: 16,
    color: '#FFFFFF',
    marginBottom: 5,
  },
  goalStatus: {
    fontSize: 16,
    color: '#FFFFFF',
    marginBottom: 5,
  },
  chartContainer: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 12,
    backgroundColor: '#000',
    shadowColor: '#fff',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 },
  },
  chartTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#fff',
    textAlign: 'center',
  },
  scrollContainer: {
    flexGrow: 1,
    paddingBottom: 20,
    backgroundColor: '#000',
    borderRadius: 8,
    padding: 10,
    marginBottom: 50,
  },
  budgetExpenseLabel: {
    fontSize: 16,
    color: '#fff',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  legendContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 10,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  legendColor: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginRight: 5,
  },
  legendLabel: {
    fontSize: 14,
    color: '#fff',
  },
});
