import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet, Alert, RefreshControl, } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import { baseURL } from '../apiConfig';

const GoalsListScreen = ({ navigation }) => {
  const [goals, setGoals] = useState([]);
  const [userId, setUserId] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  // Check for uid and uname in AsyncStorage
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const uid = sessionStorage.getItem('userId');
        const uname = sessionStorage.getItem('username');

        if (!uid || !uname) {
          Alert.alert('Error', 'You must be logged in to access this page.');
          navigation.navigate("Account");
        } else {
          setUserId(uid);
        }
      } catch (error) {
        console.error('Error reading AsyncStorage:', error);
        Alert.alert('Error', 'Something went wrong. Please try again.');
        navigation.navigate("Account");
      }
    };

    checkAuth();
  }, [navigation]);
  
  const fetchGoals = async () => {
    try {
      const userId = sessionStorage.getItem('userId');
      const response = await axios.get(`${baseURL}/goals`, { params: { userId }});
      if (response.data.success) {
        setGoals(response.data.goals);
      } else {
        Alert.alert('Error fetching goals:',response.data.message);
      }
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  

  const onRefresh = () => {
    setRefreshing(true);
    Promise.all([fetchGoals()])
      .then(() => setRefreshing(false))
      .catch(() => setRefreshing(false));
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={goals}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          <View style={styles.card}>
            <View style={styles.cardHeader}>
              <Text style={styles.goalAmount}>₹{item.targetAmount}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('EditGoal', { goal: item })}>
                <Text style={styles.menuIcon}>⋮</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.goalName}>{item.name}</Text>
            <Text style={styles.savingTip}>
              ₹{item.savedAmount} saved of total ₹{item.targetAmount}
            </Text>
            <Text style={styles.goalStatus}>
              Status: {item.status}
            </Text>
            <TouchableOpacity
              style={styles.editButton}
              onPress={() => navigation.navigate('EditGoal', { goal: item })}
            >
              <Text style={styles.editButtonText}>Edit</Text>
            </TouchableOpacity>
          </View>
        )}
        refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      />
      <View style={styles.addButtonContainer}>
        <TouchableOpacity
          style={styles.addButton}
          onPress={() => navigation.navigate('AddGoalScreen')}
        >
          <Text style={styles.addButtonText}>Add Goal</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.addButton}
          onPress={() => navigation.navigate('SetGoalAmountScreen')}
        >
          <Text style={styles.addButtonText}>Set Goal Amount</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000000', // Black background
    padding: 10,
  },
  card: {
    backgroundColor: '#1F1F1F', // Dark card background
    padding: 20,
    borderRadius: 12,
    marginBottom: 15,
    borderColor: '#3A3A3A',
    borderWidth: 1,
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  goalAmount: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  menuIcon: {
    fontSize: 20,
    color: '#FFFFFF',
  },
  goalName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFD700', // Golden color
    marginBottom: 5,
  },
  goalDate: {
    fontSize: 14,
    color: '#BBBBBB',
    marginBottom: 10,
  },
  goalLeft: {
    fontSize: 16,
    color: '#FFFFFF',
    marginBottom: 5,
  },
  goalStatus: {
    fontSize: 16,
    color: '#FFFFFF',
    marginBottom: 5,
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  amount: {
    fontSize: 16,
    color: '#555',
  },
  savingTip: {
    fontSize: 14,
    color: '#ccc', // Light green for tip
    fontStyle: 'italic',
  },
  addButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addButton: {
    backgroundColor: '#FFFFFF', // White button
    paddingVertical: 15,
    borderRadius: 8,
    alignItems: 'center',
    bottom: 20,
    alignItems: 'center',
    padding: 15,
    width:"45%",
  },
  addButtonText: {
    color: '#000000', // Black text
    fontSize: 16,
    fontWeight: 'bold',
    
  },
  editButton: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  editButtonText: {
    color: '#000000', // Black text
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default GoalsListScreen;
