// MenuScreen.js
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Alert } from 'react-native';
import axios from 'axios';
import { baseURL } from '../apiConfig';

const MenuScreen = () => {
  const [pages, setPages] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    fetchPages();
    fetchMenuItems();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await axios.get(`${baseURL}/pages`);
      setPages(response.data.pages);
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(`${baseURL}/menu`);
      setMenuItems(response.data.menuItems);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const addToMenu = async (page) => {
    try {
      await axios.post(`${baseURL}/menu`, { pageId: page.id });
      fetchMenuItems();
      Alert.alert('Success', 'Page added to menu.');
    } catch (error) {
      console.error('Error adding to menu:', error);
      Alert.alert('Error', 'Failed to add page to menu.');
    }
  };

  const removeFromMenu = async (menuItemId) => {
    try {
      await axios.delete(`${baseURL}/menu/${menuItemId}`);
      fetchMenuItems();
      Alert.alert('Success', 'Page removed from menu.');
    } catch (error) {
      console.error('Error removing from menu:', error);
      Alert.alert('Error', 'Failed to remove page from menu.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Menu Management</Text>
      <Text style={styles.subtitle}>Available Pages:</Text>
      <FlatList
        data={pages}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <View style={styles.pageItem}>
            <Text>{item.title}</Text>
            <TouchableOpacity onPress={() => addToMenu(item)}>
              <Text style={styles.addText}>Add to Menu</Text>
            </TouchableOpacity>
          </View>
        )}
      />
      <Text style={styles.subtitle}>Menu Items:</Text>
      <FlatList
        data={menuItems}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <View style={styles.menuItem}>
            <Text>{item.pageTitle}</Text>
            <TouchableOpacity onPress={() => removeFromMenu(item.id)}>
              <Text style={styles.removeText}>Remove</Text>
            </TouchableOpacity>
          </View>
        )}
      />
    </View>
  );
};

export default MenuScreen;

const styles = StyleSheet.create({
  // Add your styles here
});
