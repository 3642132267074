import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

const Signout = () => {
  const navigate = useNavigation();

  useEffect(() => {
    // Clear the token and user-related data from sessionStorage
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('isLoggedIn');

    // Redirect to the login page (or home page) after signing out
    navigate.navigation('/Account'); // Adjust this to the appropriate route for your app
  }, [navigate]);

  return (
    <div>
      <h2>Signing Out...</h2>
    </div>
  );
};

export default Signout;
