// BlogDetailScreen.js

import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Image,
  ActivityIndicator,
  Alert,
  Dimensions,
} from 'react-native';
import { useRoute } from '@react-navigation/native';
import axios from 'axios'; // For making HTTP requests
import {baseURL} from '../apiConfig'; // Your axios instance with baseURL and token handling
import RenderHTML from 'react-native-render-html'; // For rendering HTML content
import moment from 'moment'; // For date formatting

const BlogDetailScreen = () => {
  const route = useRoute();
  const { blogId } = route.params;

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogDetail();
  }, []);

  const fetchBlogDetail = async () => {
    try {
      const response = await axios.get(`${baseURL}/blogs/${blogId}`);
      if (response.data.blog) {
        setBlog(response.data.blog);
      } else {
        Alert.alert('Error', 'Blog not found.');
      }
    } catch (error) {
      console.error('Error fetching blog detail:', error);
      Alert.alert('Error', 'Failed to load blog details.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#2196F3" />
      </View>
    );
  }

  if (!blog) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Blog not found.</Text>
      </View>
    );
  }

  const contentWidth = Dimensions.get('window').width - 30; // Adjust based on your padding/margin

  return (
    <ScrollView style={styles.container}>
      {blog.featuredImage ? (
        <Image source={{ uri: blog.featuredImage }} style={styles.featuredImage} />
      ) : null}
      <Text style={styles.title}>{blog.title}</Text>
      <View style={styles.metaContainer}>
        <Text style={styles.metaText}>
          By {blog.author?.username || 'Unknown Author'}
        </Text>
        <Text style={styles.metaText}>
          Published on {moment(blog.createdAt).format('MMMM Do, YYYY')}
        </Text>
      </View>
      <View style={styles.contentContainer}>
        <RenderHTML
          contentWidth={contentWidth}
          source={{ html: blog.content }}
          tagsStyles={htmlStyles}
        />
      </View>
    </ScrollView>
  );
};

export default BlogDetailScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    backgroundColor: '#fff',
  },
  featuredImage: {
    width: '100%',
    height: 200,
    marginBottom: 15,
    borderRadius: 8,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
  },
  metaContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  metaText: {
    fontSize: 14,
    color: '#666',
  },
  contentContainer: {
    marginBottom: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 18,
    color: '#666',
  },
});

const htmlStyles = {
  p: {
    fontSize: 16,
    lineHeight: 24,
    color: '#333',
  },
  h1: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  h2: {
    fontSize: 22,
    fontWeight: 'bold',
    marginVertical: 8,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    marginVertical: 10,
  },
  // Add more styles for other HTML elements as needed
};
