// PermalinksScreen.js
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Picker, Alert } from 'react-native';
import axios from 'axios';
import { baseURL } from '../apiConfig';

const PermalinksScreen = () => {
  const [permalinkStructure, setPermalinkStructure] = useState('');

  useEffect(() => {
    fetchPermalinkSettings();
  }, []);

  const fetchPermalinkSettings = async () => {
    try {
      const response = await axios.get(`${baseURL}/settings/permalinks`);
      setPermalinkStructure(response.data.permalinkStructure);
    } catch (error) {
      console.error('Error fetching permalink settings:', error);
    }
  };

  const savePermalinkSettings = async () => {
    try {
      await axios.post(`${baseURL}/settings/permalinks`, { permalinkStructure });
      Alert.alert('Success', 'Permalink settings updated.');
    } catch (error) {
      console.error('Error saving permalink settings:', error);
      Alert.alert('Error', 'Failed to update permalink settings.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Permalink Settings</Text>
      <Picker
        selectedValue={permalinkStructure}
        onValueChange={(itemValue) => setPermalinkStructure(itemValue)}
      >
        <Picker.Item label="Plain" value="plain" />
        <Picker.Item label="Day and Name" value="day-name" />
        <Picker.Item label="Month and Name" value="month-name" />
        <Picker.Item label="Post Name" value="post-name" />
        <Picker.Item label="Custom Structure" value="custom" />
      </Picker>
      <TouchableOpacity style={styles.saveButton} onPress={savePermalinkSettings}>
        <Text style={styles.saveButtonText}>Save Changes</Text>
      </TouchableOpacity>
    </View>
  );
};

export default PermalinksScreen;

const styles = StyleSheet.create({
  // Add your styles here
});
