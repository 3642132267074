// App.web.js
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, ActivityIndicator, Text, TouchableOpacity, Image, } from 'react-native';
import { NavigationContainer, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { Provider } from 'react-redux';
import { store } from './store';
import { MaterialIcons } from '@expo/vector-icons';
import { Avatar, Provider as PaperProvider } from 'react-native-paper';
import { AuthContext, AuthProvider } from './AuthContext_web';
import useAxiosInterceptors from './hooks/useAxiosInterceptors';
import { useFonts, OpenSans_400Regular, OpenSans_600SemiBold, OpenSans_700Bold } from '@expo-google-fonts/open-sans';
import { Linking } from 'react-native';
import { fetchUserData, fetchUserDataRaw } from './services/userService';
import { UserContext, UserProvider } from './context/UserContext';

// Screens
import AccountScreen from './webview/AccountScreen';
import SigninScreen from './webview/SigninScreen';
import SignupScreen from './webview/SignupScreen';
import HomeScreen from './webview/HomeScreen';
import HomeScreenActive from './webview/HomeScreenActive';
import DashboardScreen from './webview/DashboardScreen';
import BlogScreen from './webview/BlogScreen';
import PagesScreen from './webview/PagesScreen';
import MenuScreen from './webview/MenuScreen';
import UsersScreen from './webview/UsersScreen';
import PermalinksScreen from './webview/PermalinksScreen';
import BudgetScreen from './webview/BudgetScreen';
import TrackerScreen from './webview/trackerScreen';
import TransactionScreen from './webview/TransactionScreen';
import AddGoalScreen from './webview/AddGoalScreen';
import GoalsListScreen from './webview/GoalsListScreen';
import SetGoalAmountScreen from './webview/SetGoalAmountScreen';
import BudgetHomeScreen from './webview/BudgetHomeScreen';
import BlogDetailScreen from './webview/BlogDetailScreen';
import Signout from './webview/Signout';
import Test from './webview/Test';
import Blog from './webview/Blog';
import BlogDescriptionScreen from './webview/BlogDescriptionScreen';
import ProfileScreen from './webview/ProfileScreen';

const BlogStack = createStackNavigator();
const AuthStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const linking = {
  prefixes: ['http://localhost:8081', 'https://savingy.com'],
  config: {
    screens: {
      Account: 'account',
      Home: 'home',
      Welcome: 'welcome',
      Dashboard: 'dashboard',
      Blogs: 'blogs',
      Pages: 'pages',
      Menu: 'menu',
      Users: 'users',
      Permalinks: 'permalinks',
      Blog: {
        screens: {
          Blog: '',
          BlogDescription: 'description',
        },
      },
      Test: 'test',
      Signout: 'signout',
    },
  },
};

const App = () => {
  const [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
  });

  useAxiosInterceptors();

  return (
    <Provider store={store}>
      <PaperProvider>
        <AuthProvider>
          <UserProvider>
            <Main />
          </UserProvider>
        </AuthProvider>
      </PaperProvider>
    </Provider>
  );
};

export default App;

const Main = () => {
  const { userToken, userRole, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#2196F3" />
      </View>
    );
  }

  return (
    <NavigationContainer linking={linking}>
      {userToken ? (
        userRole === 'admin' ? (
          <AdminDrawerNavigator />
        ) : (
          <UserDrawerNavigator />
        )
      ) : (
        <LandingDrawerNavigator />
      )}
    </NavigationContainer>
  );
};

// ------------------ Custom Drawer Content for Users ------------------

const Section = ({ title }) => (
  <View style={styles.sectionHeader}>
    <Text style={styles.sectionTitle}>{title}</Text>
  </View>
);

const CustomDrawerContent = (props) => {
  const { navigation } = props;
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
      
      
  const [userData, setUserData] = useState({ name: '', phone: '', image: null });

  const refreshUserData = async () => {
      setLoading(true);
      const response = await fetchUserDataRaw(); // you’ll write this
      if (response?.success) {
          console.log('User data:', response.data);
          setUserData(response.data);
          setUser(response.data); // <- sync global context
      }
      setLoading(false);
  };

  useFocusEffect(
      React.useCallback(() => {
          refreshUserData();
      }, [])
  );


  if (loading) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" color="#000" />
        </View>
      );
  }

  return (
    <DrawerContentScrollView {...props}>
      <TouchableOpacity style={styles.profileTouch} onPress={() => navigation.navigate('Profile')}>
        <View style={styles.profileContainer}>
          {loading ? (
              <ActivityIndicator size="small" color="#000" />
          ) : (
              <>
                  <Image
                      source={userData.image ? { uri: userData.image } : require('./assets/profile-placeholder.png')}
                      style={styles.profileImage}
                  />
                  <View style={styles.textContainer}>
                      <Text style={styles.profileName}>{userData.name}</Text>
                      <Text style={styles.profilePhone}>{userData.phone}</Text>
                  </View>
              </>
          )}
        </View>
      </TouchableOpacity>
    
      <Section title="Home" />
      <DrawerItem label="Welcome" onPress={() => navigation.navigate('Welcome')} icon={({ color, size }) => <MaterialIcons name="home" color={color} size={size} />} />

      <Section title="Budget" />
      <DrawerItem label="Budget Home" onPress={() => navigation.navigate('BudgetHomeScreen')} icon={({ color, size }) => <MaterialIcons name="home" color={color} size={size} />} />
      <DrawerItem label="Budget" onPress={() => navigation.navigate('BudgetScreen')} icon={({ color, size }) => <MaterialIcons name="account-balance" color={color} size={size} />} />

      <Section title="Expense / Income" />
      <DrawerItem label="Tracker" onPress={() => navigation.navigate('TrackerScreen')} icon={({ color, size }) => <MaterialIcons name="payment" color={color} size={size} />} />
      <DrawerItem label="Transactions" onPress={() => navigation.navigate('TransactionScreen')} icon={({ color, size }) => <MaterialIcons name="attach-money" color={color} size={size} />} />

      <Section title="Goals" />
      <DrawerItem label="Goals List" onPress={() => navigation.navigate('GoalsListScreen')} icon={({ color, size }) => <MaterialIcons name="checklist" color={color} size={size} />} />
      <DrawerItem label="Add Goal" onPress={() => navigation.navigate('AddGoalScreen')} icon={({ color, size }) => <MaterialIcons name="track-changes" color={color} size={size} />} />
      <DrawerItem label="Set Goal Amount" onPress={() => navigation.navigate('SetGoalAmountScreen')} icon={({ color, size }) => <MaterialIcons name="add-circle-outline" color={color} size={size} />} />

      <Section title="Articles" />
      <DrawerItem label="Blog" onPress={() => navigation.navigate('Blog')} icon={({ color, size }) => <MaterialIcons name="article" color={color} size={size} />} />

      <Section title=" " />
      <DrawerItem label="Sign Out" onPress={() => navigation.navigate('SignOut')} icon={({ color, size }) => <MaterialIcons name="logout" color={color} size={size} />} />
    </DrawerContentScrollView>
  );
};

// ------------------ Navigators ------------------

const AuthStackNavigator = () => (
  <AuthStack.Navigator initialRouteName="Account">
    <AuthStack.Screen name="Account" component={AccountScreen} options={{ headerShown: false }} />
  </AuthStack.Navigator>
);

const BlogStackNavigator = () => (
  <BlogStack.Navigator>
    <BlogStack.Screen name="Blog" component={Blog} options={{ title: 'Blog' }} />
    <BlogStack.Screen name="BlogDescription" component={BlogDescriptionScreen} options={{ title: 'Blog Description' }} />
  </BlogStack.Navigator>
);

const LandingDrawerNavigator = () => (
  <Drawer.Navigator initialRouteName="Home">
    <Drawer.Screen name="Home" component={HomeScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="home" color={color} size={size} />
    }} />
    <Drawer.Screen name="Account" component={AccountScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="account-circle" color={color} size={size} />
    }} />
  </Drawer.Navigator>
);

const UserDrawerNavigator = () => (
  <Drawer.Navigator
    initialRouteName="Welcome"
    drawerContent={(props) => <CustomDrawerContent {...props} />}
  >
    <Drawer.Screen name="Profile" component={ProfileScreen} />
    <Drawer.Screen name="Welcome" component={HomeScreenActive} />
    <Drawer.Screen name="BudgetHomeScreen" component={BudgetHomeScreen} />
    <Drawer.Screen name="BudgetScreen" component={BudgetScreen} />
    <Drawer.Screen name="TrackerScreen" component={TrackerScreen} />
    <Drawer.Screen name="TransactionScreen" component={TransactionScreen} />
    <Drawer.Screen name="GoalsListScreen" component={GoalsListScreen} />
    <Drawer.Screen name="AddGoalScreen" component={AddGoalScreen} />
    <Drawer.Screen name="SetGoalAmountScreen" component={SetGoalAmountScreen} />
    <Drawer.Screen name="Blog" component={BlogStackNavigator} />
    <Drawer.Screen name="SignOut" component={Signout} />
  </Drawer.Navigator>
);

const AdminDrawerNavigator = () => (
  <Drawer.Navigator initialRouteName="Dashboard">
    <Drawer.Screen name="Dashboard" component={DashboardScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="dashboard" color={color} size={size} />
    }} />
    <Drawer.Screen name="Blogs" component={BlogScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="article" color={color} size={size} />
    }} />
    <Drawer.Screen name="Pages" component={PagesScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="pages" color={color} size={size} />
    }} />
    <Drawer.Screen name="Test" component={Test} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="pages" color={color} size={size} />
    }} />
    <Drawer.Screen name="Menu" component={MenuScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="menu" color={color} size={size} />
    }} />
    <Drawer.Screen name="Users" component={UsersScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="people" color={color} size={size} />
    }} />
    <Drawer.Screen name="Permalinks" component={PermalinksScreen} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="link" color={color} size={size} />
    }} />
    <Drawer.Screen name="Signout" component={Signout} options={{
      drawerIcon: ({ color, size }) => <MaterialIcons name="logout" color={color} size={size} />
    }} />
  </Drawer.Navigator>
);

// ------------------ Styles ------------------

const styles = StyleSheet.create({
  sectionHeader: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: '#f2f2f2',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#555',
  },
  drawerContainer: {
    flex: 1,
    backgroundColor: '#f0f0f0',
  },
  profileTouch: {
      margin: 16,
  },
  profileContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 16,
      backgroundColor: '#fff',
      borderRadius: 10,
  },
  profileImage: {
      width: 50,
      height: 50,
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 50,
  },
  textContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: 10,
  },
  profileName: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#333',
  },
  profilePhone: {
      fontSize: 14,
      color: '#666',
      marginTop: 4,
  },
});
